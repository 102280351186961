import React, { useEffect, useState } from 'react'
import { Collapse, Modal, Select, Input, DatePicker } from 'antd';
import AppraisalForm from './AppraisalForm';
import { ErrorMessage, ErrorMessages, retrieveData, storeData, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import { useParams } from 'react-router-dom';
import { ratings } from './AppraisalFormValidationSchema';
import TextAreaRendering from './TextAreaRendering';
import { handleAppraisalsApiCall } from './AppraisalModalHelper';
import AppraisalButtonRendering from './AppraisalButtonRendering';
import { useSelector } from 'react-redux';
import CloseModalForm from './EmailAppraisal/CloseModalForm';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';
import { dateConversion } from './components/appraisalHook';
import RevisedSalary from '../../../revised-salary/RevisedSalary';
import { useEmployeeAppraisalContext } from './context/EmployeeAppraisalContext';
import PopupModal from '../../modal/PopupModal';

const { TextArea } = Input;
const { Panel } = Collapse;

const AppraisalAssesment = (props: any) => {
  const reviewFromDetails = useSelector((state: any) => state.reviewDetailsSlice?.reviewFormSlice);
  const concatSalary = useSelector((state: any) => state.reviewDetailsSlice?.concatenatedSalaryParticulars
  );
  const { nextButton, questionsArray, functionalAttributes, keyPerformance, organisationAttributes,
    personalAttributes, salaryRevised, incrementEligible, probationExtension, handleCloseModal,
    isView, ctcDetails, employeeReviewId, employeeReviewFormActionType, values, setFieldValue, } = props;

  const { gross_SalaryMonthly, gross_SalaryYearly, salaryUpdatePayload, ctc_SalaryYearly, salaryPayloadDetails, concatEmployerContribution, employeeContribution } = useSelector((state: any) => state.reviewDetailsSlice);
  const { getReviews, isPending, salaryIncrement } = useEmployeeAppraisalContext();
  const [tempQuestionArray, setTempQuestionArray] = useState<any>();
  const [changeCtcModal, setChangeCtcModal] = useState(false);
  const [keyPerformanceErrors, setKeyPerformanceErrors] = useState<any>([]);
  const [personalErrors, setPersonalErrors] = useState<any>([]);
  const [functionalErrors, setFunctionalErrors] = useState<any>([]);
  const [organisationErrors, setOrganisationErrors] = useState<any>([]);
  const [significantAchievementsError, setSignificantAchievementsError] = useState(false);
  const [improvementAreasError, setImprovementAreasError] = useState(false);
  const [technicalSkillsError, setTechnicalSkillsError] = useState(false);
  const [softSkillsError, setSoftSkillsError] = useState(false);
  const [appraiserCommentsError, setAppraiserCommentsError] = useState(false);
  const [touched, setTouched] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [appraisercommentsNew, setAppraisercommentsNew] = useState('');
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const [salaryDate, setSalaryDate] = useState('');
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  const reviewtype: any = retrieveData('reviewtype', true)
  const createReview = useGetApiRequests('createReviewAppraisal', 'POST')
  const createSalaryApi = useGetApiRequests("createEmployeeSalary", "POST");

  const updateReview = useGetApiRequests('updateReviewAppraisal', 'PUT')
  const userId = useSelector((state: any) => state?.userId?.id);

  //
  const { id }: any = useParams();

  useEffect(() => {
    if (questionsArray !== undefined && Object.keys(questionsArray).length > 0)
      setTempQuestionArray(questionsArray)
  }, []);

  useEffect(() => {
    if (reviewFromDetails?.isEligibleForIncrement === true) {
      setSalaryDate(dateConversion(reviewFromDetails?.newCtcEffectiveDate))
    }
  }, []);

  const handelSaveRevise = (value: string) => {

    let keyperformanceFlag: boolean;
    let mandatoryValuesFlag: boolean = false;
    let ratingValueFlag: boolean = false;
    let newSalaryValueFlag: boolean = false;

    const hasTrueValue: any = [...keyPerformanceErrors, ...personalErrors, ...functionalErrors, ...organisationErrors].some((value: any) => value === true);
    const personalattributesFlag: any = tempQuestionArray.personalattributes.some((item: any) => item.range === null);

    if (tempQuestionArray.keyperformance.length > 0) {
      keyperformanceFlag = tempQuestionArray.keyperformance.some((item: any) => item.range === null);
    } else {
      keyperformanceFlag = false;
    }

    const functionalattributesFlag: any = tempQuestionArray.functionalattributes.some((item: any) => item?.range === null);
    const organisationalattributesFlag: any = tempQuestionArray.organisationalattributes.some((item: any) => item?.range === null);
    const hasEmptyAnswer = Object.values(tempQuestionArray.appraiserfeedback).some((feedbackItem: any) => feedbackItem?.answer === "");
    if (values?.incrementEligible) {
      newSalaryValueFlag = salaryIncrement?.newCtcInRupees && salaryIncrement?.newCtcInRupees != '0' ? false : true;
    }
    let formStatus: boolean = true;

    if (!hasTrueValue && personalattributesFlag === false && keyperformanceFlag === false &&
      functionalattributesFlag === false && organisationalattributesFlag === false) {
      ratingValueFlag = true;
      if (!significantAchievementsError && !improvementAreasError && !technicalSkillsError && !softSkillsError &&
        !appraiserCommentsError && !hasEmptyAnswer && reviewtype && !newSalaryValueFlag) {

        mandatoryValuesFlag = true;
      } else {
        formStatus = false;
        mandatoryValuesFlag = false;
      }
    } else {
      ratingValueFlag = false;
      formStatus = false;
    }
    if (value === 'Draft') {
      formStatus = false;

      handleAppraisalsApiCall(tempQuestionArray, incrementEligible, id,
        createReview, handleCloseModal,
        setChangeCtcModal, getReviews, setLoader,
        probationExtension, isView, updateReview,
        employeeReviewId, userId, formStatus, value, employeeReviewFormActionType, values, salaryIncrement, handleSave);
    } else {
      if (ratingValueFlag) {
        if (mandatoryValuesFlag) {
          handleAppraisalsApiCall(tempQuestionArray, incrementEligible, id,
            createReview, handleCloseModal,
            setChangeCtcModal, getReviews, setLoader,
            probationExtension, isView, updateReview,
            employeeReviewId, userId, formStatus, value, employeeReviewFormActionType, values, salaryIncrement);
        } else {
          setPopModal(true)
          setmodalButtonShow(true)
          setModalErrMessage(ErrorMessages.mandatoryValues)
          setModalResponse('warning')
        }

      } else {
        setPopModal(true)
        setmodalButtonShow(true)
        setModalErrMessage(ErrorMessages.ratingValue)
        setModalResponse('warning')
      }
    }

  };

  const selectRating = (index: number, val: string, event: any) => {
    if ((val === 'keyperformance' && keyPerformance) || (val === 'personalattributes' && personalAttributes) ||
      (val === 'functionalattributes' && functionalAttributes) ||
      (val === 'organisationalattributes' && organisationAttributes)) {

      const updatedQuestions = { ...tempQuestionArray };
      const attributeType = val
      const currentAttribute = updatedQuestions[attributeType][index];

      if (currentAttribute) {
        currentAttribute.ansFlag = true;
        // currentAttribute.answer = '';
        currentAttribute.commentFlag = event === 1 || event === 2;
        currentAttribute.range = event;
      }
      updatedQuestions[attributeType] = updatedQuestions[attributeType].slice();
      setTempQuestionArray(updatedQuestions);

      const stateMap = {
        keyperformance: { state: keyPerformanceErrors, setter: setKeyPerformanceErrors },
        personalattributes: { state: personalErrors, setter: setPersonalErrors },
        functionalattributes: { state: functionalErrors, setter: setFunctionalErrors },
        organisationalattributes: { state: organisationErrors, setter: setOrganisationErrors },
      };

      const selectedState = stateMap[val];
      if (selectedState) {
        const { state, setter } = selectedState;

        const updatedErrors = [...state];
        updatedErrors[index] = event === 1 || event === 2;
        if (!currentAttribute?.answer) setter(updatedErrors);
      }
      setTouched(true);
    }
  };

  const handleTextArea = (event: any, type: string, index: number) => {
    const updatedQuestions = { ...tempQuestionArray };
    const currentQuestion = updatedQuestions[type][index];
    if (event.target.value !== '') {
      currentQuestion.answer = event.target.value;
      currentQuestion.commentFlag = false;
      if (type === 'keyperformance' && keyPerformanceErrors[index]) {
        const updatedErrors = [...keyPerformanceErrors];
        updatedErrors[index] = false;
        setKeyPerformanceErrors(updatedErrors);
      } else if (type === 'personalattributes' && personalErrors[index]) {
        const updatedErrors = [...personalErrors];
        updatedErrors[index] = false;
        setPersonalErrors(updatedErrors);
      } else if (type === 'functionalattributes' && functionalErrors[index]) {
        const updatedErrors = [...functionalErrors];
        updatedErrors[index] = false;
        setFunctionalErrors(updatedErrors);
      } else if (type === 'organisationalattributes' && organisationErrors[index]) {
        const updatedErrors = [...organisationErrors];
        updatedErrors[index] = false;
        setOrganisationErrors(updatedErrors);
      }
    } else if (currentQuestion.range === 1 || currentQuestion.range === 2) {
      currentQuestion.answer = event.target.value;
      const errorTypes: any = {
        keyperformance: { errors: keyPerformanceErrors, setter: setKeyPerformanceErrors },
        personalattributes: { errors: personalErrors, setter: setPersonalErrors },
        functionalattributes: { errors: functionalErrors, setter: setFunctionalErrors },
        organisationalattributes: { errors: organisationErrors, setter: setOrganisationErrors },
      };

      const errorType = errorTypes[type];
      if (errorType) {
        const updatedErrors = [...errorType.errors];
        updatedErrors[index] = true;
        errorType.setter(updatedErrors);
      }
      currentQuestion.commentFlag = true;

    } else {
      currentQuestion.answer = event.target.value;
      currentQuestion.commentFlag = false;
    }
    setTempQuestionArray(updatedQuestions);
  };
  const handleAppraisals = (event: any, name: string, type: string) => {
    const updatedQuestions = { ...tempQuestionArray };
    const inputValue = event.target.value;
    if (name === 'appraiserfeedback') {
      updatedQuestions[name][type].answer = inputValue;
    }
    setTempQuestionArray(updatedQuestions);
    switch (type) {
      case "significantachievements":
        setSignificantAchievementsError(inputValue.trim() === "");
        break;
      case "improvementareas":
        setImprovementAreasError(inputValue.trim() === "");
        break;
      case "technicalskills":
        setTechnicalSkillsError(inputValue.trim() === "");
        break;
      case "softskills":
        setSoftSkillsError(inputValue.trim() === "");
        break;
      case "appraisercomments":
        setAppraisercommentsNew(inputValue)
        setAppraiserCommentsError(inputValue.trim() === "");
        break;
      default:
        break;
    }
    setTouched(true);
  }


  const openChangeSalaryModal = () => {
    setChangeCtcModal(true);
  }
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  const closeChangeSalary = () => {
    if (isView === true && isPending === false) {
      setChangeCtcModal(!changeCtcModal);
    } else {
      setCloseConfirmationModal(true)
    }
  };

  const handleClosed = () => {
    setChangeCtcModal(!changeCtcModal);
    setCloseConfirmationModal(false)
  }

  const handelChangeSalary = () => {

    let keyperformanceFlag: any
    const trueValue: any = [...keyPerformanceErrors, ...personalErrors, ...functionalErrors, ...organisationErrors,].some((value: any) => value === true);
    const personalattributesFlag: any = tempQuestionArray.personalattributes.some((item: any) => item.range === null);
    if (tempQuestionArray.keyperformance.length > 0) {
      keyperformanceFlag = tempQuestionArray.keyperformance.some((item: any) => item.range === null);
    } else {
      keyperformanceFlag = false;
    }
    const functionalattributesFlag: any = tempQuestionArray.functionalattributes.some((item: any) => item.range === null);
    const organisationalattributesFlag: any = tempQuestionArray.organisationalattributes.some((item: any) => item.range === null);
    const feedbacks: any = Object.values(tempQuestionArray.appraiserfeedback).some((feedbackItem: any) => feedbackItem.answer === "");

    if (!trueValue && personalattributesFlag === false && keyperformanceFlag === false && functionalattributesFlag === false && organisationalattributesFlag === false) {
      if (!significantAchievementsError && !improvementAreasError && !technicalSkillsError && !softSkillsError && !appraiserCommentsError && !feedbacks &&
        reviewtype !== '' && reviewtype !== null && reviewtype !== undefined) {

        setChangeCtcModal(true)
      } else {
        setPopModal(true)
        setmodalButtonShow(true)
        setModalErrMessage(ErrorMessages.mandatoryValues)
        setModalResponse('warning')
      }
    } else {
      setPopModal(true)
      setmodalButtonShow(true)
      setModalErrMessage(ErrorMessages.ratingValue)
      setModalResponse('warning')
    }
  }

  const createPayload = () => {
    setLoader(true);
    const employeeSalaryDetails: any = [];
    const employeeId: string | number = id ? parseInt(id) : "";
    return {
      ctc: Math.round(ctc_SalaryYearly),
      employee: { employeeId: employeeId },
      employeeSalaryDetails,
      packageType: "yearly",
      status: "Draft",
      appraisalEffectiveFrom: salaryIncrement?.salaryRevisedDate,
    };
  };
  const handleSave = async () => {
    if (gross_SalaryMonthly > 0 && gross_SalaryYearly > 0) {
      // setLoader(true);
      let requestPayload = createPayload();
      const concatSalaryParticulars = concatSalary ? concatSalary : [];
      const storeConcatEmployerContribution = concatEmployerContribution ? concatEmployerContribution : [];
      const storeEmployeeContribution = employeeContribution ? employeeContribution : [];

      let allList = [...concatSalaryParticulars, ...storeConcatEmployerContribution, ...storeEmployeeContribution];
      console.log("TCL: handleSave -> allList-Before", allList)

      let tempList: any = [];

      allList.forEach((item: any) => {
        if (item.name !== "LTA" || (item.name === "LTA" && gross_SalaryMonthly > 36803)) {
          tempList.push({
            monthly: Number(item.monthly),
            salaryComponentId: item.salaryComponentId,
            yearly: Number(item.yearly),
          });
        } else {
          tempList.push({
            monthly: 0,
            salaryComponentId: item.salaryComponentId,
            yearly: 0,
          });
        }
      });


      if (tempList) {
        let salaryDetails = tempList.filter((elm: any) => {
          if (elm.yearly >= 0) {
            return elm;
          }
        });

        console.log("TCL: handleSave -> allList-tempList", tempList)
        if (salaryDetails.length !== 0) {
          requestPayload.employeeSalaryDetails = salaryDetails;
          console.log("TCL: handleSave -> salaryDetails", tempList)
          try {
            const res: any = await createSalaryApi(requestPayload);
            triggerNotification("success", "", res?.data?.status?.message, "topRight");
            setLoader(false);
            setChangeCtcModal(false);
            setLoader(false);
            handleCloseModal();
          } catch (err) {
            triggerNotification("error", "", ErrorMessages.somethingWentWrong, "topRight");
            setChangeCtcModal(false);
            setLoader(false);
            handleCloseModal();
          }
        }
      }
    } else {
      setPopModal(true)
      setmodalButtonShow(true)
      setModalErrMessage(ErrorMessages?.grossSalaryRequire)
      setModalResponse('warning')
    }
  };

  return (
    <>
      {loader && <ModalLoader />}
      <div className="general-assessment">
        <div>
          {/* <pre>{JSON.stringify(salaryPayloadDetails, null, 1)}</pre> */}
          <h3 className='general-assessment-header' data-testid='general-assessment-id'>General Assessment</h3>
          <h4 className='general-assessment-subtitle py-4'>Key Performance </h4>
          {keyPerformance?.map((item: any, index: number) => (
            <Collapse key={item.questionaireId} className='my-2'
              size="small" expandIconPosition={"end"} >
              {keyPerformanceErrors[index] && <ErrorMessage message='Comment is required*' />}
              <Panel header={<div className='flex justify-between key-questions items-center'>
                <p className='question-title'>{item?.question ? item?.question : item?.questionaire?.question}</p>
                <div className="form-item" onClick={(e) => e.stopPropagation()}>
                  <Select
                    disabled={isView}
                    placeholder="Select your rating"
                    options={ratings}
                    onChange={(e) => selectRating(index, "keyperformance", e)}
                    value={
                      tempQuestionArray['keyperformance'][index]?.range ||
                      ratings?.filter((element: any) => element.value === item?.performanceRatingScale?.performanceRatingScaleId) || null}
                  />
                </div>
              </div>} key="1">
                <TextArea rows={2} style={{ resize: 'none' }}
                  disabled={isView}
                  placeholder='Enter your comments'
                  onChange={(e) => { handleTextArea(e, "keyperformance", index) }}
                  value={tempQuestionArray['keyperformance']?.[index]?.answer} />
              </Panel>
            </Collapse>))}
        </div>
        <div>
          <h3 className='general-assessment-header pt-4'>Competency Assessment</h3>
          <h4 className='general-assessment-subtitle py-4' >Personal Attributes</h4>

          {personalAttributes?.map((item: any, index: number) => (
            <Collapse key={item?.questionaireId} className='my-2'
              size="small" expandIconPosition={"end"} >
              {personalErrors[index] && <ErrorMessage message='Comment is required*' />}
              <Panel header={<div className='flex justify-between key-questions items-center'>
                <p className='question-title'>{item?.question ? item?.question : item?.questionaire?.question} </p>
                <div className="form-item" onClick={(e) => e.stopPropagation()}>
                  <Select
                    disabled={isView}
                    placeholder="Select your rating"
                    options={ratings}
                    onChange={(e) => selectRating(index, "personalattributes", e)}
                    value={
                      tempQuestionArray['personalattributes'][index]?.range || null ||
                      ratings?.filter((element: any) => element.value === item?.performanceRatingScale?.performanceRatingScaleId) || null}
                  />
                </div>
              </div>} key="2">

                <TextArea disabled={isView} rows={2} style={{ resize: 'none' }}
                  placeholder='Enter your comments' onChange={(e) => { handleTextArea(e, "personalattributes", index) }}
                  value={tempQuestionArray['personalattributes']?.[index]?.answer} />
              </Panel>
            </Collapse>))}
        </div>
        <div>
          <h3 className='general-assessment-subtitle py-4'>Functional Attributes</h3>
          {functionalAttributes?.map((item: any, index: any) => (
            <Collapse key={item.questionaireId} className='my-2'
              size="small" expandIconPosition={"end"} >
              {functionalErrors[index] && <ErrorMessage message='Comment is required*' />}
              <Panel header={<div className='flex justify-between key-questions items-center'>
                <p className='question-title'>{item?.question ? item?.question : item?.questionaire?.question}</p>
                <div className="form-item" onClick={(e) => e.stopPropagation()}>
                  <Select disabled={isView}
                    placeholder="Select your rating"
                    options={ratings}
                    onChange={(e) => selectRating(index, "functionalattributes", e)}
                    value={
                      tempQuestionArray['functionalattributes'][index]?.range ||
                      ratings?.filter((element: any) => element.value === item?.performanceRatingScale?.performanceRatingScaleId) || null}
                  />
                </div>
              </div>} key="3">
                <TextArea rows={2} style={{ resize: 'none' }}
                  placeholder='Enter your comments' disabled={isView}
                  onChange={(e) => { handleTextArea(e, "functionalattributes", index) }}
                  value={tempQuestionArray['functionalattributes']?.[index]?.answer} />
              </Panel>
            </Collapse>))}
        </div>

        <div>
          <h4 className='general-assessment-subtitle py-4' >Organisation Attributes</h4>
          {organisationAttributes?.map((item: any, index: any) => (
            <Collapse
              key={item.questionaireId} className='my-2'
              size="small" expandIconPosition={"end"}>
              {organisationErrors[index] && <ErrorMessage message='Comment is required*' />}
              <Panel header={<div className='flex justify-between key-questions items-center'>
                <p className='question-title'>{item?.question ? item?.question : item?.questionaire?.question}</p>
                <div className="form-item" onClick={(e) => e.stopPropagation()}>
                  <Select disabled={isView}
                    placeholder="Select your rating"
                    options={ratings}
                    onChange={(e) => selectRating(index, "organisationalattributes", e)}
                    value={tempQuestionArray['organisationalattributes'][index]?.range ||
                      ratings?.filter((element: any) => element.value === item?.performanceRatingScale?.performanceRatingScaleId) ||
                      null}
                  />
                </div>
              </div >} key="4" >
                <TextArea disabled={isView} rows={2} style={{ resize: 'none' }} placeholder='Enter your comments'
                  onChange={(e) => { handleTextArea(e, "organisationalattributes", index) }}
                  value={tempQuestionArray['organisationalattributes']?.[index]?.answer} />
              </Panel >
            </Collapse >))}
        </div >

        <TextAreaRendering tempQuestionArray={tempQuestionArray} appraisercommentsNew={appraisercommentsNew} handleAppraisals={handleAppraisals} significantAchievementsError={significantAchievementsError} improvementAreasError={improvementAreasError}
          technicalSkillsError={technicalSkillsError} softSkillsError={softSkillsError} appraiserCommentsError={appraiserCommentsError} isView={isView} />

        <RevisedSalary values={values} setFieldValue={setFieldValue} salaryDate={salaryDate} />
        {/* <AppraisalForm isView={isView} ctcDetails={ctcDetails} handleCloseModal={handleCloseModal} setChangeCtcModal={setChangeCtcModal}
          questionObject={tempQuestionArray} changeCtcModal={changeCtcModal}
          reviewType={reviewtype !== undefined && reviewtype} salaryRevised={salaryRevised}
          probationExtension={probationExtension} incrementEligible={incrementEligible}
          getReviewsApi={getReviews} isPending={isPending}
          employeeReviewId={employeeReviewId} userId={userId} /> */}
        {
          isView && !isPending && (
            <div data-testid='button-id'>
              {nextButton && (
                <div className='flex justify-end mr-5 salary_add-btn'>
                  <button className='addButton ' onClick={() => openChangeSalaryModal()}>
                    Next
                  </button>
                </div>)}
            </div>
          )
        }
        {
          !isView && <AppraisalButtonRendering incrementEligible={incrementEligible}
            touched={touched} handelSaveRevise={handelSaveRevise}
            handelChangeSalary={handelChangeSalary} />
        }
        {
          changeCtcModal && (
            <Modal title='Salary Increment' centered open={changeCtcModal} onCancel={closeChangeSalary} footer={false} maskClosable={false}>
              <AppraisalForm isView={isView} ctcDetails={ctcDetails} handleCloseModal={handleCloseModal} setChangeCtcModal={setChangeCtcModal}
                questionObject={tempQuestionArray} changeCtcModal={changeCtcModal}
                reviewType={reviewtype !== undefined && reviewtype} salaryRevised={salaryRevised}
                probationExtension={probationExtension} incrementEligible={incrementEligible}
                getReviewsApi={getReviews} isPending={isPending}
                employeeReviewId={employeeReviewId} userId={userId} />
            </Modal>
          )
        }
      </div >
      {closeConfirmationModal && <CloseModalForm handleCloseModal={handleClosed} closeConfirmationModal={closeConfirmationModal} setCloseConfirmationModal={setCloseConfirmationModal} />}
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
    </>
  )
}
export default AppraisalAssesment
