import React, { useState, useEffect, useRef } from 'react';
import { EditorState, DraftHandleValue, RichUtils, CompositeDecorator, ContentState, convertFromHTML, AtomicBlockUtils, Modifier, ContentBlock } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
// import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import ToolbarComponent, { toolbarPlugin } from './ToolbarComponent';

import '@draft-js-plugins/alignment/lib/plugin.css'; // Alignment plugin styles
import 'draft-js/dist/Draft.css';
import "./draft.css";
import '@draft-js-plugins/emoji/lib/plugin.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import { stateToHTML, } from 'draft-js-export-html';
import { Modal, Space, Input, Button } from 'antd'
import type { MenuProps } from 'antd';
import { ReactMailChips } from "react-mail-chips";
import { ErrorMessages, triggerNotification, useGetApiRequests, useSelector, retrieveData } from '../../common/CommonExport';
import {
  onBoldClick, onItalicClick, onUnderlineClick,
  onBulletedListClick, onNumberedListClick, onUndo, onRedo, convertTo24HourFormat,
  addLinkRef, blockStyleFn, blockStyleFn1, applyAlignment
} from './draftHelpers';
import ComposeMailToobar from './ComposeMailToobar';
import EditorToolbar from './EditorToolbar';
import { resetSchedule, setScheduleAnnouncement, setScheduleDate, setScheduleTime } from '../../redux/slices/announcementScheduleSlice';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { ResponseAnnouncement, EmailAnnouncementDto, ErrorResponse } from './SingleAnnouncementInterface';
import { fetchApiCall, setScheduleInfo, announcementSliceRootState } from '../../redux/slices/announcementIdSlice';
import { useNavigate } from 'react-router-dom';
import { GetAnnouncementDataProps, redirectRoutes, Attachment } from './ComposeMailUtiles';
import FilesUploadedList from './FilesUploadedList';
import PopupModal from '../employee/employee-details/employee-profile/modal/PopupModal';
import createImagePlugin from "@draft-js-plugins/image";

const MAX_TOTAL_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

const imagePlugin = createImagePlugin();


const Link: React.FC<{
  contentState: ContentState;
  entityKey: string;
  children: React.ReactNode;
}> = (props) => {
  const { contentState, entityKey, children } = props;
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      }, callback);
    },
    component: Link,
  },
]);

const ComposeMail = (props: any) => {
  const { scheduleDate, scheduleTime } = useSelector((state: any) => state.AnnouncementScheduleSlice);
  const announcementSlice = useSelector((state: announcementSliceRootState) => state.announcementIdSlice);
  const { isReload } = announcementSlice;
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const plugins: any = [emojiPlugin, toolbarPlugin, imagePlugin];
  const [editorState, setEditorState] = useState<any>(EditorState.createEmpty(decorator));
  const [cc, setCc] = useState<string[]>([]);
  const [bcc, setBcc] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>('');
  const [openCc, setOpenCc] = useState(false);
  const [openBcc, setOpenBcc] = useState(false);
  const [iconModal, setIconModal] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [attachedFile, setAttachedFile] = useState<File[]>([]);
  const [getAttachedFileNameList, setGetAttachedFileNameList] = useState<Attachment[]>([]);
  const [removedFiles, setRemovedFiles] = useState<Attachment[]>([]);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [imageLinkModalVisible, setImageLinkModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [linkURL, setLinkURL] = useState('');
  const [emailAnnouncementId, setEmailAnnouncementId] = useState<number | null | string>(null);
  const [sendBtnLoading, setSendBtnLoading] = useState<boolean>(false);
  const [draftBtnLoading, setDraftBtnLoading] = useState<boolean>(false);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null);
  const createdOn = new Date().toISOString();
  const userId = retrieveData('userId', true);

  const dispatch = useDispatch();
  const createAnnouncement = useGetApiRequests('createAnnouncement', 'POST');
  const updateAnnouncement = useGetApiRequests('updateAnnouncement', 'PUT');
  const getAnnouncement = useGetApiRequests('getAnnouncement', 'GET');
  const navigate = useNavigate();
  const formattedScheduleDate = scheduleDate ? dayjs(scheduleDate).format('YYYY-MM-DD') : null;
  const formattedScheduleTime = scheduleTime ? dayjs(scheduleTime).format('hh:mm A') : null;
  const openLinkModal = () => setLinkModalVisible(true);
  const closeLinkModal = () => { setLinkModalVisible(false); setLinkURL(''); };


  useEffect(() => {
    if (props.actionType && props.actionType == 'edit') {
      getAnnouncementDetails(props);
    }
  }, []);

  useEffect(() => {
    dispatch(resetSchedule());
  }, [dispatch]);


  const getAnnouncementDetails = (props: GetAnnouncementDataProps) => {
    if (props) {
      const emailAnnouncementId: number = props.announcementData.emailAnnouncementId
      if (emailAnnouncementId) {
        getSingleAnnouncementDetails(emailAnnouncementId);
      }
    }
  }
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  const getSingleAnnouncementDetails = (id: number) => {
    const pathParams = { id };
    setEmailAnnouncementId(id);
    getAnnouncement('', '', pathParams)
      .then((response: AxiosResponse<ResponseAnnouncement> | AxiosError) => {
        if (response && !(response instanceof AxiosError)) {
          const { status, message, data: announcementData } = response.data;
          if (status === 200) {
            const _toEmail = response.data.data.emailRecipient.toEmail?.split(',') ?? [];
            const _ccEmail = response.data.data.emailRecipient.ccEmail?.split(',') ?? [];
            const _bccEmail = response.data.data.emailRecipient.bccEmail?.split(',') ?? [];
            const _subject = response.data.data.subject ?? '';
            const _body: any = response.data.data.body;
            // setEditorState(_body);
            setEmails(_toEmail);
            setCc(_ccEmail);
            setBcc(_bccEmail);
            setSubject(_subject);
            const files = response.data.data.attachments || []; // Adjust according to your response structure             
            const mappedFiles: Attachment[] = files.map((file: any) => ({
              fileName: file.fileName,
              filePath: file.filePath,
              emailAttachmentId: file.emailAttachmentId,
            }));
            // Set the mapped files into the state
            setGetAttachedFileNameList(mappedFiles);

            const blocksFromHTML = convertFromHTML(_body);
            const _statecontent = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap,
            );

            try {
              const { scheduledTime } = response.data.data;
              if (scheduledTime) {
                const _dateTime = dayjs(scheduledTime);
                dispatch(setScheduleDate(_dateTime));
                dispatch(setScheduleTime(_dateTime));
              }
            } catch (error) {
              console.error("Error while processing scheduled time:", error);
            }
            const alignedEditorState = EditorState.createWithContent(_statecontent);
            setEditorState(alignedEditorState); // Set updated EditorState
          } else {
            // triggerNotification('error', '', message || 'Unexpected error', 'topRight');
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage(message || 'Unexpected error')
            setModalResponse('error')
            setTimeout(() => {
              handleClosePopUpModal()
            }, 1500)
          }
        } else {
          // triggerNotification('error', '', 'Invalid response format', 'topRight');
          setPopModal(true)
          setmodalButtonShow(false)
          setModalErrMessage('Invalid response format')
          setModalResponse('error')
          setTimeout(() => {
            handleClosePopUpModal()
          }, 1500)
        }
      })
      .catch((err: AxiosError) => {
        const errorResponse = err.response?.data as ErrorResponse;  // Use type assertion to access the `message` property safely
        const errorMessage = errorResponse?.message || 'Something went wrong';
        // triggerNotification('error', '', errorMessage, 'topRight');
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(errorMessage)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal()
        }, 1500)
      });
  };
  // Add link to the editor
  const addLink = async () => {
    if (linkURL.trim()) {
      let content = addLinkRef(linkURL.trim(), editorState);
      setEditorState(content);
    }
    // Close the link modal after applying the link
    closeLinkModal();
  };

  const handleIconModal = () => { setIconModal((prev) => !prev); };

  const validateEmailData = () => {
    if (!emails.length) {
      setPopModal(true)
      setmodalButtonShow(true)
      setModalErrMessage('Recipient email(s) cannot be empty.')
      setModalResponse('warning')
      return false;
    }
    if (!subject.trim()) {
      setPopModal(true)
      setmodalButtonShow(true)
      setModalErrMessage('Subject cannot be empty.')
      setModalResponse('warning')
      return false;
    }
    if (!stateToHTML(editorState.getCurrentContent()).trim()) {
      setPopModal(true)
      setmodalButtonShow(true)
      setModalErrMessage('Email body cannot be empty.')
      setModalResponse('warning')
      return false;
    }
    return true;
  };
  const handleToSendMail = (type: string) => {
    if (validateEmailData()) {
      // const contentAsHtml = stateToHTML(editorState.getCurrentContent());
      const contentAsHtml = stateToHTML(editorState.getCurrentContent(), {
        blockStyleFn: blockStyleFn1, // Using blockStyleFn for text alignment
      });
      let checkSchedule = false;
      let processType = type;
      let isStarred = false;
      isStarred = props.announcementData?.isStarred;
      if (isStarred == null) {
        isStarred = false;
      }
      const payload: { emailAnnouncementDto: EmailAnnouncementDto } = {
        "emailAnnouncementDto": {
          "senderCntUserId": userId,
          "subject": subject,
          "body": contentAsHtml,
          "status": type,
          "isStarred": false,
          "emailRecipient": {
            "toEmail": emails.join(','),
            "ccEmail": cc.length === 0 ? null : cc.join(','),
            "bccEmail": bcc.length === 0 ? null : bcc.join(',')
          },
          // "isActive": true,
          "createdOn": createdOn,
          "organisationId": orgId
        }
      };
      if (emailAnnouncementId !== null) {
        payload.emailAnnouncementDto.emailAnnouncementId = emailAnnouncementId;
      }
      if (formattedScheduleDate && formattedScheduleTime) {
        const formattedTime24 = convertTo24HourFormat(formattedScheduleTime);
        const scheduledTime = `${formattedScheduleDate}T${formattedTime24}:00+05:30`;
        payload.emailAnnouncementDto.scheduledTime = scheduledTime;
        payload.emailAnnouncementDto.status = type === 'DRAFT' ? 'DRAFT' : 'SCHEDULED';
        processType = type === 'DRAFT' ? 'DRAFT' : 'SCHEDULED';
        checkSchedule = true;
      }

      if (props.actionType === 'edit') {
        payload.emailAnnouncementDto.attachments = getAttachedFileNameList;
      }
      const formData = new FormData();  // Prepare FormData for file attachments
      if (attachedFile && attachedFile.length > 0) {
        attachedFile.forEach((file) => {
          formData.append('files', file); // Append each file under the 'files' key
        });
      } else {
        formData.append('files', "");
      }

      const headers = { 'Content-Type': 'multipart/form-data' };

      const apiCall = props.actionType === 'edit' ? updateAnnouncement : createAnnouncement;
      const pathParams = props.actionType === 'edit' ? { emailAnnouncementId: props.announcementData.emailAnnouncementId } : {};
      if (type == 'SENT') {
        setSendBtnLoading(true);
      } else {
        setDraftBtnLoading(true);
      }
      apiCall(formData, payload, pathParams, headers)
        .then((response: any) => {
          if (axios.isAxiosError(response)) {
            throw response;
          }
          const responseStatus = response?.status
          if (responseStatus === 200 || responseStatus === 201) {
            handleApiResponse(response, checkSchedule, processType);
          }
        })
        .catch(() => handleApiError())
        .finally(() => {
          setDraftBtnLoading(false);
          setSendBtnLoading(false);
        });
    }
  }
  const handleApiResponse = (response: any, checkSchedule: boolean, processType: string) => {
    if (checkSchedule) {
      const storeSchedule = response?.data;
      const id = storeSchedule?.emailAnnouncementId;
      dispatch(setScheduleInfo({
        isActiveAlert: true,
        data: storeSchedule
      }));
      dispatch(setScheduleAnnouncement(id));
    }
    dispatch(fetchApiCall(!isReload));
    // triggerNotification('success', '', response?.data?.message, 'topRight');
    setPopModal(true)
    setmodalButtonShow(false)
    setModalErrMessage(response?.data?.message)
    setModalResponse('success')
    setTimeout(() => {
      handleClosePopUpModal()
      props?.setSendMail(false); // Close the modal after success
      if (processType) {
        const route = redirectRoutes[processType];
        if (route) {
          navigate(route);
        }
      }
    }, 1500)

  };
  // Helper function to handle API errors
  const handleApiError = () => {
    // triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
    setPopModal(true)
    setmodalButtonShow(false)
    setModalErrMessage(ErrorMessages.somethingWentWrong)
    setModalResponse('error')
    setTimeout(() => {
      handleClosePopUpModal()
      props?.setSendMail(false);
    }, 1500)

  };
  const handleKeyCommand = (command: any, state: any) => {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files); // Convert the FileList to an array and update state
      const currentTotalSize = attachedFile.reduce((acc, file) => acc + file.size, 0); // Calculate total size of already attached files
      const newFilesSize = newFiles.reduce((acc, file) => acc + file.size, 0); // Calculate size of new files

      if (currentTotalSize + newFilesSize > MAX_TOTAL_FILE_SIZE) {
        // alert('Total file size exceeds 10MB. Please remove some files or choose smaller files.'); // Notify user
        // triggerNotification('error', '', 'Total file size exceeds 10MB. Please remove some files or choose smaller files.', 'topRight');
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage('Total file size exceeds 10MB. Please remove some files or choose smaller files.')
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal()
        }, 1500)
        return;
      }
      setAttachedFile(prevFiles => [...prevFiles, ...newFiles]);

      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 0);
    }
  };

  const handleInsertImageByURL = () => {
    if (!imageUrl) return;
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp"];
    const isValidImage = imageExtensions.some(ext => imageUrl.toLowerCase().endsWith(ext));

    if (!isValidImage) {
      setPopModal(true)
      setmodalButtonShow(true)
      setModalErrMessage("Invalid image URL. Please provide a valid image link.")
      setModalResponse('warning')
      return;
    }
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: imageUrl });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");
    setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));

    setImageLinkModalVisible(false);
    setImageUrl("");
  };
  const handleFileSelect = () => {
    document.getElementById('announcementFileUpload')?.click();
  }
  const handleRemoveAttachment = (index: number) => {
    setAttachedFile((prevFiles) => prevFiles.filter((_, i: number) => i !== index));
  }
  // Wrapper function to handle updates
  const handleEmailsChange = (field: 'emails' | 'cc' | 'bcc', newEmails: string[]) => {
    const trimmedEmails = newEmails.map(email => email.trim());
    let duplicateEmails: string[] = [];
    const filteredEmails = trimmedEmails.filter(email => {
      const isDuplicate =
        (field === 'emails' && (cc.includes(email) || bcc.includes(email))) ||
        (field === 'cc' && (emails.includes(email) || bcc.includes(email))) ||
        (field === 'bcc' && (emails.includes(email) || cc.includes(email)));

      if (isDuplicate) duplicateEmails.push(email);
      return !isDuplicate;
    });

    if (duplicateEmails.length > 0) {
      // triggerNotification('warning', '', `Duplicate email not allowed`, 'topRight'
      // );
      setPopModal(true)
      setmodalButtonShow(true)
      setModalErrMessage(`Duplicate email not allowed`)
      setModalResponse('warning')
    }

    if (field === 'emails') setEmails(filteredEmails);
    if (field === 'cc') setCc(filteredEmails);
    if (field === 'bcc') setBcc(filteredEmails);
  };
  // Function to handle click outside
  const handleClickOutside = (event: any) => {
    if (event) {
      setOpenCc(false);
      setOpenBcc(false)
    }
  };
  const removeAttachedFile = (index: number) => {
    const removedItem = getAttachedFileNameList[index];
    const updatedFilesList = getAttachedFileNameList.filter((_, i) => i !== index);
    setGetAttachedFileNameList(updatedFilesList);
    setRemovedFiles((prev) => [...prev, removedItem]);
  };

  const applyAlignmentPosition = (newStyle: any) => {
    const source = applyAlignment(newStyle, editorState);
    setEditorState(source);
  };
  const handleImageUrlModalOpen = () => {
    setImageLinkModalVisible(true)
  }

  const handlePastedFiles = (files: Blob[]): DraftHandleValue => {
    const file = files[0];

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result as string;
      };
      reader.readAsDataURL(file);
      return "handled";
    }
    return "not-handled";
  };

  const handlePastedText = (
    text: string,
    html: string | undefined,
    editorState: EditorState
  ): DraftHandleValue => {
    if (text) {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const newContentState = Modifier.replaceText(
        contentState,
        selection,
        text
      );

      const newEditorState = EditorState.push(editorState, newContentState, "insert-characters");

      setEditorState(newEditorState);

      return "handled";
    }

    return "not-handled";
  };
  return (
    <div>
      <div className='w-full h-auto pl-[10px]'>
        <div className=" flex items-center  border-b mt-6 justify-between">
          <div className=' flex items-center w-[91%]'>
            <label className="block text-[#D34A7C] font-[400] text-[18px]  mb-1 font-rubik" htmlFor="to">
              To:
            </label>
            <ReactMailChips emails={emails} setEmails={(newEmails) => handleEmailsChange('emails', newEmails)} className="font-rubik text-[16px]  w-full py-2 px-3 border-b-0 focus:outline-none focus:shadow-outline" />
          </div>
          {!openCc ? (
            <div className=' flex  w-[9%]'>
              <p className=' px-2 text-[#707070] text-[17px] font-rubik cursor-pointer hover:underline' onClick={() => setOpenCc(true)}>Cc</p>
              <p className=' px-2 text-[#707070] text-[17px] font-rubik cursor-pointer hover:underline' onClick={() => setOpenBcc(true)}>Bcc</p>
            </div>
          ) : null}
        </div>
        {openCc && (
          <div className='border-b mt-2 flex items-center  justify-between'>
            <div className=' flex items-center w-[93%]'>
              <label className="block text-[#D34A7C] font-[400] text-[18px]  mb-1 font-rubik" htmlFor="cc">
                Cc:
              </label>
              <ReactMailChips emails={cc} setEmails={(newEmails) => handleEmailsChange('cc', newEmails)} className="font-rubik text-[16px]  w-full py-2 px-3 border-b-0 focus:outline-none focus:shadow-outline" />
            </div>
            {!openBcc ? (
              <div className=' flex  w-[7%]'>
                <p className=' px-2 text-[#707070] text-[17px] font-rubik cursor-pointer' onClick={() => setOpenBcc(true)}>Bcc</p>
              </div>
            ) : null}
          </div>
        )}
        {openBcc && (
          <div className='border-b mt-2 flex items-center  justify-between'>
            <div className=' flex items-center w-[98%]'>
              <label className="block text-[#D34A7C] font-[400] text-[18px]  mb-1 font-rubik" htmlFor="bcc">
                Bcc:
              </label>
              <ReactMailChips emails={bcc} setEmails={(newEmails) => handleEmailsChange('bcc', newEmails)} className="font-rubik text-[16px]  w-full py-2 px-3 border-b-0 focus:outline-none focus:shadow-outline" />
            </div>
            <div>
            </div>
          </div>
        )}

        <div className="mb-2 flex items-center border-b py-4">
          <label className="block text-[#D34A7C] font-[400]  text-[18px] font-rubik" htmlFor="subject">
            Subject:
          </label>
          <input
            className="w-full  px-3 border-b-0 font-medium text-[17px] font-rubik focus:outline-none focus:shadow-outline"
            id="subject"
            type="text"
            placeholder=""
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>

        <div ref={containerRef} className={`body-content   ${iconModal ? "body-content-edit" : ""} `}>
          <input type="file" accept="image/*" id="announcementFileUpload" name="announcementFileUpload"
            onChange={handleImageUpload} style={{ display: 'none' }} multiple />
          <Editor
            onFocus={handleClickOutside}
            editorState={editorState}
            onChange={(newEditorState) => setEditorState(newEditorState)}
            handleKeyCommand={handleKeyCommand}
            plugins={plugins}
            blockStyleFn={blockStyleFn}
            handlePastedText={handlePastedText}
            handlePastedFiles={handlePastedFiles}
          />

          <FilesUploadedList
            attachedFile={attachedFile}
            getAttachedFileNameList={getAttachedFileNameList}
            iconModal={iconModal}
            handleRemoveAttachment={handleRemoveAttachment}
            removeAttachedFile={removeAttachedFile}
          />
        </div>
      </div>
      <div className=' flex justify-between border-t pt-[10px]'>

        <Modal title="Insert Link" visible={linkModalVisible} onOk={addLink} onCancel={closeLinkModal}>
          <Input placeholder="Enter URL" value={linkURL} onChange={(e) => setLinkURL(e.target.value)} />
        </Modal>
        <Modal
          title="Insert Image URL"
          open={imageLinkModalVisible}
          onOk={handleInsertImageByURL}
          onCancel={() => setImageLinkModalVisible(false)}
        >
          <Input
            placeholder="Enter Image URL"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
        </Modal>
        {iconModal && (
          <EditorToolbar editorState={editorState} setEditorState={setEditorState} openCc={openCc}
            onUndo={onUndo} onRedo={onRedo} onBoldClick={onBoldClick} onItalicClick={onItalicClick}
            onUnderlineClick={onUnderlineClick} onBulletedListClick={onBulletedListClick}
            onNumberedListClick={onNumberedListClick} applyAlignmentPosition={applyAlignmentPosition}
          />
        )}
        {
          <ComposeMailToobar
            handleIconModal={handleIconModal} handleFileSelect={handleFileSelect}
            formattedScheduleDate={formattedScheduleDate} iconModal={iconModal}
            formattedScheduleTime={formattedScheduleTime} openLinkModal={openLinkModal}
            handleImageUrlModalOpen={handleImageUrlModalOpen}

          />
        }

        <div className=' Dynamic-emoji'>
          <EmojiSuggestions />
          <EmojiSelect />
        </div>

        <div className=' space-x-2'>
          {
            formattedScheduleDate && formattedScheduleTime ? null : (
              <Button loading={draftBtnLoading}
                className=' cursor-pointer text-[#D34A7C] bg-[#FFF5F9] border border-[#D23B7D] w-[130px]  h-[45px] rounded-3xl text-[18px] font-rubik hover:opacity-60  hover:transition-opacity'
                onClick={() => handleToSendMail('DRAFT')}>Draft</Button>
            )
          }
          <Button loading={sendBtnLoading}
            className=' cursor-pointer text-white bg-[#D23B7D] w-[130px]  h-[45px] rounded-3xl text-[18px] font-rubik hover:opacity-70  hover:transition-opacity hover:text-white'
            onClick={() => handleToSendMail('SENT')}>
            {
              formattedScheduleDate && formattedScheduleTime ? 'Schedule' : 'Send'
            }
          </Button>
        </div>
      </div>
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

    </div >
  );
};
export default ComposeMail;