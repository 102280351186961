import React, { useEffect, useState } from 'react';
import { Input, Modal, DatePicker } from 'antd'
import { ErrorMessage, Form, Formik } from 'formik';
import holidayValidationSchema from './HolidayValidationSchema';
import dayjs from 'dayjs';
import { useGetApiRequests } from '../../../common/CommonExport';
import ModalLoader from '../../../components/Loader/ModalLoader';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';

const EditHoliday = (props: any) => {
  const { open, handleClosepopup, editRecord, isEdit, holidayApi } = props;
  const dateFormat = 'DD.MM.YYYY';
  const editHolidayApi = useGetApiRequests('editHoliday', 'PUT');
  const holidayDate = editRecord?.holidayOn;
  const [loader, setLoader] = useState(false);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  const [initialValues, setInitialValues] = useState<any>({
    holidayName: '',
    holidayDate: ''
  })
  const handleCloseModal = () => {
    handleClosepopup()
  }
  const handleClose = () => {
    setPopModal(false)
    handleCloseModal()
  }
  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        holidayName: editRecord?.name,
        holidayDate: editRecord?.holidayOn
      })
    }
  }, [])
  const handleHolidayDate = (date: any, dateString: any, setFieldValue: any) => {
    setFieldValue('holidayDate', dateString)
  }
  const convertDateFormat = (dateString: any) => {
    const dateParts = dateString.split('.');
    const formattedDate = `${dateParts[2]}-${dateParts[1].padStart(2, '0')}-${dateParts[0].padStart(2, '0')}`;
    return formattedDate;
  }
  const handleSubmit = (values: any) => {
    setLoader(true);
    const payload: any = {
      "holidayDetailId": editRecord?.key,
      "name": values?.holidayName,
      "holidayOn": convertDateFormat(values?.holidayDate)
    }
    const pathParams: any = {
      id: editRecord?.key
    }
    editHolidayApi(payload, '', pathParams)
      .then((response: any) => {
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(response?.data?.message)
        setModalResponse('success')
        setTimeout(() => {
          setLoader(false)
          handleClose()
        }, 1500)

        holidayApi();
      })
      .catch((err: any) => {
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.message)
        setModalResponse('error')
        setTimeout(() => {
          setLoader(false)
          handleClose()
        }, 1500)

      });
  }
  return (
    <div>
      <Modal title={'Edit Holiday'} centered open={open} onCancel={() => handleCloseModal()} footer={null} className="modal-uploadDocument" maskClosable={false}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          values={initialValues}
          enableReinitialize={true}
          validationSchema={holidayValidationSchema}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="p-6 documentUpload holiday">
                  <div className="attenance-upload">
                    <div className='form-item'>
                      <div className=' chapter-document flex justify-between w-full py-4'>
                        <label className='w-28 text-[#878787] flex items-center text-[16px]'>Holiday Name*</label>
                        <Input name='holidayName' className='w-[65%] h-11' placeholder="Pooja Holidays" value={values?.holidayName} onChange={(e) => setFieldValue('holidayName', e.target.value)} />
                      </div>
                      <div>
                        <ErrorMessage name="holidayName" component="div" className="error-message" />
                      </div>
                      <div className=' chapter-document justify-between flex w-full py-2'>
                        <label className='w-28 text-[#878787] flex items-center text-[16px]'>Date*</label>
                        <DatePicker name='holidayDate' defaultValue={dayjs(holidayDate, dateFormat)} format="DD.MM.YYYY"
                          className='w-[65%] h-11 ' onChange={(date: any, dateString: any) => handleHolidayDate(date, dateString, setFieldValue)} />
                      </div>
                      <div>
                        <ErrorMessage name="holidayDate" component="div" className="error-message" />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-20">
                    <button className="addButton cancel-leave  w-32 mr-5" onClick={() => handleCloseModal()}>Cancel</button>
                    <button type='submit' className="addButton px-6 w-32" >Save</button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
        {loader && <ModalLoader />}
      </Modal>
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClose} response={modalResponse} modalButtonShow={modalButtonShow} />}
    </div>
  )
}

export default EditHoliday