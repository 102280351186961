import { useSelector, useDispatch } from "react-redux";
import { fetchApiCall } from "../../../../redux/slices/announcementIdSlice";
import { useGetApiRequests, triggerNotification } from '../../../../common/CommonExport';
import { Tooltip } from "antd";
import PopupModal from "../../../employee/employee-details/employee-profile/modal/PopupModal";
import { useState } from "react";

interface Props {
  data: {
    emailAnnouncementId: string;
    isStarred: boolean;
  };
  loader: boolean;
  setLoader: (value: boolean) => void;
}

interface AnnouncementSlice {
  isReload: boolean;
}

export default function Starred({ data, setLoader }: Props) {
  const dispatch = useDispatch();
  const announcementSlice = useSelector((state: { announcementIdSlice: AnnouncementSlice }) => state.announcementIdSlice);
  const isReload = announcementSlice.isReload;
  const setStarred = useGetApiRequests("setStarred", "PUT");
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  const handleStarredProcess = (starred: boolean) => {
    setLoader(true)

    const queryParams: any = {
      isStarred: !starred,
    };
    const pathParams = {
      id: data.emailAnnouncementId,
    };
    setStarred("", queryParams, pathParams)
      .then((res: any) => {
        const responseStatus = res?.status
        if (responseStatus === 200) {
          setTimeout(() => {
            dispatch(fetchApiCall(!isReload));
          }, 1000)
        }
      })
      .catch((err) => {
        // triggerNotification("error", "", err?.response?.data?.message, "topRight");
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.response?.data?.message)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal()
        }, 1500)
        setLoader(false)
      })
  };
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  return (
    <div>
      <Tooltip title={`${data?.isStarred ? 'Starred' : 'Not starred'}`}>
        <div
          className={`star-icon cursor-pointer ${data?.isStarred ? "filled" : ""}`}
          onClick={() => handleStarredProcess(data?.isStarred)}
        >
          <span className="icon cursor-pointer">&#9733;</span>
        </div>
      </Tooltip>
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
    </div>
  );
}
