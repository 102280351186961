import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import LeaveEmployees from './LeaveEmployees';
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import { useSelector } from 'react-redux';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';

export default function EmployeeAttendance({ dashboardData }: any) {
    const [sendMail, setSendMail] = useState<boolean>(false)
    const getWhosonLeaveApi = useGetApiRequests('getWhosOnLeave', 'POST');
    const orgId = useSelector((state: any) => state?.organisationId?.id);
    const [leaveList, setLeaveList] = useState([]);
    const [spliceList, setSpliceList] = useState([])
    const [splicing, setSplicing] = useState(false);
    const [permissionCount, setPermissionCount] = useState(0);
    const [onDutyCount, setOnDutyCount] = useState(0);
    const [fullAttendance, setFullAttendance] = useState(false)
    const [modalerrMessage, setModalErrMessage] = useState('')
    const [popModal, setPopModal] = useState(false)
    const [modalResponse, setModalResponse] = useState('')
    const [modalButtonShow, setmodalButtonShow] = useState(true)

    useEffect(() => {
        getTodaysLeave()
    }, [])
    const getTodaysLeave = () => {
        const payload: any = {
            "organisationId": orgId,
            "status": "approved"
        }
        getWhosonLeaveApi(payload).then((responseData: any) => {
            const apiData: any = responseData?.data?.data?.today;
            apiData.forEach((item: any) => {
                const initials = item.employeeName
                    .split(' ')
                    .map((namePart: any, index: number) => {
                        if (index === 0 || index === (item.employeeName.split(' ').length - 1)) {
                            return namePart.charAt(0).toUpperCase();
                        } else {
                            return '';
                        }
                    })
                    .join('');
                item.initials = initials;
            });
            const permissionCountNew = apiData.filter((item: any) => item?.leaveType === 'Permission').length
            setPermissionCount(permissionCountNew)
            const onDutyCountNew = apiData.filter((item: any) => item?.leaveType === 'On-duty').length
            setOnDutyCount(onDutyCountNew)
            if (apiData.length !== 0 && apiData.length < 9) {
                setLeaveList(apiData)
                setSplicing(false)
                setFullAttendance(false)
            } else if (apiData.length !== 0 && apiData.length > 9) {
                setLeaveList(apiData)
                setSplicing(true)
                const shallowArray: any = apiData.slice(0, 9);
                setSpliceList(shallowArray)
                setFullAttendance(false)
            } else if (apiData.length === 0) {
                setFullAttendance(true)
            }
        })
            .catch((err: any) => {
                // triggerNotification('error', '', err?.message, 'topRight');
                setPopModal(true)
                setmodalButtonShow(false)
                setModalErrMessage(err?.message)
                setModalResponse('error')
                setTimeout(() => {
                    handleClosePopUpModal();
                }, 1500)
            });
    }
    const statsData = [
        { title: 'Org. Strength', value: dashboardData?.totalEmployees },
        { title: 'On Leave', value: leaveList.length },
        { title: 'Permission', value: permissionCount },
    ];
    const handelCancel = () => {
        setSendMail(false)
    }
    const handleClosePopUpModal = () => {
        setPopModal(false)
    }

    const handelLeave = () => {
        setSendMail(true)
    }
    const formatDates = (inputDate: any) => {
        const date = new Date(inputDate);
        const day = ("0" + date.getDate()).slice(-2);
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
    const handelPopUp = (items: any) => {
        return (
            <div className="leave-tooltip w-[240px] bg-[#F5F5F5] h-[70px] pt-1 m-0">
                <p className='text-[12px] pb-1'>{items?.employeeName}</p>
                <div className="w-full flex">
                    <div className="w-1/3">
                        <p className='text-[#838383] text-[11px] font-rubik'>From:</p>
                        <span className='text-[#000] text-[12px] font-rubik'>{formatDates(items?.startDate)}</span>
                    </div>
                    <div className="w-1/3">
                        <p className='text-[#838383] text-[11px] font-rubik'>To:</p>
                        <span className='text-[#000] text-[12px] font-rubik'>{formatDates(items?.endDate)}</span>
                    </div>
                    <div className="w-1/3">
                        <p className='text-[#838383] text-[11px] font-rubik'>Duration:</p>
                        <span className='text-[#000] text-[12px] font-rubik'>{items?.durationOfLeave}D</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='employee-attendance '>
                <div className='grid md:grid-cols-3 md:justify-between md:pt-0 pt-3'>
                    {statsData.map((stat, index) => (
                        <div key={index} className='w-[125px]'>
                            <h6 className='text-center'>{stat.title}</h6>
                            <p className='flex justify-center items-center'>
                                <span className='font-medium font-rubik'>{stat.value}</span>
                            </p>
                        </div>
                    ))}
                </div>
                <div className='employee-leave'>
                    <div className='grid grid-cols-1'>
                        <div>
                            <h6>Who's on leave</h6>
                            {!splicing && !fullAttendance ? (
                                <ul className='flex'>
                                    {leaveList.map((code: any, index: any) => (
                                        <Tooltip key={code?.employeeId} placement="top" title={handelPopUp(code)}>
                                            <li key={code?.employeeId} className='leave-design font-rubik'>{code?.initials}</li>
                                        </Tooltip>
                                    ))}
                                </ul>) : (splicing && !fullAttendance) ? (
                                    <ul className='flex'>
                                        {spliceList.map((code: any, index: any) => (
                                            <Tooltip key={code?.employeeId} placement="top" title={handelPopUp(code)}>
                                                <li key={code?.employeeId} className='leave-design font-rubik'>{code?.initials}</li>
                                            </Tooltip>
                                        ))}
                                        {splicing && <button className='text-[#d34a7c]' onClick={handelLeave}>View More </button>}
                                    </ul>
                                ) : (
                                <div className='text-[#d34a7c]'>{ErrorMessages?.fullAttendance}</div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            {sendMail && <LeaveEmployees open={sendMail} leaveList={leaveList} handelCancel={handelCancel} />}
            {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
        </div>
    );
}
