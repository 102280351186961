import { Button, Modal } from "antd";
import React from "react";
interface UnauthorizedModalProps {
  open: boolean;
  handleModalClose: () => void;
  message: string;
  response: string;
  modalButtonShow: Boolean;
}

const PopupModal: React.FC<UnauthorizedModalProps> = ({ open, handleModalClose, message, response, modalButtonShow }) => {


  return (
    <div className="Error-popup-modal-container">
      <Modal
        className="p-10  Error-popup-modal rounded-lg"
        maskClosable={false}
        centered
        open={open}
        footer={false}
        onCancel={handleModalClose}
        closable={false}
        width={580}
      >
        <div className="Error-popup-modal-contant">
          <div className="modal-body  py-4  ">
            <div className="flex w-full justify-center items-center flex-col">
              <div className="flex justify-center mt-2">
                {response === 'success' ? (<span className="material-symbols-outlined text-green-500 text-[60px]">
                  check_circle
                </span>) : <span className="material-symbols-outlined text-red-500 text-[60px]">
                  error
                </span>}
              </div>
              <p className="text-[18px] text-center font-Rubik font-semibold mt-4">{message}</p>
            </div>
          </div>
          {modalButtonShow && (<div className="modal-footer pt-4">
            <div className="w-full flex justify-center">
              <Button className=' w-[90px] text-center ' type="primary" shape="round" size={"large"} onClick={handleModalClose} >
                Ok
              </Button>
            </div>
          </div>)}

        </div>
      </Modal>
    </div>
  );
};

export default PopupModal;
