import { DatePicker } from 'antd';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import calender from "../../../../../../assets/images/calender-week.svg"
import dayjs, { Dayjs } from 'dayjs';
import { triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import Loader from '../../../../../../components/Loader/Loader';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProfileLoad } from '../../../../../../redux/slices/updateProfileSlice';
import PopupModal from '../../modal/PopupModal';

const DeprovisionForm = ({ formDetails }: any) => {
  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const [resignDate, setResignDate] = useState('')
  const [getresignDate, setGetResignDate] = useState(employeeDetails?.relievingDate)
  const updateEmployeeDetails = useGetApiRequests('companyEmployeeDetails', 'PUT');
  const [loader, setLoader] = useState<boolean>(false)
  const { id } = useParams();
  const dispatch = useDispatch();
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  let hrDetails: any, itAdminDetails: any, financeDetails: any, remortingManagerDetails: any;
  formDetails?.employeeDeprovisionKVDto?.map((item: any) => {
    if (item?.inchargeDescription === 'HR Admin') {
      hrDetails = item?.inchargeEmployee
    } else if (item?.inchargeDescription === 'IT Admin') {
      itAdminDetails = item?.inchargeEmployee
    } else if (item?.inchargeDescription === 'Finance Admin') {
      financeDetails = item?.inchargeEmployee
    } else if (item?.inchargeDescription === 'Reporting Manager') {
      remortingManagerDetails = item?.inchargeEmployee
    }
  })
  const RelievingDateOnSelect = (dateString: any) => {
    setResignDate(dateString);
    setGetResignDate(dateString)
  }
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }

  const habdleRelievingDate = () => {
    setLoader(true)
    const insidePayload = {
      firstName: employeeDetails?.firstName,
      lastName: employeeDetails?.lastName,
      relievingDate: resignDate,
      dateOfBirth: employeeDetails?.dateOfBirth,
      bloodGroup: employeeDetails?.bloodGroup,
      fatherOrSpouseName: employeeDetails?.fatherOrSpouseName || "",
      fatherName: employeeDetails?.fatherName || "",
      motherName: employeeDetails?.motherName || "",
      personalContactNo: employeeDetails?.personalContactNo || "",
      personalEmail: employeeDetails?.personalEmail || "",
      aadharNumber: employeeDetails?.aadharNumber || "",
      panNumber: employeeDetails?.panNumber || "",
      uanNumber: employeeDetails?.uanNumber || "",
      esiNumber: employeeDetails?.esiNumber || "",
      gender: employeeDetails?.gender || "",
      isMarried: employeeDetails?.isMarried || false,
      sameAsCurrentAddress: employeeDetails?.sameAsCurrentAddress || false,
      presentAddress: {
        doorNo: employeeDetails?.presentAddress?.doorNo || "",
        street: employeeDetails?.presentAddress?.street || "",
        city: employeeDetails?.presentAddress?.city || "",
        state: employeeDetails?.presentAddress?.state || "",
        pinCode: employeeDetails?.presentAddress?.pinCode || "",
        addressId: employeeDetails?.presentAddress?.addressId || "",
      },
      permanentAddress: {
        doorNo: employeeDetails?.permanentAddress?.doorNo || "",
        street: employeeDetails?.permanentAddress?.street || "",
        city: employeeDetails?.permanentAddress?.city || "",
        state: employeeDetails?.permanentAddress?.state || "",
        pinCode: employeeDetails?.permanentAddress?.pinCode || "",
        addressId: employeeDetails?.permanentAddress?.addressId || "",
      },
      emergencyContactNo1: employeeDetails?.emergencyContactNo1 || "",
      emergencyContactNo1Relationship: employeeDetails?.emergencyContactNo1Relationship || "",
      emergencyContactNo2: employeeDetails?.emergencyContactNo2 || "",
      emergencyContactNo2Relationship: employeeDetails?.emergencyContactNo2Relationship || "",
      profileUrl: employeeDetails?.profileUrl || "",
    };

    const outerPayload = {
      employeeDto: insidePayload,
      companyDto: {},
    };
    const formData = new FormData();
    const headers = { 'Content-Type': 'multipart/form-data' };
    updateEmployeeDetails(formData, { employeeInformationDto: outerPayload }, { id: id }, headers)
      .then((res: any) => {
        dispatch(setProfileLoad());
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(res?.data?.status?.message)
        setModalResponse('success')
        setTimeout(() => {
          handleClosePopUpModal();
          setLoader(false);
        }, 1500)
      })
      .catch((err: any) => {
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.response?.data?.message)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal();
          setLoader(false);
        }, 1500)
      })
  }
  const disabledDate = (current: Dayjs) => {
    return current.isBefore(dayjs().startOf('day'));
  };
  function safeGetValue(obj: any, defaultValue = '') {
    return obj !== null && obj !== undefined ? obj : defaultValue;
  }

  return (
    <div className='flex w-full bg-[#FCF7FB] min-h-[125px] pt-3 px-5 2xl:px-10 gap-3 2xl:gap-0'>
      <div className="w-1/3">
        <div className="flex w-full py-1">
          <p className='text-[#595959] text-[13px] 2xl:text-[16px] w-1/2 font-rubik font-medium'>HR Incharge</p>
          <p className='text-[#878787] text-[13px] 2xl:text-[16px] font-rubik '>{safeGetValue(hrDetails?.firstName, "-") + " " + safeGetValue(hrDetails?.lastName)}</p>
        </div>
        <div className="flex w-full py-1 ">
          <p className='text-[#595959] text-[13px] 2xl:text-[16px] w-1/2  font-rubik font-medium'>IT Incharge</p>
          <p className='text-[#878787] text-[13px] 2xl:text-[16px] font-rubik '>{safeGetValue(itAdminDetails?.firstName, "-") + " " + safeGetValue(itAdminDetails?.lastName)}</p>
        </div> <div className="flex w-full py-1">
          <p className='text-[#595959] text-[13px] 2xl:text-[16px] w-1/2  font-rubik font-medium'>Finance Incharge</p>
          <p className='text-[#878787] text-[13px] 2xl:text-[16px] font-rubik '>{safeGetValue(financeDetails?.firstName, "-") + " " + safeGetValue(financeDetails?.lastName)}</p>
        </div>
      </div>
      <div className="w-1/3">
        {/* <div className="flex w-full py-1">
          <p className='text-[#595959] text-[13px] 2xl:text-[16px] w-1/2 font-rubik font-medium'>Knowledge Transfer</p>
          <p className='text-[#878787] text-[13px] 2xl:text-[16px] font-rubik '>Darren Hickman</p>
        </div> */}
        <div className="flex w-full py-1 ">
          <p className='text-[#595959] text-[13px] 2xl:text-[16px] w-1/2  font-rubik font-medium'>Reporting Manager</p>
          <p className='text-[#878787] text-[13px] 2xl:text-[16px] font-rubik '>{safeGetValue(remortingManagerDetails?.firstName, "-") + " " + safeGetValue(remortingManagerDetails?.lastName)}</p>
        </div> <div className="flex w-full py-1">
          <p className='text-[#595959] text-[13px] 2xl:text-[16px] w-1/2  font-rubik font-medium'>Employee Exit Type</p>
          <p className='text-[#878787] text-[13px] 2xl:text-[16px] font-rubik '>{safeGetValue(employeeDetails?.status?.name, "-")}</p>
        </div>
      </div>

      <div className="w-1/3">
        <div className="flex w-full py-1">
          <p className='text-[#595959] text-[13px] 2xl:text-[16px] w-1/2 font-rubik font-medium'>Resignation Date</p>
          <p className='text-[#878787] text-[13px] 2xl:text-[16px] font-rubik '>{safeGetValue(employeeDetails?.dateOfResigned, "-")}</p>
        </div>
        <div className="flex w-full py-1 ">
          <p className='text-[#595959] text-[13px] 2xl:text-[16px] w-1/2  font-rubik font-medium'>Relieving Date</p>
          <div className='w-[180px] flex'>
            <DatePicker suffixIcon={<img src={calender} className='mb-2' />} placeholder='Select relieving date' name='relieving-date' className='float-control w-full' format="YYYY-MM-DD"
              onChange={(date, dateString) => { RelievingDateOnSelect(dateString) }} disabledDate={disabledDate} value={getresignDate ? dayjs(getresignDate) : null} />
            {employeeDetails?.relievingDate !== getresignDate &&
              <div className=' flex justify-end'><button disabled={employeeDetails?.relievingDate === getresignDate} className=' px-[5px] text-[13px] bg-[#D34A7C] text-white rounded-md ml-2' onClick={habdleRelievingDate}>Apply</button></div>
            }
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

    </div>
  )
}

export default DeprovisionForm