export const checkValueGreaterThanZero = (value: any) => {
  if (Number(value) > 0) {
    return Number(value);
  }
  return 0;
};

export const addEditSalaryParitulars = (apiResponse: any) => {
  const salaryParticularsData = apiResponse.filter((obj: any) => {
    if (obj.groupName && obj.groupName.trim() === "Salary Particulars") {
      if (obj.percent && obj.percent !== 0) {
        return Object.assign(obj, { monthly: "", yearly: "" });
      }
    }
  });
  return salaryParticularsData;
};
export const addEditEmployerContribution = (apiResponse: any) => {
  const employerContributionData = apiResponse.filter((obj: any) => {
    if (obj.groupName && obj.groupName.trim() === "Employer Contribution") {
      if (obj.percent && obj.percent !== 0) {
        return Object.assign(obj, { monthly: "", yearly: "" });
      }
    }
  });
  return employerContributionData;
};
export const addEditEmployeeContribution = (apiResponse: any) => {
  const tempEmployeeContribution = apiResponse.filter((obj: any) => {
    if (obj.groupName && obj.groupName.trim() === "Employee Contribution") {
      if (obj.percent && obj.percent !== 0) {
        return Object.assign(obj, { monthly: "", yearly: "" });
      } else {
        return Object.assign(obj, {
          monthly: obj.sectionName === "monthly" ? Number(obj.amount) : Number(obj.amount / 12),
          yearly: obj.sectionName === "yearly" ? Number(obj.amount) : Number(obj.amount * 12),
        });
      }
    }
  });
  return tempEmployeeContribution;
};
export const addEditFixedSalary = (apiResponse: any) => {
  const tempFixedSalaryParticulars = apiResponse
    .filter((obj: any) => obj.groupName && obj.groupName.trim() === "Salary Particulars" && (obj.percent === 0 || obj.percent == null))
    .map((obj: any) => {
      return Object.assign(obj, {
        monthly: obj.sectionName === "monthly" ? Number(obj.amount) : Number(obj.amount / 12),
        yearly: obj.sectionName === "yearly" ? Number(obj.amount) : Number(obj.amount * 12),
      });
    });
  return tempFixedSalaryParticulars;
};
export const addEditFixedEmployer = (apiResponse: any) => {
  const tempFixedEmployerContribution = apiResponse.filter((obj: any) => {
    if (obj.groupName && obj.groupName.trim() === "Employer Contribution" && (obj.percent === 0 || obj.percent === null)) {
      return Object.assign(obj, {
        monthly: obj.sectionName === "monthly" ? Number(obj.amount) : checkValueGreaterThanZero(0),
        yearly: obj.sectionName === "yearly" ? Number(obj.amount) : Number(obj.amount * 12),
      });
    }
  });
  console.log("TCL: addEditFixedEmployer -> tempFixedEmployerContribution", tempFixedEmployerContribution);
  return tempFixedEmployerContribution;
};
