import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import FloatLabel from '../../../components/FloatLabel';
import { Controller, Input, useForm, yupResolver, SubmitHandler, useGetApiRequests, triggerNotification, useSelector, ErrorMessages, ErrorMessage } from '../../../common/CommonExport';
import { CreateDepartmentSchema } from './CreateDepartmentSchema';
import { DepartmentApiProps, DepartmentProps } from '../OrganisationsInterfaces';
import ModalLoader from '../../../components/Loader/ModalLoader';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';
const CreateDepartmentModal: React.FC<DepartmentApiProps> = ({ departmentApi, setModalOpen, modalOpen, editRecord, editModal }: DepartmentApiProps) => {
    const getHodListApi = useGetApiRequests('hodList', 'GET');
    const createDepartmentApi = useGetApiRequests('createDepartment', 'POST');
    const editDepartmentApi = useGetApiRequests('editDepartment', 'PUT');
    const orgId = useSelector((state: any) => state?.organisationId?.id);
    const [hodList, setHodList] = useState<any>([]);
    const [propHodId, setPropHodId]: any = useState(editRecord?.hodId);
    const [errorHod, seterrorHod] = useState<boolean>(true);
    const [modalEdit, setModalEdit] = useState(editModal);
    const [createHod, setCreateHod] = useState<any>();
    const [loader, setLoader] = useState(false)
    const [modalerrMessage, setModalErrMessage] = useState('')
    const [popModal, setPopModal] = useState(false)
    const [modalResponse, setModalResponse] = useState('')
    const [modalButtonShow, setmodalButtonShow] = useState(true)

    useEffect(() => {
        setLoader(true)
        const params: any = {
            start: 0,
            limit: 100000,
            organisationId: orgId,
            isActive: true,
        };
        getHodListApi('', params)
            .then((response: any) => {
                const reformedHodList = response?.data?.data.map((item: any) => ({
                    value: item.employeeId,
                    email: item.officialEmail,
                    label: item.firstName + ' ' + item.lastName,
                    employeeId: item.employeeId,
                }));
                setLoader(false)
                setHodList(reformedHodList);
            })
            .catch((err: any) => {
                // triggerNotification('error', '', err?.message, 'topRight');
                setPopModal(true)
                setmodalButtonShow(false)
                setModalErrMessage(err?.message)
                setModalResponse('error')
                setTimeout(() => {
                    handleClosePopUpModal()
                    setLoader(false);
                }, 1500)
            });
        if (!modalEdit) {
            reset({ email: '', description: '', departmentName: '', hod: '' });
        } else if (modalEdit) {
            if (editRecord !== undefined && editRecord !== null && Object.keys(editRecord).length > 0) {
                setValue('departmentName', editRecord?.department);
                setValue('description', editRecord?.description);
                setValue('email', editRecord?.email);
                setValue('hod', editRecord?.hodId);
            }
        }
    }, []);
    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors },
    } = useForm<DepartmentProps>({ resolver: yupResolver(CreateDepartmentSchema) as any });
    const onChange = (value: string) => {
        if (modalEdit) {
            const hodTempListEdit = hodList.find((item: any) => item.value === value);
            setValue('hod', value);
            setPropHodId(value);
            seterrorHod(false);
            setValue('email', hodTempListEdit?.email);

        } else {
            const hodTempList = hodList.find((item: any) => item.value === value);
            setValue('hod', value);
            setPropHodId(value);
            setCreateHod(hodTempList?.value)
            seterrorHod(false);
            setValue('email', hodTempList?.email);
        }
    };
    const handleClose = () => {
        reset({ email: '', description: '', departmentName: '', hod: '' });
        setModalOpen(false);
        setModalEdit(false);
    };
    const handleApiCall = async (payload: object, pathParams: object, successMessage: string) => {
        try {
            await (modalEdit ? editDepartmentApi(payload, {}, pathParams) : createDepartmentApi(payload));
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage(successMessage)
            setModalResponse('success')
            setTimeout(() => {
                handleClosePopUpModal()
                setLoader(false);
            }, 1500)
            departmentApi();
        } catch (err: any) {
            // triggerNotification('error', '', err?.response?.data?.message, 'topRight');
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage(err?.response?.data?.message)
            setModalResponse('error')
            setTimeout(() => {
                handleClosePopUpModal()
                setLoader(false);
            }, 1500)
        }
    };
    const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const onSubmit: SubmitHandler<DepartmentProps> = (data: DepartmentProps) => {
        const payload: object = {
            description: data?.description,
            leadEmail: data?.email,
            name: data?.departmentName,
            organisationId: orgId,
            headOfDepartment: {
                employeeId: data?.hod,
            },
        };

        if (modalEdit) {
            const pathParams: object = {
                id: editRecord.key,
            };
            const editPayload: object = {
                ...payload,
                departmentId: editRecord.key,
            };
            handleApiCall(editPayload, pathParams, ErrorMessages.departmentUpdation);
        } else {
            handleApiCall(payload, {}, ErrorMessages.departmentCreation);
        }
    };

    const handleClosePopUpModal = () => {
        setPopModal(false)
        setModalOpen(false);
    }

    return (
        <Modal data-testid="modalEl" title={`${modalEdit ? 'Edit' : 'Create'} Department`} centered open={modalOpen} onCancel={handleClose} footer={null} maskClosable={false}>
            <div className='relative z-0 mt-5 '>
                <Controller name='departmentName' control={control} render={({ field }) => <Input type='text' id='departmentName' label='Department Name*' control={control} {...field} hasError={!!errors.departmentName} />} />
                {errors.departmentName && <ErrorMessage message={errors.departmentName.message} />}
            </div>
            <div className='form-item mt-5'>
                <FloatLabel label='Select HOD*' name='hod' value={modalEdit ? hodList.filter((item: any) => item.value === propHodId) : createHod}>
                    <Select className='float-control' showSearch filterOption={filterOption} options={hodList} value={modalEdit ? hodList.filter((item: any) => item.value === propHodId) : createHod} onChange={onChange} />
                </FloatLabel>
                {errors.hod && errorHod && <ErrorMessage message={errors.hod.message?.toString()} />}

            </div>

            <div className='relative z-0 mt-5 pointer-events-none'>
                <Controller name='email' control={control} render={({ field }) => <Input type='email' id='email' label='Department Email ID*' control={control} {...field} hasError={!!errors.email} />} />
                {errors.email && <ErrorMessage message={errors.email.message} />}
            </div>

            <div className='relative z-0 mt-5 '>
                <Controller name='description' control={control} render={({ field }) => <Input type='text' id='description' label='Description*' control={control} {...field} hasError={!!errors.description} />} />
                {errors.description && <ErrorMessage message={errors.description.message} />}
            </div>
            <div className='mt-5 flex justify-end '>
                <button className='addButton mr-4 secondary' onClick={handleClose}  >
                    Cancel
                </button>
                <button className='addButton mr-4 ' onClick={handleSubmit(onSubmit)}  >
                    {modalEdit ? 'Update' : 'Create'}
                </button>
            </div>
            {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

            {loader && <ModalLoader />}
        </Modal>

    );
};

export default CreateDepartmentModal;
