import React, { useState } from 'react'
import { Formik, ErrorMessage } from 'formik';
import { Form, Input, Button, Table, Switch } from 'antd';
import { triggerNotification, useGetApiRequests, useSelector, } from '../../../common/CommonExport';
import FloatLabel from '../../../components/FloatLabel';
import { validationSchema } from './AddEditRolesSchema';
import ModalLoader from '../../../components/Loader/ModalLoader';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';

const AddEditRender = (props: any) => {
  const { initialValues, isEdit, selectedRows, handleCloseModal, getRolesList, selectedRecordsPermissions, createPayload, columns, tableRow } = props;
  const createRoles = useGetApiRequests("roles", "POST")
  const editRoles = useGetApiRequests("editRole", "PUT")
  const [loader, setLoader] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)

  const requestCreateRoles = (values: Record<string, boolean | string>) => {
    setLoader(true);

    let transformedValues: any = {
      organisation: {
        organisationId: orgId,
      },
      name: values.roleName,
      description: values.description,
      rolePermissions: isEdit ? selectedRecordsPermissions : createPayload,
      isFullDataAccess: values.isFullDataAccess,
    };
    if (!isEdit) {
      createRoles(transformedValues).then((response: any) => {
        setLoader(false);
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(response?.data?.status?.message)
        setModalResponse('success')
        setTimeout(() => {
          handleClosePopUpModal()
        }, 1500)
        getRolesList();
      })
        .catch((err: any) => {
          setPopModal(true)
          setmodalButtonShow(false)
          setModalErrMessage(err?.response?.data?.status?.message)
          setModalResponse('error')
          setTimeout(() => {
            handleClosePopUpModal()
            setLoader(false);
          }, 1500)
          // triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
        });
    } else {
      const pathParams = {
        id: selectedRows.RoleId
      }
      transformedValues['roleId'] = selectedRows.RoleId
      editRoles(transformedValues, '', pathParams).then((response: any) => {
        // triggerNotification('success', '', response?.data?.status?.message, 'topRight');
        setLoader(false);
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(response?.data?.status?.message)
        setModalResponse('success')
        setTimeout(() => {
          handleClosePopUpModal()
        }, 1500)
        getRolesList();
      })
        .catch((err: any) => {
          // setLoader(false);
          // triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
          setPopModal(true)
          setmodalButtonShow(false)
          setModalErrMessage(err?.response?.data?.status?.message)
          setModalResponse('error')
          setTimeout(() => {
            handleClosePopUpModal()
            setLoader(false);
          }, 1500)
        });
    }

  };
  const handleClosePopUpModal = () => {
    setPopModal(false)
    handleCloseModal()
  }
  return (
    <div className='form-role mt-10'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={requestCreateRoles}
      >
        {({ values, handleChange, handleBlur, handleSubmit }: any) => (
          <Form onFinish={handleSubmit} className='form px-2'>
            <Form.Item className='form-item'>
              <FloatLabel label='Role Name*' name='roleName' value={values.roleName}>
                <Input
                  type="text"
                  name="roleName"
                  className="float-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.roleName}
                />
              </FloatLabel>
              <span className="error-message"> <ErrorMessage name="roleName" component="div" /></span>
            </Form.Item>
            <Form.Item className='form-item'>
              <FloatLabel label='Role Description*' name='description' value={values.roleName}>
                <Input
                  type="text"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="float-control"
                  value={values.description}
                />
              </FloatLabel>
              <span className="error-message"><ErrorMessage name="description" component="div" /></span>
            </Form.Item>
            <div className='permission form-item'>
              <div className="permission-title mb-4 flex justify-between items-center">
                <h6 className='inline-block'>Permissions</h6>
                <div className='flex'>
                  <p className='text text-secondaryText font-rubik mr-5'>All Employee View</p>
                  <Switch
                    checked={values.isFullDataAccess}
                    onChange={(checked) => handleChange({ target: { name: 'isFullDataAccess', value: checked } })}
                  />
                </div>
              </div>
              <Table columns={columns} dataSource={tableRow} pagination={false} />
            </div>

            <div className='w-full flex justify-end mt-4'>
              <Button type="primary" shape="round" htmlType="submit" >
                {isEdit ? 'Update Role' : 'Save'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

      {loader && <ModalLoader />}
    </div>
  );
}
export default AddEditRender;