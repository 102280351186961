import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { storeData, triggerNotification, useGetApiRequests } from '../../../../../../../common/CommonExport';
import { setProfileLoad } from '../../../../../../../redux/slices/updateProfileSlice';
import dayjs from 'dayjs';
import { appraisalLoad, eligibleForIncrement } from '../../../../../../../redux/slices/reviewFormSlice';

interface EmployeeAppraisalContextProps {
  reviewList: any[];
  modalOpen: boolean;
  isExitFormModalOpen: boolean;
  isView: boolean;
  isPending: boolean;
  employeeReviewId: any;
  employeeReviewType: string | null;
  employeeReviewFormActionType: string;
  handleView: (object: any, employeeReviewId: any) => void;
  handleCloseModal: () => void;
  handleModalClose: () => void;
  handelReviews: () => void;
  getReviews: () => void;
  reviewType: string | null,
  loader: boolean,
  size: string,
  employeeCtc: any
  salaryIncrement: SalaryIncrementState,
  SetSalaryIncrement: any
}

interface SalaryIncrementState {
  salaryRevisedDate: string;
  currentCtc: string;
  newCtcInRupees: string | number;
  newCtcInPercentage: string | number;
}

const EmployeeAppraisalContext = createContext<EmployeeAppraisalContextProps | undefined>(undefined);
const now = dayjs();
const currentDate = now.format('YYYY-MM-DD');
export const EmployeeAppraisalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const getCtcApi = useGetApiRequests('getEmployeeCtc', 'GET');
  const salaryRange = useGetApiRequests('getAppraisal', 'GET');
  const getQuestions = useGetApiRequests('getquestions', 'GET');

  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const designationId = useSelector((state: any) => state?.employeeTypeId?.id)
  const dispatch = useDispatch();

  const { id }: any = useParams();
  const size: string = 'large';

  const [modalOpen, setModalOpen] = useState(false);
  const [isExitFormModalOpen, setIsExitFormModalOpen] = useState<boolean>(false);
  const [reviewList, setReviewList] = useState<any>([])
  const [loader, setLoader] = useState(true);
  const [isView, setIsView] = useState(false)
  const [isPending, setIsPending] = useState(false);
  const [reviewType, setReviewType] = useState(null);
  const [employeeReviewId, setEmployeeReviewId] = useState(null);
  const [employeeReviewType, setEmployeeReviewType] = useState<string | null>(null)
  const [employeeCtc, setEmployeeCtc] = useState(null)
  const [employeeReviewFormActionType, setEmployeeReviewFormActionType] = useState<string>('create');
  const [salaryIncrement, SetSalaryIncrement] = useState<SalaryIncrementState>({
    salaryRevisedDate: currentDate,
    currentCtc: "",
    newCtcInRupees: 0,
    newCtcInPercentage: 0,
  })

  useEffect(() => {
    SetSalaryIncrement({
      salaryRevisedDate: currentDate,
      currentCtc: "",
      newCtcInRupees: 0,
      newCtcInPercentage: 0,
    })
  }, []);

  const getReviews = () => {
    const params: any = {
      id: id,
    };
    setLoader(true)
    salaryRange('', '', params)
      .then((response: any) => {
        dispatch(setProfileLoad());
        const apiResponse = response?.data?.data;

        if (employeeDetails?.status?.name === "Resigned") {
          const filterResponse = apiResponse.filter((item: any) => item.type === 'Exit Interview Form');
          setReviewList(filterResponse)
        } else {
          setReviewList(apiResponse)
        }
        getQuestionsApi();
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      });
  }
  const getQuestionsApi = () => {
    const params: any = {
      designationId: designationId, organisationId: orgId
    };
    const pathParams: any = {
      id: id
    }
    setLoader(true);
    getQuestions('', params, pathParams)
      .then((response: any) => {
        const apiResponse: any = response?.data?.data;
        setReviewType(apiResponse?.reviewType)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  }
  const getEmployeeCtc = () => {
    const params: any = {
      id: id, end: 'employeecurrentctc'
    };
    setLoader(true);
    getCtcApi('', '', params)
      .then((response: any) => {
        const apiResponse = response?.data?.data;
        setEmployeeCtc(apiResponse)
        SetSalaryIncrement((prev) => ({
          ...prev,
          currentCtc: apiResponse,
        }));
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  }
  const handelReviews = () => {
    storeData("reviewtype", '', true);
    storeData("reviewStatus", '', true);
    setEmployeeReviewFormActionType('create');
    setModalOpen(true)
    getEmployeeCtc()
    setIsView(false);
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  const handleView = (object: any) => {
    getEmployeeCtc()
    storeData("currentEmployeeId", object?.employeeId, true);
    if (object.type !== 'Exit Interview Form') {
      setEmployeeReviewFormActionType('edit');
      storeData("reviewtype", '', true);
      storeData("reviewStatus", object.reviewStatus, true);
      setEmployeeReviewType(object?.reviewStatus);
      dispatch(eligibleForIncrement(object?.eligibleForIncrement))
      if (object?.reviewStatus === 'Pending' || object?.reviewStatus === 'Draft') {
        setIsPending(true);
        setIsView(false);
      } else {
        setIsView(true);
        setIsPending(false);
      }
      // setIsView(true);
      setModalOpen(true);
      setEmployeeReviewId(object.employeeReviewId);
      dispatch(appraisalLoad(true));
    }
    else {
      setIsExitFormModalOpen(true)
    }
  }


  const handleModalClose = () => {
    setIsExitFormModalOpen(false);
  };
  return (
    <EmployeeAppraisalContext.Provider
      value={{
        reviewList,
        modalOpen,
        isExitFormModalOpen,
        isView,
        isPending,
        employeeReviewId,
        employeeReviewType,
        employeeReviewFormActionType,
        handleView,
        handleCloseModal,
        handleModalClose,
        handelReviews,
        getReviews,
        reviewType,
        loader,
        size,
        employeeCtc,
        salaryIncrement,
        SetSalaryIncrement
      }}
    >
      {children}
    </EmployeeAppraisalContext.Provider>
  );
};

export const useEmployeeAppraisalContext = () => {
  const context = useContext(EmployeeAppraisalContext);
  if (!context) {
    throw new Error('useEmployeeAppraisalContext must be used within EmployeeAppraisalProvider');
  }
  return context;
};
