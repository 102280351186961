import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  reviewFormSlice: any;
  itsIntern: boolean;
  concatenatedSalaryParticulars: any[];
  concatEmployerContribution: any[],
  employeeContribution: [],
  salaryPayloadDetails: {
    grossSalary: {},
    costToCompany: {},
    netAmount: {}
  }
  gross_SalaryYearly: number;
  gross_SalaryMonthly: number;
  ctc_SalaryYearly: number;
  salaryUpdatePayload: {},
  eligibleForIncrement: boolean;
  appraisalLoad: boolean;
  ctc_AmountYearly: number | string;
  net_AmountYearly: number | string;
  salaryParticularsForGrossAmount: {}

}

const initialState: AuthState = {
  reviewFormSlice: [],
  itsIntern: false,
  salaryPayloadDetails: {
    grossSalary: {},
    costToCompany: {},
    netAmount: {}
  },
  gross_SalaryYearly: 0,
  gross_SalaryMonthly: 0,
  ctc_SalaryYearly: 0,
  ctc_AmountYearly: 0,
  net_AmountYearly: 0,
  eligibleForIncrement: false,
  appraisalLoad: false,
  salaryUpdatePayload: {},
  concatenatedSalaryParticulars: [],
  concatEmployerContribution: [],
  employeeContribution: [],
  salaryParticularsForGrossAmount: {}

};

const reviewDetailsSlice = createSlice({
  name: 'reviewForm',
  initialState,
  reducers: {
    setReviewDetails: (state, action: PayloadAction<string>) => {
      state.reviewFormSlice = action.payload;
    },
    setItsIntern: (state, action: PayloadAction<boolean>) => {
      state.itsIntern = action.payload;
    },
    setSalaryPayloadDetails: (state, action: PayloadAction<any>) => {
      state.salaryPayloadDetails = action.payload;
    },
    grossSalaryMonthlyValue: (state, action: PayloadAction<any>) => {
      state.gross_SalaryMonthly = action.payload;
    },
    grossSalaryYearlyValue: (state, action: PayloadAction<any>) => {
      state.gross_SalaryYearly = action.payload;
    },
    ctcSalaryYearlyValue: (state, action: PayloadAction<any>) => {
      state.ctc_SalaryYearly = action.payload;
    },
    setSalaryUpdatePayload: (state, action: PayloadAction<any>) => {
      state.salaryUpdatePayload = action.payload;
    },
    eligibleForIncrement: (state, action: PayloadAction<boolean>) => {
      state.eligibleForIncrement = action.payload;
    },
    appraisalLoad: (state, action: PayloadAction<boolean>) => {
      state.appraisalLoad = action.payload;
    },
    ctcAmountYearlyValue: (state, action: PayloadAction<any>) => {
      state.ctc_AmountYearly = action.payload;
    },
    netAmountYearlyValue: (state, action: PayloadAction<any>) => {
      state.net_AmountYearly = action.payload;
    },
    clearReviewDetails: state => {
      state.reviewFormSlice = [];
    },
    setConCatenatedSalaryParticulars: (state, action: PayloadAction<any>) => {
      state.concatenatedSalaryParticulars = action.payload;
    },
    setPayloadConcatEmployerContribution: (state, action: PayloadAction<any>) => {
      state.concatEmployerContribution = action.payload;
    },
    setPayloadEmployeeContribution: (state, action: PayloadAction<any>) => {
      state.employeeContribution = action.payload;
    },
    setSalaryParticularsForGross: (state, action: PayloadAction<any>) => {
      state.salaryParticularsForGrossAmount = action.payload;
    },
  },
});

export const { setConCatenatedSalaryParticulars, setReviewDetails, clearReviewDetails, setItsIntern, setSalaryPayloadDetails, grossSalaryMonthlyValue, grossSalaryYearlyValue, setSalaryUpdatePayload, ctcSalaryYearlyValue, eligibleForIncrement, appraisalLoad, ctcAmountYearlyValue, netAmountYearlyValue, setPayloadConcatEmployerContribution, setPayloadEmployeeContribution, setSalaryParticularsForGross } = reviewDetailsSlice.actions;
export default reviewDetailsSlice.reducer;
