import React, { useEffect, useState } from "react";
import {
  addEditEmployeeContribution,
  addEditEmployerContribution,
  addEditFixedEmployer,
  addEditFixedSalary,
  addEditSalaryParitulars,
} from "../employee-profile/layout/salary-component/AddEditCalculations";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ErrorMessages, triggerNotification, useGetApiRequests } from "../../../../common/CommonExport";
import { createRequest, createRevisedRequest } from "../employee-profile/layout/salary-component/AddEditSalaryApiCalls";
import { useEmployeeAppraisalContext } from "../employee-profile/layout/Appraisal/context/EmployeeAppraisalContext";
import { useDispatch } from "react-redux";
import {
  ctcAmountYearlyValue,
  grossSalaryMonthlyValue,
  grossSalaryYearlyValue,
  netAmountYearlyValue,
  setConCatenatedSalaryParticulars,
  setPayloadConcatEmployerContribution,
  setPayloadEmployeeContribution,
  setSalaryUpdatePayload,
} from "../../../../redux/slices/reviewFormSlice";

export default function useSalaryDetails() {
  const { gross_SalaryMonthly, gross_SalaryYearly, salaryUpdatePayload } = useSelector((state: any) => state.reviewDetailsSlice);
  const newCtcInRupees = useSelector((state: any) => state.reviewDetailsSlice);
  const { search } = useLocation();
  const isEdit = false;
  const isRevised = true;
  const empTypeId: any = new URLSearchParams(search).get("employeeTypeId");
  const determineRequestMethod = () => (isEdit ? "PUT" : "POST");
  const { id } = useParams();
  const { salaryIncrement } = useEmployeeAppraisalContext();
  const dispatch = useDispatch();

  const [salaryParticulars, setSalaryParticulars] = useState<any>([]);
  const [employerContribution, setEmployerContribution] = useState<any>([]);
  const [employeeContribution, setEmployeeContribution] = useState<any>([]);
  const [fixedSalaryParticulars, setFixedSalaryParticulars] = useState<any>([]);
  const [fixedEmployerContribution, setFixedEmployerContribution] = useState<any>([]);
  const [concatSalaryParticulars, setConcatSalaryParticulars] = useState<any>([]);
  const [concatEmployerContribution, setConcatEmployerContribution] = useState<any>([]);
  const [grossSalaryMonthly, setGrossSalaryMonthly] = useState<any>(0);
  const [grossSalaryYearly, setGrossSalaryYearly] = useState<any>(0);
  const [ctcAmountMonthly, setCtcAmountMonthly] = useState<number>(0);
  const [ctcAmountYearly, setCtcAmountYearly] = useState<number>(0);
  const [netAmountMonthly, setNetAmountMonthly] = useState<number>(0);
  const [netAmountAnnual, setNetAmountAnnual] = useState<number>(0);
  const [loader, setLoader] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const internLables = useGetApiRequests("employeeType", "GET");
  const regularLables = useGetApiRequests("salaryLabelList", "GET");
  const editSalaryApi = useGetApiRequests("updateEmployeeSalary", "PUT");
  const checkValueGreaterThanZero = (value: any) => {
    if (Number(value) > 0) {
      return Number(value);
    }
    return 0;
  };
  const indianCurrencyChanges = (numberValue: any) => {
    return Number(numberValue).toLocaleString("en-IN");
  };
  useEffect(() => {
    if (empTypeId == "2") {
      getInternSideLables();
    } else if (empTypeId === "1") {
      getRegularSideLables();
    }
  }, []);

  const getInternSideLables = () => {
    const params: any = {
      id: empTypeId,
    };
    internLables("", "", params)
      .then((response: any) => {
        const emoyeeTypeSalaryComponentMap = response?.data?.data?.emoyeeTypeSalaryComponentMap;
        const mappedSalaryComponent = emoyeeTypeSalaryComponentMap.map((item: any) => item.salaryComponent);
        const salaryParticularsDataIntern = addEditSalaryParitulars(mappedSalaryComponent);
        const employerContributionDataIntern = addEditEmployerContribution(mappedSalaryComponent);
        const tempFixedSalaryParticularsIntern = addEditFixedSalary(mappedSalaryComponent);
        const tempFixedEmployerContributionIntern = addEditFixedEmployer(mappedSalaryComponent);
        const tempEmployeeContributionIntern = addEditEmployeeContribution(mappedSalaryComponent);
        setSalaryParticulars(salaryParticularsDataIntern);
        setFixedSalaryParticulars(tempFixedSalaryParticularsIntern);
        const concatenatedSalaryArray = [...salaryParticularsDataIntern, ...tempFixedSalaryParticularsIntern];

        setConcatSalaryParticulars(concatenatedSalaryArray);

        setEmployerContribution(employerContributionDataIntern);
        setFixedEmployerContribution(tempFixedEmployerContributionIntern);

        const concatenatedEmployer = [...employerContributionDataIntern, ...tempFixedEmployerContributionIntern];
        setConcatEmployerContribution(concatenatedEmployer);

        setEmployeeContribution(tempEmployeeContributionIntern);
        setLoader(false);
        dispatch(
          setSalaryUpdatePayload({
            ...salaryUpdatePayload,
            concatEmployerContribution: concatenatedEmployer,
            concatSalaryParticulars: concatenatedSalaryArray,
            employeeContribution: tempEmployeeContributionIntern,
            tempFixedEmployerContributionIntern,
            fixedEmployerContribution: tempFixedEmployerContributionIntern,
            employerContribution: employerContributionDataIntern,
            salaryParticulars: salaryParticularsDataIntern,
            fixedSalaryParticulars: tempFixedSalaryParticularsIntern,
          }),
        );
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification("error", "", ErrorMessages.somethingWentWrong, "topRight");
      });
  };
  const getRegularSideLables = () => {
    const params: any = {
      organisationId: orgId,
    };
    setLoader(true);
    regularLables("", params)
      .then((response: any) => {
        const apiResponse = response?.data?.data;
        const salaryParticularsData = addEditSalaryParitulars(apiResponse);
        const employerContributionData = addEditEmployerContribution(apiResponse);
        const tempFixedSalaryParticulars = addEditFixedSalary(apiResponse);
        const tempFixedEmployerContribution = addEditFixedEmployer(apiResponse);
        const tempEmployeeContribution = addEditEmployeeContribution(apiResponse);

        setSalaryParticulars(salaryParticularsData);
        setFixedSalaryParticulars(tempFixedSalaryParticulars);
        const concatenatedSalaryArray = [...salaryParticularsData, ...tempFixedSalaryParticulars];
        setConcatSalaryParticulars(concatenatedSalaryArray);

        setEmployerContribution(employerContributionData);
        setFixedEmployerContribution(tempFixedEmployerContribution);

        const concatenatedEmployer = [...employerContributionData, ...tempFixedEmployerContribution];
        setConcatEmployerContribution(concatenatedEmployer);

        setEmployeeContribution(tempEmployeeContribution);
        setLoader(false);
        dispatch(
          setSalaryUpdatePayload({
            ...salaryUpdatePayload,
            concatEmployerContribution: concatenatedEmployer,
            concatSalaryParticulars: concatenatedSalaryArray,
            employeeContribution: tempEmployeeContribution,
            fixedEmployerContribution: tempFixedEmployerContribution,
            employerContribution: employerContributionData,
            salaryParticulars: salaryParticularsData,
            fixedSalaryParticulars: tempFixedSalaryParticulars,
          }),
        );
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification("error", "", ErrorMessages.somethingWentWrong, "topRight");
      });
  };

  function calculateSalaryParticulars(grossMonth: any, grossYear?: any) {
    if (orgId === 563) {
      calculateSalaryParticularsForKrissco(grossMonth, grossYear);
    } else {
      calculateSalaryParticularsForCNW(grossMonth, grossYear);
    }
  }
  const calculateSalaryParticularsForKrissco = (grossMonth: any, grossYear?: any) => {
    let tempFixedSalaryParticularsTotal: number = 0;
    let tempDAMonthly: number = 0;
    let tempBasicMonthly: number = 0;

    const updatedSalaryParticulars = salaryParticulars.map((item: any) => {
      item.yearly = checkValueGreaterThanZero(item.monthly) * 12;
      const monthValue = ((grossMonth - tempFixedSalaryParticularsTotal) * item.percent * 2) / (100 * 3);
      item.monthly = checkValueGreaterThanZero(monthValue);
      tempBasicMonthly = item.monthly;
      item.yearly = checkValueGreaterThanZero(item.monthly) * 12;
      return item;
    });

    setSalaryParticulars(updatedSalaryParticulars);

    setSalaryUpdatePayload({
      ...salaryUpdatePayload,
      salaryParticulars: updatedSalaryParticulars,
    });

    fixedSalaryParticulars.forEach((item: any) => {
      if (item.name === "DA") {
        const monthValue = tempBasicMonthly / 2;
        item.monthly = checkValueGreaterThanZero(monthValue);
        tempDAMonthly = item.monthly;
        item.yearly = checkValueGreaterThanZero(item.monthly) * 12;
      }
    });

    fixedSalaryParticulars.forEach((item: any) => {
      if (item.name === "HRA") {
        const monthValue = grossMonth - tempBasicMonthly - tempDAMonthly;
        item.monthly = checkValueGreaterThanZero(monthValue);
        item.yearly = checkValueGreaterThanZero(item.monthly) * 12;
      }
    });

    calculateCTC(grossMonth, grossYear);
  };
  const calculateSalaryParticularsForCNW = (grossMonth: any, grossYear?: any) => {
    let updatedSalaryParticulars;
    let medicalAllowanceAmount = 0;
    let conveyanceAmount = 0;
    const salaryParticulars = salaryUpdatePayload?.salaryParticulars || [];
    const fixedSalaryParticulars = salaryUpdatePayload?.fixedSalaryParticulars || [];
    salaryParticulars.forEach((item: any) => {
      switch (item.name) {
        case "Medical Allowance":
          medicalAllowanceAmount = item.amount;
          break;
        case "Conveyance":
          conveyanceAmount = item.amount;
          break;
        default:
          break;
      }
    });
    //salaryUpdatePayload
    let tempFixedSalaryParticularsTotal: number = 0;
    let basicSalaryMonthly: number = 0;
    fixedSalaryParticulars.forEach((item: any) => {
      if (item.name === "LTA") {
        if (Number(grossMonth) > 36803) {
          tempFixedSalaryParticularsTotal = tempFixedSalaryParticularsTotal + Number(item.monthly);
        }
      } else {
        tempFixedSalaryParticularsTotal = tempFixedSalaryParticularsTotal + Number(item.monthly);
      }
    });

    updatedSalaryParticulars = salaryParticulars.map((item: any) => {
      let monthValue = ((Number(grossMonth) - tempFixedSalaryParticularsTotal) * Number(item.percent)) / 100;

      if (item.name === "Basic Salary") {
        basicSalaryMonthly = Number(monthValue);
      }
      return {
        ...item,
        monthly: checkValueGreaterThanZero(monthValue),
        yearly: checkValueGreaterThanZero(monthValue) * 12,
      };
    });
    // setSalaryParticulars(updatedSalaryParticulars);
    const concatenatedSalaryArray = [...updatedSalaryParticulars, ...fixedSalaryParticulars];

    setConcatSalaryParticulars(concatenatedSalaryArray);
    dispatch(setConCatenatedSalaryParticulars(concatenatedSalaryArray));
    dispatch(
      setSalaryUpdatePayload({
        ...salaryUpdatePayload,
        concatSalaryParticulars: concatenatedSalaryArray,
      }),
    );
    const storeFixedEmployerContribution = salaryUpdatePayload?.fixedEmployerContribution ? salaryUpdatePayload.fixedEmployerContribution : [];
    let updatedContribution = storeFixedEmployerContribution;
    updatedContribution = storeFixedEmployerContribution.map((item: any) => {
      if (item.name === "Gratuity") {
        return {
          ...item,
          yearly: (checkValueGreaterThanZero(basicSalaryMonthly) * 15) / 26,
        };
      }
      return item;
    });

    console.log(updatedContribution);
    dispatch(
      setSalaryUpdatePayload({
        ...salaryUpdatePayload,
        fixedEmployerContribution: updatedContribution,
      }),
    );
    calculateCTC(grossMonth, grossYear, updatedContribution);
  };

  const calculateCTC = (grossMonth: any, grossYear: any, updatedContribution: any = []) => {
    try {
      const storeEmployerContribution = salaryUpdatePayload?.employerContribution ? salaryUpdatePayload?.employerContribution : [];
      const tempEmployerContribution = [...storeEmployerContribution];

      const fixedEmployerContributionTemp = updatedContribution.map((item: any) => {
        if (item?.name === "ESI" && grossMonth <= 21000) {
          try {
            return {
              ...item,
              monthly: (checkValueGreaterThanZero(grossMonth) * 3.25) / 100,
              yearly: (checkValueGreaterThanZero(grossMonth) * 3.25 * 12) / 100,
            };
          } catch (e) {
            console.error("Error calculating ESI:", e);
          }
        }
        return item;
      });

      let tempMonthlyCTC: number = grossMonth;

      // Calculate the total contribution from `tempEmployerContribution`
      if (tempEmployerContribution.length > 0) {
        tempEmployerContribution.forEach((item: any) => {
          tempMonthlyCTC += Number(item?.monthly);
        });
      }

      fixedEmployerContributionTemp.forEach((item: any) => {
        tempMonthlyCTC += Number(item?.monthly);
      });

      setCtcAmountMonthly(checkValueGreaterThanZero(tempMonthlyCTC));

      let tempYearlyCTC: number = tempMonthlyCTC * 12;

      fixedEmployerContributionTemp.length > 0 &&
        fixedEmployerContributionTemp.forEach((item: any) => {
          if (item.monthly === 0) {
            tempYearlyCTC += item.yearly;
          }
        });

      setCtcAmountYearly(tempYearlyCTC);
      dispatch(ctcAmountYearlyValue(tempYearlyCTC));
      const employerConCat = [...tempEmployerContribution, ...fixedEmployerContributionTemp];
      dispatch(setPayloadConcatEmployerContribution(employerConCat));
      setConcatEmployerContribution(employerConCat);
      calculateEmployeeContribution(grossMonth, grossYear);
      dispatch(
        setSalaryUpdatePayload({
          ...salaryUpdatePayload,
          concatEmployerContribution: employerConCat,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const calculateEmployeeContribution = (grossMonth: any, grossYear: any) => {
    let tempEmployeeContributionMonthlyTotal: number = 0;
    let tempEmployeeContributionYearlyTotal: number = 0;
    const tempEmployeeContribution = salaryUpdatePayload.employeeContribution ? salaryUpdatePayload.employeeContribution : [];
    tempEmployeeContribution.map((item: any) => {
      if (item.name === "ESI") {
        if (grossMonth <= 21000) {
          const tempMonthly = (grossMonth * 0.75) / 100;
          return {
            ...item,
            monthly: checkValueGreaterThanZero(tempMonthly),
            yearly: checkValueGreaterThanZero(tempMonthly * 12),
          };
        } else {
          return {
            ...item,
            monthly: 0,
            yearly: 0,
          };
        }
      }
      return { ...item };
    });

    tempEmployeeContribution.forEach((item: any) => {
      tempEmployeeContributionMonthlyTotal += Number(item.monthly);
      tempEmployeeContributionYearlyTotal += Number(item.yearly);
    });

    setEmployeeContribution(tempEmployeeContribution);
    dispatch(setPayloadEmployeeContribution(tempEmployeeContribution));
    let tempNetAmountMonthly = grossMonth - tempEmployeeContributionMonthlyTotal;
    let tempNetAmountAnnual = grossYear - tempEmployeeContributionYearlyTotal;
    setNetAmountMonthly(checkValueGreaterThanZero(tempNetAmountMonthly));
    setNetAmountAnnual(checkValueGreaterThanZero(tempNetAmountAnnual));
    dispatch(netAmountYearlyValue(checkValueGreaterThanZero(tempNetAmountAnnual)));
    dispatch(
      setSalaryUpdatePayload({
        ...salaryUpdatePayload,
        employeeContribution: tempEmployeeContribution,
      }),
    );
  };

  const changeGross = (value: any, type: string) => {
    const inputValue = value;
    const numericValue = inputValue === "" ? 0 : parseInt(inputValue);
    let grossMonth: number = 0;
    let grossYear: number = 0;
    grossMonth = numericValue / 12;
    grossYear = numericValue;
    setGrossSalaryYearly(grossYear);
    setGrossSalaryMonthly(grossMonth);
    dispatch(grossSalaryMonthlyValue(grossMonth));
    dispatch(grossSalaryYearlyValue(grossYear));
    calculateSalaryParticulars(grossMonth, grossYear);
  };

  return { concatSalaryParticulars, grossSalaryYearly, grossSalaryMonthly, changeGross, concatEmployerContribution };
}
