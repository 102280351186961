import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
interface Salary {
  newGrossAmount: number;
}
export default function SalaryTable({ newGrossAmount }: Salary) {
  const { ctc_AmountYearly, net_AmountYearly, gross_SalaryYearly } = useSelector((state: any) => state.reviewDetailsSlice);

  const [netValue, setNetValue] = useState(0);
  const [grossValue, setGrossValue] = useState(0);
  const [ctcValue, setCtcValue] = useState(0);

  useEffect(() => {
    setNetValue(net_AmountYearly)
    setGrossValue(gross_SalaryYearly)
    setCtcValue(ctc_AmountYearly)
  }, [
    ctc_AmountYearly, net_AmountYearly, gross_SalaryYearly
  ])
  return (
    <div className="overflow-x-auto bg-white rounded-xl ">
      <table className="min-w-full text-left text-sm whitespace-nowrap ">

        <thead className=" tracking-wider border-b dark:border-neutral-300 rounded-md">
          <tr className='salary-revision-heading'>
            <th scope="col" className="px-3 py-4 ">
              Salary Types
            </th>
            <th scope="col" className="px-6 py-4">
              Monthly
            </th>

            <th scope="col" className="px-6 py-4">
              Annual
            </th>
          </tr>
        </thead>

        <tbody className='salary-revision-table'>
          <tr className="border-b dark:border-neutral-300">
            <th scope="row" className="px-3 py-5">
              Cost To Company
            </th>
            <td className="px-6 py-4">₹ {(ctcValue / 12)?.toFixed(2)}</td>
            <td className="px-6 py-4">₹ {ctcValue?.toFixed(2)}  </td>
          </tr>
          <tr className="border-b dark:border-neutral-300">
            <th scope="row" className="px-3 py-5">
              Gross Salary
            </th>
            <td className="px-6 py-4">₹ {(grossValue / 12)?.toFixed(2)}</td>
            <td className="px-6 py-4">₹ {grossValue?.toFixed(2)}  </td>
          </tr>

          <tr className="border-b dark:border-neutral-300">
            <th scope="row" className=" px-3 py-5">
              Net Salary
            </th>
            <td className="px-6 py-4">₹ {(netValue / 12)?.toFixed(2)}</td>
            <td className="px-6 py-4">₹ {netValue?.toFixed(2)}  </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
