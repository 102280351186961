import React, { useEffect, useState } from 'react'
import './employeeProfile.scss'
import { Button, Collapse, Flex, Tag, Tooltip } from 'antd';
import allAboardLogo from '../../../../assets/images/profile.jpg'
import CompanyDetails from './layout/company-details/CompanyDetails';
import PersonalDetails from './layout/personal-details/PersonalDetails';
import EditEmployeeDetailsModal from './layout/edit-employee/EditEmployeeDetailsModal';
import { useParams } from 'react-router-dom';
import { useDispatch, useGetApiRequests } from '../../../../common/CommonExport';
import { setEmpId } from '../../../../redux/slices/employeeTypeSlice';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Loader/Loader';
import EmployeeProfileImage from './EmployeeProfileImage';
import SendMail from './layout/company-details/SendMail';
import { setDeprovision } from '../../../../redux/slices/updateProfileSlice';
import { setParticularEmployeeData } from '../../../../redux/slices/empEventsSlice';
import AppraisalStatus from './AppraisalStatus';
import appraisalImg from './../../../../assets/images/new-dashboard/appraisal.svg';

const { Panel } = Collapse;

interface EmployeeProfileProps {
  layoutFull: (el: string) => void;
}
export default function EmployeeProfile({ layoutFull }: EmployeeProfileProps) {
  const imageBaseUrl = process.env.REACT_APP_IMAGE_VIEW;
  const reloadData = useSelector((state: any) => state?.profileReload?.profileLoad);
  const deprovisionReload = useSelector((state: any) => state?.profileReload?.deprovisionLoad);

  const [modalOpen, setModalOpen] = useState<any>(false);
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const employeeDetails = useGetApiRequests('employeeDetails', 'GET');
  const [imageOpen, setImageOpen] = useState<boolean>(false)
  const [employeeInformation, setEmployeeInformation] = useState<any>()
  const [sendMail, setSendMail] = useState<boolean>(false)
  const [sendMailType, setSendMailType] = useState<boolean>(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  useEffect(() => {
    employeeDetails('', {}, { id })
      .then((res: any) => {
        setLoader(false)
        setEmployeeInformation(res?.data?.data)
        if (res?.data?.data?.isDeprovisionStarted === true) {
          dispatch(setDeprovision(true));
        } else {
          dispatch(setDeprovision(false));
        }
        dispatch(setParticularEmployeeData(res?.data?.data))
        setIsEmailSent(res?.data?.data?.isBasicFormSent)
        dispatch(setParticularEmployeeData(res?.data?.data))
        dispatch(setEmpId(res?.data?.data?.designation?.designationId));
      })
      .catch((err: any) => {
        console.error(err)
        setLoader(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData, deprovisionReload])

  const handleImageOpen = () => {
    setImageOpen(true)
  }
  const handelSendMail = () => {
    setSendMail(true)
    setSendMailType(true)
  }
  const handelClose = () => {
    setSendMail(false)

  }
  const basicnfoEmail = () => {
    setSendMail(true)
    setSendMailType(false)
  }

  return (
    <div className="employee-details__card">
      {loader && <Loader />}
      {employeeInformation && (
        <>
          <div className="img-data">
            <div>
              <img onClick={() => handleImageOpen()} alt='logo' src={employeeInformation?.profileUrl ? `${imageBaseUrl}${employeeInformation?.profileUrl}` : allAboardLogo} className='employee_image' />
            </div>
            <Tooltip placement="top" title={'Edit'}>
              <span className="material-symbols-outlined edit-profile" onClick={() => setModalOpen(true)}>edit</span>
            </Tooltip>
            <button className="personal-card-button flex justify-center items-center" onClick={() => { layoutFull("") }} >
              <span className='material-symbols-outlined'>chevron_left</span>
            </button>

            <div className="employee-job">
              <p className="employee-name">{`${employeeInformation?.firstName} ${employeeInformation?.lastName}`}</p>

              <Flex gap="4px 0" className='mt-2'>
                <Tag color="magenta" bordered={false}>{employeeInformation?.designation?.name}</Tag>
                <Tag color="magenta" bordered={false}>{employeeInformation?.department?.name}</Tag>
              </Flex>
              <Flex gap="4px 0" className='mt-2'>

                {
                  employeeInformation?.reviewStatus && <Tag color="magenta" bordered={false}>
                    {employeeInformation?.reviewStatus.toLowerCase() === 'pending' && (
                      <div className='flex items-center'>
                        <img src={appraisalImg} alt='Appraisal images' className='appraisalImg' />
                        <span>  Appraisal Pending </span>
                      </div>
                    )}

                  </Tag>
                }

                <div className="text-sm  text-[#d34a7c]">

                </div>
              </Flex>


              <div className={`flex justify-between pb-4 mt-6`}>
                {!isEmailSent && <Button type="primary" shape="round" onClick={() => basicnfoEmail()} className='mr-4 xl:mr-2 3xl:mr-4 employee-proposal-btn'>
                  Send Basic Information
                </Button>}

                <Button disabled={employeeInformation?.isDeprovisionStarted === true} type="primary" shape="round" onClick={() => handelSendMail()} className='employee-proposal-btn' >
                  Resign
                </Button>

              </div>


            </div>
          </div>

          <div className="employee-details__personal-main">
            <div className="employee-details__personal">
              <Collapse defaultActiveKey={['1']} bordered={false} >
                <Panel header="Company Details" key="1">
                  <CompanyDetails employeeInformation={employeeInformation} />
                </Panel>
                <Panel header="Personal Details" key="2">
                  <PersonalDetails employeeInformation={employeeInformation} />
                </Panel>
              </Collapse>
              {sendMail && <SendMail open={sendMail} handelClose={handelClose} sendMailType={sendMailType} />}
              {modalOpen && <EditEmployeeDetailsModal open={modalOpen} setModalOpen={setModalOpen} />}
              {imageOpen && <EmployeeProfileImage open={imageOpen} setImageOpen={setImageOpen} imageURL={employeeInformation?.profileUrl ? `${imageBaseUrl}${employeeInformation?.profileUrl}` : allAboardLogo} />}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

