import React, { useEffect, useState } from 'react';
import DynamicTable from '../../../components/CommonTable/DynamicTable';
import { ErrorMessages, debounce, triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import UserHeader from '../layout/UserHeader';
import { Modal, Pagination, Tooltip } from 'antd';
import AddEditRoles from '../roles/AddEditRoles';
import { useSelector } from 'react-redux';
import DeleteConfirmationModal from '../../Organization/DeleteConfirmationModal';
import { handleDeleteDesignation } from '../../Organization/OrganisationHelper';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';

const UserTable = () => {
    const userList = useGetApiRequests('role', 'GET');
    const singleDelete = useGetApiRequests('roleDelete', 'DELETE');
    const orgId = useSelector((state: any) => state?.organisationId?.id);
    const [selectedRows, setSelectedRows]: any = useState([]);
    const [elementData, setElementData] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [deleteMessage, setDeleteMessage] = useState('');
    const [isSingleDelete, setIsSingleDelete] = useState(false);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [editRecord, setEditRecord] = useState<any>();
    const [count, setCount] = useState<number>()
    const [loader, setLoader] = useState(false);
    const [modalerrMessage, setModalErrMessage] = useState('')
    const [popModal, setPopModal] = useState(false)
    const [modalResponse, setModalResponse] = useState('')
    const [modalButtonShow, setmodalButtonShow] = useState(true)

    const onShowSizeChange = (current: number, newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };
    const onSearch = debounce((searchTerm: string) => {
        getRolesList(searchTerm);
    }, 500);
    useEffect(() => {
        getRolesList()
    }, [currentPage, pageSize])
    const getRolesList = (searchTerm = '') => {
        const start: number = (currentPage - 1 === 0) ? 0 : currentPage - 1;
        const param: any = {
            start: start,
            limit: pageSize,
            organisationId: orgId,
        }
        if (searchTerm !== '') {
            param.search = searchTerm
        }
        setLoader(true)
        userList("", param)
            .then((response: any) => {
                const data = response?.data?.data !== null ? response?.data?.data : []
                if (data) {
                    const tempUserList: any = data.map((item: any) => {
                        let obj = {
                            'Role': item.name,
                            'Description': item.description,
                            'RoleId': item.roleId,
                            'RolePermissions': item.rolePermissions,
                            'Action': 'Edit',
                            'isFullDataAccess': item.isFullDataAccess,
                            'key': item?.roleId,
                        };
                        return obj;
                    });
                    setCount(response?.data?.totalResults);
                    setElementData(tempUserList);
                    setTimeout(() => {
                        setLoader(false)
                    }, 1000)
                }
            })
            .catch((err: any) => {
                setPopModal(true)
                setmodalButtonShow(false)
                setModalErrMessage(ErrorMessages.somethingWentWrong)
                setModalResponse('error')
                setTimeout(() => {
                    handleClosePopUpModal()
                    setLoader(false);
                }, 1500)
            });
    }
    const columns = [
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'Role',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
        },
        {
            title: 'Action',
            dataIndex: 'deleteButton, editButton',
            render: (_: any, record: any) => (
                <div className='flex justify-end'>
                    <Tooltip placement="top" title="Edit">
                        <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => editRoles(record)} >
                            edit
                        </span>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                        <span className="material-symbols-outlined cursor-pointer hover:text-primary" onClick={() => roleSingleDelete(record)}>
                            delete
                        </span>

                    </Tooltip>
                </div>
            ),
        }
    ];
    const handleClosePopUpModal = () => {
        setPopModal(false)
        handleCloseModal()
    }
    const handleOpenModal = () => {
        setIsEdit(false)
        setOpen(true)
    };
    const handleCloseModal = () => {
        setOpen(false)
    };
    const editRoles = (record: any) => {
        setIsEdit(true)
        setOpen(true)
        setEditRecord(record);
    };
    const handleSelectedRows = (selected: any) => {
        setSelectedRows(selected);
        setIsSingleDelete(false);
        setDeleteMessage(`Are you sure you want to delete these ${selected.length} roles?`);
    }
    const roleSingleDelete = (record: any) => {
        setDeletePopUp(true);
        setIsSingleDelete(true);
        const recordArray: any = [];
        recordArray.push(record);
        setSelectedRows(recordArray);
        setDeleteMessage(`Are you sure you want to delete ${record.Role} ?`);
    };
    const handleSingleSelectDelete = async () => {
        const deleteArray: any = [];
        setDeletePopUp(false);
        const roleId = { 'roleId': selectedRows[0].key };
        deleteArray.push(roleId);
        handleDeleteDesignation(singleDelete, deleteArray, getRolesList, '');
        setSelectedRows([])

    };
    const handleDeleteSelected = async () => {
        const deleteArray: any = [];
        selectedRows.map((item: any) => {
            const id = {
                'roleId': item
            }
            deleteArray.push(id);
        })
        handleDeleteDesignation(singleDelete, deleteArray, getRolesList, '');
        setSelectedRows([])

    };
    const handleCloseDelete = () => setDeletePopUp(false);

    return (
        <div className="user-table">
            <UserHeader types={"designations"} handleOpenModal={handleOpenModal} onSearch={onSearch} setDeletePopUp={setDeletePopUp} selectedRows={selectedRows} isSingleDelete={isSingleDelete} />
            <DynamicTable columns={columns} data={elementData} handleSelectedRows={handleSelectedRows} loading={loader} />
            <div className='pagination'>
                <Pagination
                    className='float-right flex flex-row p-6'
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    current={currentPage}
                    total={count}
                    pageSize={pageSize}
                    onChange={(page) => setCurrentPage(page)}
                />
            </div>
            {open && (<Modal centered open={open} onCancel={handleCloseModal} className='modal-role' title={isEdit ? 'Update Role' : 'Create Role'} maskClosable={false}>
                <AddEditRoles selectedRows={editRecord} isEdit={isEdit} getRolesList={getRolesList} handleCloseModal={handleCloseModal} />
            </Modal>)}
            {deletePopUp && <DeleteConfirmationModal open={deletePopUp} handleSingleSelectDelete={handleSingleSelectDelete} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} selectedRows={selectedRows} type='designation' isSingleDelete={isSingleDelete} deleteMessage={deleteMessage} />}
            {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
        </div>
    );
};
export default UserTable;
