import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import noRecordFound from "../../../../../../assets/images/empty-folder.png"
import { useSelector } from 'react-redux';
import ExitFormModal from './exit-form/modal/ExitFormModal';
import AppraisalReviewForm from './AppraisalReviewForm';
import EmployeeAppraisalCard from './components/EmployeeAppraisalCard';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';
import { useEmployeeAppraisalContext } from './context/EmployeeAppraisalContext';

const EmployeeAppraisal = (activeTab: any) => {
  const designationId = useSelector((state: any) => state?.employeeTypeId?.id)
  const {
    reviewList,
    loader,
    modalOpen,
    isExitFormModalOpen,
    handelReviews,
    handleView,
    reviewType,
    getReviews,
    handleModalClose
  } = useEmployeeAppraisalContext();

  useEffect(() => {
    designationId && getReviews();
  }, [designationId, activeTab]);

  return (
    <div className='employee-appraisal'>
      <div className="grid grid-cols-1 ">
        <div className='flex justify-end'>
          <Tooltip placement="bottom" title="Create Appraisal">
            <Button type="primary" shape="circle" size={"large"} onClick={() => handelReviews()}>
              <span className="material-symbols-outlined">
                add
              </span>
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="employee-appraisal-container overflow-y-auto">

        {reviewList && reviewList.length > 0 ? reviewList.map((item: any) => (
          <EmployeeAppraisalCard
            key={item?.employeeReviewId}
            item={item}
            handleView={handleView}
            reviewType={reviewType}
          />
        )) : <div className="no-record appraisal">
            <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
          </p>
        </div>}

      </div>
      {
        isExitFormModalOpen && <ExitFormModal open={isExitFormModalOpen} handleClose={handleModalClose} />
      }

      {modalOpen && <AppraisalReviewForm />}
      {loader && <ModalLoader />}
    </div >
  )
}

export default EmployeeAppraisal
