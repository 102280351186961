import React, { useEffect, useState } from 'react'
import { Modal, Table, Tooltip } from 'antd';
import { ErrorMessages, triggerNotification, useGetApiRequests, useSelector } from '../../common/CommonExport';
import DeleteConfirmationModal from '../Organization/DeleteConfirmationModal';
import { handleDeleteDesignation } from '../Organization/OrganisationHelper';
import AddChapterContent from './assessment/chapter/AddChapterContent';
import Loader from '../../components/Loader/Loader';
import PopupModal from '../employee/employee-details/employee-profile/modal/PopupModal';

function ChapterandCertificateTable({ addChapterModal, setAddChapterModal, chapterSearch }: any) {
  const getChapterList = useGetApiRequests('getChapterList', 'POST');
  const deleteChapterApi = useGetApiRequests('deleteChapter', 'DELETE');
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const [loader, setLoader] = useState(false);
  const [chapterList, setChapterList] = useState([]);
  const [modalOpen, setModalOpen] = useState<boolean>();
  const [editRecord, setEditRecord] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [isSingleDelete, setIsSingleDelete] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)


  useEffect(() => {
    getListOfChapters(chapterSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterSearch]);
  useEffect(() => {
    setModalOpen(addChapterModal)
  }, [addChapterModal])
  const getListOfChapters = (search?: any) => {
    if (search !== '' && search !== 0 && search !== '0') {
      setLoader(false)
    } else {
      setLoader(true);
    }
    const params: any = {
      "searchChapterDto": {
        "isActive": true,
        "organisationId": orgId
      },
    }
    if (search !== '' && search !== 0 && search !== '0') {
      params.searchChapterDto.name = search
    }
    getChapterList(params)
      .then((response: any) => {
        const reformedChapterList: any = response?.data?.data.map((item: any, index: number) => ({
          key: item.chapterId,
          index: index + 1,
          ...item
        }))
        setLoader(false)
        setChapterList(reformedChapterList);
      })
      .catch((err: any) => {
        // triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(ErrorMessages.somethingWentWrong)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal();
          setLoader(false);
        }, 1500)
      });
  }

  const handleCloseDelete = () => {
    setDeletePopUp(false);
  };
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }

  const editFunction = (record: any) => {
    setModalOpen(true);
    setEditRecord(record);
    setEditModal(true);
  };
  const deleteFunction = (record: any) => {
    setDeletePopUp(true);
    setIsSingleDelete(true);
    const recordArray: any = [];
    recordArray.push(record);
    setSelectedRows(recordArray);
    setDeleteMessage(`Are you sure you want to delete ${record?.name} chapter ?`);
  };
  const handleSingleSelectDelete = async (record: any) => {
    setLoader(true);
    setDeletePopUp(false);
    const pathParams: any = {
      id: record.key,
    };
    handleDeleteDesignation(deleteChapterApi, '', getListOfChapters, '', pathParams, "elms");
  };
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'index',
      key: 'index',
      width: '10%',

    },
    {
      title: 'Chapter Name',
      dataIndex: 'name',
      key: 'name',
      width: '70%',

    },
    {
      title: 'Action',
      dataIndex: 'deleteButton, editButton',
      width: '20%',

      render: (_: any, record: any) => (
        <div className='flex'>
          <Tooltip placement="top" title="Edit">
            <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => editFunction(record)} >
              Edit
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Delete">
            <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => deleteFunction(record)}>
              Delete
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleClose = () => {
    setModalOpen(false);
    setAddChapterModal(false)
    setEditRecord({})
    setEditModal(false)
  }
  return (
    <div className='lm-table m-3 pt-[3px] bg-[#F5F5F5]'>
      <Table columns={columns} dataSource={chapterList} className='pl-4 bg-[#F5F5F5]' />
      {deletePopUp && <DeleteConfirmationModal open={deletePopUp} handleDeleteSelected={''} handleSingleSelectDelete={handleSingleSelectDelete} handleCloseDelete={handleCloseDelete} selectedRows={selectedRows} type='department' isSingleDelete={isSingleDelete} deleteMessage={deleteMessage} />}
      {modalOpen && (
        <Modal className='addcourse w-full' title={`${editModal ? 'Edit Chapter' : 'Add Chapter'}`} centered open={modalOpen} onCancel={handleClose} footer={null} maskClosable={false}>
          <div className="main w-full">
            <div className={`main-container w-full grey`}>
              <AddChapterContent close={handleClose} getListOfChapters={getListOfChapters} isEdit={editModal} editRecord={editRecord} start={0} />
            </div>
          </div>
        </Modal>
      )}
      {loader && <Loader />}
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

    </div>
  )
}

export default ChapterandCertificateTable