import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { storeData, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../../components/Loader/Loader';
import noRecordFound from "../../../../../../assets/images/empty-folder.png"
import { useSelector, useDispatch } from 'react-redux';
import { setProfileLoad } from '../../../../../../redux/slices/updateProfileSlice';
import ExitFormModal from './exit-form/modal/ExitFormModal';
import AppraisalReviewForm from './AppraisalReviewForm';
import EmployeeAppraisalCard from './components/EmployeeAppraisalCard';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';

const EmployeeAppraisal = (activeTab: any) => {
  const resginationTabsLoad = useSelector((state: any) => state?.profileReload?.deprovisionLoad);
  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const dispatch = useDispatch();
  const salaryRange = useGetApiRequests('getAppraisal', 'GET');
  const getQuestions = useGetApiRequests('getquestions', 'GET');
  const { id }: any = useParams();
  const size: any = 'large';
  const [modalOpen, setModalOpen] = useState(false);
  const [isExitFormModalOpen, setIsExitFormModalOpen] = useState<boolean>(false);

  const [reviewList, setReviewList] = useState<any>([])

  const [loader, setLoader] = useState(true);
  const [isView, setIsView] = useState(false)
  const [isPending, setIsPending] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const designationId = useSelector((state: any) => state?.employeeTypeId?.id)
  const [reviewType, setReviewType] = useState(null);
  const [employeeReviewId, setEmployeeReviewId] = useState(null);
  const [employeeReviewType, setEmployeeReviewType] = useState<string | null>(null)
  const [employeeReviewFormActionType, setEmployeeReviewFormActionType] = useState<string>('create');

  useEffect(() => {
    designationId && getReviews();
  }, [designationId, activeTab]);

  const dateConvertion = (date: any) => {
    let spliteValue = date.split('T')[0];
    let value = spliteValue;
    let dateValue = value.split('-')
    return dateValue[2] + '-' + dateValue[1] + '-' + dateValue[0];
  }
  const getReviews = () => {
    const params: any = {
      id: id,
    };
    setLoader(true)
    salaryRange('', '', params)
      .then((response: any) => {
        dispatch(setProfileLoad());
        const apiResponse = response?.data?.data;


        if (employeeDetails?.status?.name === "Resigned") {
          const filterResponse = apiResponse.filter((item: any) => item.type === 'Exit Interview Form');
          setReviewList(filterResponse)
        } else {
          setReviewList(apiResponse)
        }
        getQuestionsApi();
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      });
  }
  const getQuestionsApi = () => {
    const params: any = {
      designationId: designationId, organisationId: orgId
    };
    const pathParams: any = {
      id: id
    }
    setLoader(true);
    getQuestions('', params, pathParams)
      .then((response: any) => {
        const apiResponse: any = response?.data?.data;
        setReviewType(apiResponse?.reviewType)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  }
  const handelReviews = () => {
    storeData("reviewtype", '', true);
    storeData("reviewStatus", '', true);
    setEmployeeReviewFormActionType('create');
    setModalOpen(true)
    setIsView(false);
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  const handleView = (object: any, employeeReviewId: any) => {
    storeData("currentEmployeeId", object?.employeeId, true);
    if (object.type !== 'Exit Interview Form') {
      setEmployeeReviewFormActionType('edit');
      storeData("reviewtype", '', true);
      storeData("reviewStatus", object.reviewStatus, true);
      setEmployeeReviewType(object?.reviewStatus);
      if (object?.reviewStatus === 'Pending' || object?.reviewStatus === 'Draft') {
        setIsPending(true);
        setIsView(false);
      } else {
        setIsView(true);
        setIsPending(false);
      }
      // setIsView(true);
      setModalOpen(true);
      setEmployeeReviewId(object.employeeReviewId);
    }
    else {
      setIsExitFormModalOpen(true)
    }
  }


  const handleModalClose = () => {
    setIsExitFormModalOpen(false);
  };
  return (
    <div className='employee-appraisal'>
      <div className="grid grid-cols-1 ">
        <div className='flex justify-end'>
          <Tooltip placement="bottom" title="Create Appraisal">
            <Button type="primary" shape="circle" size={size} onClick={() => handelReviews()}>
              <span className="material-symbols-outlined">
                add
              </span>
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="employee-appraisal-container overflow-y-auto">

        {reviewList && reviewList.length > 0 ? reviewList.map((item: any) => (
          // <div key={item?.employeeReviewId} className='employee-appraisal-card cursor-pointer'
          //   onClick={() => handleView(item, item?.employeeReviewId)} >
          //   <p className='employee-appraisal-title' >{item?.reviewFormName}</p>
          //   <div className=" grid-rows-4 grid-flow-col gap-4 flex asset-header pt-3">
          //     {item?.reviewedOn !== null ? (
          //       <div className='header-status header-border' >Review date : <span>{item?.reviewedOn !== null ? dateConvertion(item?.reviewedOn) : '-'}</span>  </div>) : (
          //       <div className='header-status header-border' >Probation Extension end date : <span>{item?.nextProbationEndDate !== null ? dateConvertion(item?.nextProbationEndDate) : '-'}</span></div>
          //     )}
          //     <div className='header-status header-border'> Review Status : <span>{item?.reviewStatus}</span> </div>
          //     {item?.type !== 'Probation' && item?.type !== 'Probation Extension' && reviewType !== null && (
          //       <div className='header-status'>Eligible for Increment:  <span>{item?.eligibleForIncrement === false || item?.eligibleForIncrement === null ? 'No' : 'Yes'}</span> </div>)}
          //   </div>
          // </div>
          <EmployeeAppraisalCard
            key={item?.employeeReviewId}
            item={item}
            handleView={handleView}
            reviewType={reviewType}
          />
        )) : <div className="no-record appraisal">
          <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />

          </p>
        </div>}

      </div>
      {
        isExitFormModalOpen && <ExitFormModal open={isExitFormModalOpen} handleClose={handleModalClose} />
      }

      {modalOpen && <AppraisalReviewForm
        open={modalOpen}
        handleCloseModal={handleCloseModal}
        getReviewsApi={getReviews}
        isView={isView}
        employeeReviewId={employeeReviewId}
        isPending={isPending}
        employeeReviewType={employeeReviewType}
        employeeReviewFormActionType={employeeReviewFormActionType} />}
      {loader && <ModalLoader />}
    </div >
  )
}

export default EmployeeAppraisal
