import React, { useContext, useState } from 'react';
import { Pagination, Input, Tooltip, Button } from 'antd';
import CreateDepartmentModal from './CreateRoleModal';
import DynamicTable from '../../../components/CommonTable/DynamicTable';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import ImagesComponent from '../../../components/Images/Images';
import { DepartmentContext } from './Department';
import { DepartmentColumns } from '../TableColumn';
import { handleDeleteDesignation } from '../OrganisationHelper';
import { useGetApiRequests } from '../../../common/CommonExport';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';
const { Search } = Input;
const DepartmentRender: React.FC = () => {
    const context = useContext(DepartmentContext);
    const { departments, departmentGetApi, onSearch, onShowSizeChange, currentPage, pageSize, count, setCurrentPage, loader } = context;
    const [editRecord, setEditRecord] = useState({});
    const [editModal, setEditModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [isSingleDelete, setIsSingleDelete] = useState(false);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalerrMessage, setModalErrMessage] = useState('')
    const [popModal, setPopModal] = useState(false)
    const [modalResponse, setModalResponse] = useState('')
    const [modalButtonShow, setmodalButtonShow] = useState(true)
    const deleteDepartmentApi = useGetApiRequests('departmentDelete', 'DELETE');
    const deleteSingleApi = useGetApiRequests('departmentSingleDelete', 'DELETE');
    const handleDeleteSelected = async () => {
        setDeletePopUp(false);
        const deletePayload: any = [];
        for (const element of selectedRows) {
            const departmentDeleteList: object = {
                departmentId: element,
            };
            deletePayload.push(departmentDeleteList);
        }
        handleDeleteDesignation(deleteDepartmentApi, deletePayload, departmentGetApi);
        setSelectedRows([])
    };
    const handleSingleSelectDelete = async (record: any) => {
        setDeletePopUp(false);
        const pathParams: any = {
            id: record.key,
        };
        handleDeleteDesignation(deleteSingleApi, '', departmentGetApi, '', pathParams);
        setSelectedRows([])
    };
    //Table functions
    const handleClosePopUpModal = () => {
        setPopModal(false)
    }
    const editRowFunction = (record: any) => {
        setModalOpen(true);
        setEditRecord(record);
        setEditModal(true);
    };
    const singleDeleteFunction = (record: any) => {
        setDeletePopUp(true);
        setIsSingleDelete(true);
        const recordArray: any = [];
        recordArray.push(record);
        setSelectedRows(recordArray);
        setDeleteMessage(`Are you sure you want to delete ${record.department} department ?`);
    };
    const columns = [
        ...DepartmentColumns,
        {
            title: 'Action',
            dataIndex: 'deleteButton, editButton',
            render: (_: any, record: any) => (
                <div className='flex'>
                    <Tooltip placement="top" title="Edit">
                        <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => editRowFunction(record)} >
                            Edit
                        </span>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                        <span className="material-symbols-outlined mr-4 cursor-pointer hover:text-primary" onClick={() => singleDeleteFunction(record)}>
                            Delete
                        </span>
                    </Tooltip>
                </div>
            ),
        },
    ];
    const handleSelectedRows = (selected: any) => {
        setSelectedRows(selected);
        setIsSingleDelete(false);
        setDeleteMessage(`Are you sure you want to delete these ${selected.length} department(s)?`);
    };
    const handleCloseDelete = () => {
        setDeletePopUp(false);
    };
    const createModalHandle = () => {
        setModalOpen(true);
        setEditModal(false);
    };
    return (
        <div>
            <div className='flex justify-between px-4 pb-5'>
                <div className='search-field'>
                    <Search placeholder='Search' onChange={e => onSearch(e.target.value)} enterButton />
                </div>
                <div className='flex gap-5'>
                    {selectedRows && selectedRows?.length > 0 && !isSingleDelete && (
                        <div>
                            <Tooltip placement="top" title="Delete Department">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    className="flex items-center justify-center font-bold"
                                    icon={<span className="material-symbols-outlined"> delete </span>}
                                    size="large"
                                    onClick={() => setDeletePopUp(true)}
                                >
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                    <div>
                        <Tooltip placement="top" title="Create Department">
                            <Button
                                type="primary"
                                shape="circle"
                                className="flex items-center justify-center font-bold"
                                icon={<span className="material-symbols-outlined"> add </span>}
                                size="large"
                                onClick={createModalHandle}
                                disabled={!!(selectedRows && selectedRows?.length > 0)}
                            >
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <DynamicTable columns={columns} data={departments} handleSelectedRows={handleSelectedRows} loading={loader} />
            <div className='pagination cursor-pointer'>
                <Pagination className='float-right flex flex-row p-6 cursor-pointer' showSizeChanger onShowSizeChange={onShowSizeChange} defaultCurrent={1} current={currentPage} total={count} pageSize={pageSize} onChange={(page) => setCurrentPage(page)} />
            </div>
            {modalOpen && <CreateDepartmentModal departmentApi={departmentGetApi} setModalOpen={setModalOpen} modalOpen={modalOpen} editRecord={editRecord} editModal={editModal} />}
            {deletePopUp && <DeleteConfirmationModal open={deletePopUp} handleDeleteSelected={handleDeleteSelected} handleSingleSelectDelete={handleSingleSelectDelete} handleCloseDelete={handleCloseDelete} selectedRows={selectedRows} type='department' isSingleDelete={isSingleDelete} deleteMessage={deleteMessage} />}
            {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
        </div >
    );
};
export default DepartmentRender;
