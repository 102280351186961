import React, { useState, useEffect } from 'react'
import "./Announcements.scss"
import "./layouts/mailList/mail.scss"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button, Tooltip } from 'antd'
import MailLists from './layouts/mailList/MailLists'
import FilterForm from './layouts/filter/FilterForm'
import AnnouncementHOC from './layouts/Hoc/AnnouncementHOC'
import composeMail from '../../assets/images/compose-email.png';
import { useDispatch, useSelector, useGetApiRequests, triggerNotification } from '../../common/CommonExport';
import ComposeMailModal from './modal/ComposeMailModal';
import {
  announcementSliceRootState,
  clearAnnouncementIdList,
  fetchApiCall,
} from '../../redux/slices/announcementIdSlice';
import AnnouncementDeleteModal from './modal/AnnouncementDeleteModal';
import AnnouncementSearch from './layouts/search/AnnouncementSearch';
import axios from 'axios';
import ScheduledAnnouncementAlert from './layouts/scheduled/ScheduledAnnouncementAlert';
import PopupModal from '../employee/employee-details/employee-profile/modal/PopupModal';

interface FilterFormData {
  toEmails: string[] | [];
  subjects: string | null;
  selectedDate: Date | string | null
  // Add more fields based on your form's structure
}
const AnnouncementsSent = () => {
  const [loader, setLoader] = useState<boolean>(false)
  const [sendMail, setSendMail] = useState<boolean>(false);
  const [isActiveFilter, setIsActiveFilter] = useState<boolean>(false)
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [filterFormData, setFilterFormData] = useState<FilterFormData | null>(null);
  const deleteAnnouncements = useGetApiRequests('deleteAnnouncement', 'DELETE');
  const dispatch = useDispatch();
  const [searchAnnouncement, setSearchAnnouncement] = useState<string>('');
  const announcementSlice = useSelector((state: announcementSliceRootState) => state.announcementIdSlice);
  const { announcementIdList, isReload, deleteAll } = announcementSlice;
  const [markAll, setMarkAll] = useState<boolean>(false);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)

  useEffect(() => {
    return () => {
      dispatch(clearAnnouncementIdList());
    };
  }, []);

  const handleClickFilter = () => {
    setIsActiveFilter(!isActiveFilter)
  }

  const handleSendMail = () => {
    setSendMail(true)
  }

  const handleDelete = () => {
    const idsToDelete = announcementSlice?.deleteAll?.length > 0
      ? announcementSlice.deleteAll
      : announcementIdList;

    let deleteForever = {
      'deleteForever': false
    }
    if (idsToDelete.length > 0) {
      deleteAnnouncements(idsToDelete, deleteForever)
        .then((response) => {
          if (axios.isAxiosError(response)) {
            throw response;
          }
          // triggerNotification('success', '', response?.data?.message, 'topRight');
          setPopModal(true)
          setmodalButtonShow(false)
          setModalErrMessage(response?.data?.message)
          setModalResponse('success')
          setTimeout(() => {
            handleClosePopUpModal()
            setDeletePopUp(false);
          }, 1500)
          dispatch(fetchApiCall(!isReload));
          dispatch(clearAnnouncementIdList());
        })
        .catch((err) => {
          setLoader(false)
          // triggerNotification('error', '', err?.message, 'topRight');
          setPopModal(true)
          setmodalButtonShow(false)
          setModalErrMessage(err?.message)
          setModalResponse('error')
          setTimeout(() => {
            handleClosePopUpModal()
            setDeletePopUp(false);
          }, 1500)
        });
    }
  }
  const handleFormUpdate = (formData: FilterFormData, buttonType: string) => {
    if (buttonType === "search") {
      setFilterFormData(formData);
      setIsActiveFilter(false);
    }
  }

  const handleDeleteModalOpen = () => {
    setDeletePopUp(true)
    setDeleteMessage('Delete Announcement')
  }
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  return (
    <>
      <div className='flex justify-between items-center'>
        {/* <ScheduledAnnouncementAlert /> */}
        <div className='announcement-search w-full relative'>
          <AnnouncementSearch setSearchAnnouncement={setSearchAnnouncement} />
          <div className='w-full announcement-search--filter'>
            <Tooltip title='Show search options'>
              <i className="fi fi-rr-settings-sliders cursor-pointer" onClick={handleClickFilter}></i>
            </Tooltip>
            {
              isActiveFilter && <FilterForm setIsActiveFilter={setIsActiveFilter} onFormUpdate={handleFormUpdate} />
            }

          </div>
        </div>
        <div className=' flex items-center space-x-3'>
          <div>

            {
              announcementIdList?.length > 0 ? (
                <div className=' flex space-x-4 items-center '>
                  <Tooltip>
                    <div className=' flex space-x-2 items-center cursor-pointer' onClick={() => { setMarkAll(!markAll) }}>
                      <span className="material-symbols-outlined cursor-pointer check-delete-all font-light">
                        mark_email_read
                      </span>
                      <p role='none' className=' cursor-pointer font-[300] text-[16px] pt-[2px] text-[#D23B7D] font-rubik'>{!markAll ? "Select All" : "Deselect All"}</p>
                    </div>
                  </Tooltip>
                  <Tooltip title='Delete'>
                    <Button
                      type="primary" shape="circle" size={'large'}
                      className='bulk-upload_dropdown flex justify-center'
                      onClick={handleDeleteModalOpen}>
                      <span className="material-symbols-outlined font-[300]">
                        delete
                      </span>
                    </Button>
                  </Tooltip>
                </div>
              ) : <></>
            }

          </div>
          <div className='compose-mail-icon'>
            <Tooltip title='Compose'>
              {/* <Button onClick={handleSendMail} type="primary" shape="circle" size={'large'} className='bulk-upload_dropdown flex justify-center'>

                <img src={composeMail} alt="composeMail" className=' composeMail-icon' />
              </Button> */}
              <button onClick={handleSendMail} className=' flex justify-center bg-[#d23b7d] composeMail-btn'>
                <img src={composeMail} alt="composeMail" className=' composeMail-icon' />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className='mailList mt-8'>
        <MailLists status={'SENT'} searchAnnouncement={searchAnnouncement} filterFormData={filterFormData} markAll={markAll} />
      </div>

      {
        sendMail && <ComposeMailModal sendMail={sendMail} setSendMail={setSendMail} title='New Message' />
      }
      {deletePopUp && <AnnouncementDeleteModal
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        type='Delete' handleDelete={handleDelete}
        deleteMessage={markAll ? 'delete this Announcements?' : 'delete this Announcement?'}
        extraDeleteMessage={''}
      />}
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

    </>

  )
}

export default AnnouncementHOC(AnnouncementsSent)