import { ErrorMessages, triggerNotification } from "../../common/CommonExport";
import { setEmployeeList } from "../../redux/slices/employeeListSlice";

export const getCurrentMonth = () => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const currentDate = new Date();
    return months[currentDate.getMonth()].substring(0, 3);
};

export const sortEventData = (dashboardData: any) => {
    return [...dashboardData].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        const monthA = dateA.getMonth();
        const dayA = dateA.getDate();
        const monthB = dateB.getMonth();
        const dayB = dateB.getDate();
        return monthA === monthB ? dayA - dayB : monthA - monthB;
    });
};

export const isObjectEmpty = (obj: any) => {
    for (const key in obj) {
        if (key !== 'nextProbationEndDate') {
            if (typeof obj[key] === 'object') {
                if (!isObjectEmpty(obj[key])) {
                    return false;
                }
            } else if (!obj[key]) {
                return false;
            }
        }
    }
    return true;
};

export const empInitValues = {
    nextProbationEndDate: '',
    firstName: '',
    lastName: '',
    officialEmail: '',
    dateOfJoin: '',
    personalEmail: '',
    organisationId: '1',
    designation: {
        designationId: '',
    },
    department: {
        departmentId: '',
    },
    status: {
        statusId: '',
    },
    reportingManager: {
        employeeId: '',
    },
    // initiationManager: {
    //     employeeId: '',
    // },
    employeeType: {
        employeeTypeId: '',
    },
};

export const empFilter = [
    {
        text: 'All Employees',
        value: 'All Employees'
    },
    {
        text: 'Probation',
        value: 'Probation',
    },
    {
        text: 'Confirmed',
        value: 'Confirmed',
    },
    {
        text: 'Past Employees',
        value: 'Past Employees',
    },
    {
        text: 'Appraisal Due',
        value: 'Appraisal Due',
    },
];

export const setDateFormat = (originalDateString: any) => {
    const originalDate = new Date(originalDateString);
    const year = originalDate.getUTCFullYear();
    const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

export const filterTakeUnique = (reformedEmployeeData: any, type: any) => {
    const unique = Array.from(new Set(reformedEmployeeData.map((item: any) => item[type])));
    const uniqueWithNone = unique.reduce((result: any, item) => {
        const newItem = {
            text: item ?? 'None',
            value: item,
        };

        if (newItem.text === 'None') {
            result.push(newItem);
        } else {
            result.unshift(newItem); // Add to the beginning of the array
        }

        return result;
    }, []);

    return uniqueWithNone;
};


export const initLoadData = (dashboardData: any, setAllFilterValues: any, setEmployeeData: any, setEmployeeDataSearch: any, dispatch: any = false) => {
    const reformedEmployeeData = dashboardData?.allEmployees?.map((item: any, index: number) => ({
        employeename: `${item?.firstName} ${item?.lastName}`,
        doj: setDateFormat(item?.dateOfJoin),
        grade: item?.gradeName,
        designation: item?.designationName,
        dept: item?.departmentName,
        reportingTo: item?.reportingManagerName,
        status: item?.statusName,
        key: item?.employeeId,
        employeeTypeId: item?.employeeTypeId,
        profileUrl: item?.profileUrl,
        reviewStatus: item?.reviewStatus
    }));
    setAllFilterValues({
        grades: filterTakeUnique(reformedEmployeeData, 'grade'),
        designation: filterTakeUnique(reformedEmployeeData, 'designation'),
        dept: filterTakeUnique(reformedEmployeeData, 'dept'),
        reportingTo: filterTakeUnique(reformedEmployeeData, 'reportingTo'),
        status: empFilter,
    });
    setEmployeeData(reformedEmployeeData);
    setEmployeeDataSearch(reformedEmployeeData);
    if (dispatch)
        dispatch(setEmployeeList(reformedEmployeeData));
};

export const employeeFilterApiCall = async (value: any, EmployeeFilter: any, setAllFilterValues: any, setEmployeeData: any, setEmployeeDataSearch: any, setLoader: any, setPopModal: any, setmodalButtonShow: any, setModalErrMessage: any, setModalResponse: any, handleClosePopUpModal: any) => {
    setLoader(true)
    try {
        const paramFalse: Object = { isDashboardRefresh: false, employeeDashboardFilterDto: { departmentNames: [], designationNames: [], gradeNames: [], status: value ?? 'All Employees' } };
        const res = await EmployeeFilter('', paramFalse);
        setLoader(false)
        initLoadData(res?.data?.data, setAllFilterValues, setEmployeeData, setEmployeeDataSearch);
    } catch (err: any) {

        // triggerNotification('error', '', err?.response?.data?.status?.message ?? ErrorMessages.somethingWentWrong, 'topRight');
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.response?.data?.status?.message ?? ErrorMessages.somethingWentWrong)
        setModalResponse('error')
        setTimeout(() => {
            setLoader(false)
            handleClosePopUpModal();
        }, 1500)
    }
};

export const handleCurrentStep = (data: any) => {
    const descriptions = new Set();
    let result = 0;
    for (const item of data) {
        descriptions.add(item.description);
        if (descriptions.has('Employee information saved') && descriptions.has('Document sent') && descriptions.has('Document signed off')) {
            result = 3;
        } else if (descriptions.has('Employee information saved') && descriptions.has('Document sent')) {
            result = 2;
        } else if (descriptions.has('Employee information saved')) {
            result = 1;
        }
    }
    return result;
};

export const handleExpand = (record: any, documentStatus: any, setCurrentStep: any, setExpandedData: any, setExpandedRowKey: any, expandedRowKey: any, setLoader: any) => {
    setLoader(true)
    const pathParams = {
        id: record.key,
        end: 'document-status',
    };
    documentStatus('', {}, pathParams)
        .then((res: any) => {
            const result = handleCurrentStep(res?.data?.data?.employeeFootPrintDto);
            setCurrentStep(result);
            setExpandedData(res?.data);
            if (expandedRowKey !== record.key) setExpandedRowKey(record.key);
            setLoader(false)
        })
        .catch((err: any) => {
            setLoader(false)
            console.log(err);
        });
};

export const searchEmployeeByName = (data: any, searchTerm: any) => {
    // const normalizedSearchTerm = searchTerm.toLowerCase();
    // const filteredData = data.filter((record: any) => record.employeename.toLowerCase().includes(normalizedSearchTerm));
    // return filteredData;
    const normalizedSearchTerm = searchTerm.toLowerCase();
    const filteredData = data.filter((record: any) => {
        return Object.values(record).some((value: any) => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(normalizedSearchTerm);
            }
            return false;
        });
    });
    return filteredData;

};
