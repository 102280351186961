import React, { useEffect, useState } from 'react';
import noRecordFound from "../../../../../../assets/images/empty-folder.png"
import { triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import AddBonusModal from './AddBonusModal';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { bonusList, handleEditEmployee } from './EmployeeBonusHelper';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../../../components/Loader/Loader';
import { setBonusLoad } from '../../../../../../redux/slices/updateProfileSlice';
import CancelBonusDate from './CancelBonusDate';
import DocumentPDFView from '../documents/documentPDFView';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';

const EmployeeBonus = () => {
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const documentPreview = useGetApiRequests('documentPreview', 'POST');
  const getBonusDetails = useGetApiRequests('getBonusDetails', 'GET');
  const updateBonus = useGetApiRequests('updateBonusDetails', 'PUT');
  const [bonusDetails, setBonusDetails] = useState<any>()
  const [openAddBonus, setOpenAddBonus] = useState<boolean>(false)
  const bonusReload = useSelector((state: any) => state?.profileReload?.bonusReload);
  const [currentPdfDetails, setCurrentPdfDetails] = useState<any>({})
  const [currentEmpBonusDetail, setCurrentEmpBonusDetail] = useState<any>({
    isEdit: false,
    detail: null
  })
  const imageData: any = '';
  const [cancelBonusDate, setCancelBonusDate] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(true)
  const [openDocumentmodal, setOpenDocumentmodal] = useState<boolean>(false)
  const [createOnDate, setCreateOnDate] = useState<any>(null)
  useEffect(() => {
    bonusList(getBonusDetails, setBonusDetails, id, setLoader)
  }, [bonusReload])

  const handleCloseBonus = () => {
    setCurrentEmpBonusDetail({ isEdit: false, detail: null })
    setOpenAddBonus(false)
    setOpenDocumentmodal(false)
    setCancelBonusDate(false)

  }

  const getDocumentDetails = async (item: any) => {
    setLoader(true)
    const formData = new FormData();
    formData.append('file', imageData);
    const headers = { 'Content-Type': 'multipart/form-data' };
    const queryParams = {
      employeeId: item?.employee?.employeeId,
      documentCode: item?.type === "project_bonus" ? 'PROJTBL' : 'PBL'
    }
    try {
      const response: any = await documentPreview(formData, queryParams, {}, headers)
      if (response?.data?.status?.success === 'Success') {
        setCurrentPdfDetails(response?.data?.data[0])
        setOpenDocumentmodal(true)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      }
    } catch (err: any) {
      setTimeout(() => {
        setLoader(false)
      }, 1000)
      triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    }
  }

  const handlePreviewModal = (item: any, type: any) => {
    setCurrentEmpBonusDetail({ isEdit: true, detail: item });
    if (type === 'document') getDocumentDetails(item)
    else if (type === 'cancelled') {
      setCancelBonusDate(true)
      setCreateOnDate(item.createdOn)
    }


  }

  const handlePutData = async (values: any) => {
    setLoader(true)
    const payloads = values
    try {
      const response: any = await updateBonus(payloads, {}, { id: values?.bonusId })
      triggerNotification('success', '', response?.data?.status?.message, 'topRight');
      setTimeout(() => {
        setLoader(false)
      }, 1000)
      dispatch(setBonusLoad());
    } catch (err: any) {
      setTimeout(() => {
        setLoader(false)
      }, 1000)
      triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
    }
  }

  const handleConfirmBonus = (item: any, type: any, cancelDate: any) => {
    const payload = {
      bonusId: item?.bonusId, name: item?.name, amount: item?.amount, startDate: dayjs(item?.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(item?.endDate).format("YYYY-MM-DD"), status: type, type: item?.type,
      employee: { employeeId: id }, ...(cancelDate && { cancellationDate: cancelDate }),
    }
    handlePutData(payload)
  }

  const approvedOrRejected = (status: any) => {
    return (
      <>
        {status?.status === 'confirmed' && status?.bonusApprovalStatus === null &&
          <p className='employee-bonus-confirmation flex '>
            <span className="material-symbols-outlined pr-3">
              schedule
            </span> Waiting for Approval</p>
        }
        {status?.status === 'confirmed' && status?.bonusApprovalStatus === 'approved' &&
          <p className='employee-bonus-approved flex pr-4'>
            <span className="material-symbols-outlined pr-1">
              check_circle
            </span> Approved</p>
        }
        {status?.status === 'confirmed' && status?.bonusApprovalStatus === 'rejected' &&
          <p className=' flex pr-4 text-[red] text-[14px] '>
            <span className="rejected-designation material-symbols-outlined rejected pr-1">
              cancel
            </span> Rejected</p>
        }
        {(status?.bonusApprovalStatus !== null && status?.bonusApprovalStatus !== 'rejected') &&
          <button onClick={() => handlePreviewModal(status, 'cancelled')} className='addButton cancel'>
            Cancel
          </button>
        }
      </>
    )
  }

  const renderStatusOfBonus = (status: any) => {
    return (
      <div className='employee-bonus-card  flex items-center justify-center'>
        {status?.status === 'draft' &&
          <button onClick={() => handleConfirmBonus(status, 'confirmed', '')} className='addButton confirm'>
            Confirm
          </button>
        }
        {status?.status === 'confirmed' && approvedOrRejected(status)}
        {status === 'approved' &&
          <>
            <p className='employee-bonus-approved flex pr-4'>
              <span className="material-symbols-outlined pr-1">
                check_circle
              </span> Approved</p>
            <button className='addButton cancel'>
              Cancel
            </button>
          </>
        }
        {status?.status === 'cancelled' &&
          <p className='employee-bonus-cancelled flex pr-4'>
            Cancelled</p>
        }
      </div>
    )
  }

  return (
    <>
      {loader && <ModalLoader />}
      {
        bonusDetails?.data?.data?.length > 0 ?
          <div className='employee-bonus'>
            <div className="employee-bonus-container" >
              {bonusDetails?.data?.data?.map((item: any) => (
                <div key={item?.bonusId} className="grid grid-cols-3 gap-4 employee-bonus_container">
                  <div onClick={() => (item?.bonusApprovalStatus !== "rejected" && item?.status !== 'cancelled') && handleEditEmployee(item, setCurrentEmpBonusDetail, setOpenAddBonus)} className='employee-bonus-card  flex  flex-col '>
                    <p className='employee-bonus-title cursor-pointer'>{item?.name} {item?.type === 'project_bonus' ? 'Project Bonus' : 'Performance Bonus'}</p>
                    <div className="flex">
                      <span className='employee-bonus-date mr-4 cursor-pointer'>Created on {`${dayjs(item?.startDate).format('DD.MM.YYYY')}`}</span>
                      {item?.cancellationDate !== null && <span className='employee-bonus-date'>Cancelled on {`${dayjs(item?.cancellationDate).format('DD.MM.YYYY')}`}</span>}
                    </div>
                  </div>
                  <div className='employee-bonus-card- flex items-center justify-center'>
                    <span onClick={() => handlePreviewModal(item, 'document')} className="material-symbols-outlined cursor-pointer">
                      visibility
                    </span>
                  </div>
                  {renderStatusOfBonus(item)}
                </div>
              ))}
            </div >
          </div >
          :
          <div className="no-record salary min-h-[60vh]">
            <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
            </p>
          </div>
      }
      {openDocumentmodal && <DocumentPDFView open={openDocumentmodal} setDocumentView={setOpenDocumentmodal} currentPdfDetails={currentPdfDetails} mainPage={false} />}
      {openAddBonus && <AddBonusModal open={openAddBonus} handleCloseBonus={handleCloseBonus} currentEmpBonusDetail={currentEmpBonusDetail} />}
      {cancelBonusDate && <CancelBonusDate createOnDate={createOnDate} open={cancelBonusDate} currentEmpBonusDetail={currentEmpBonusDetail} handleCloseBonus={handleCloseBonus} handleConfirmBonus={handleConfirmBonus} certificate={false} />}
    </>
  )
}

export default EmployeeBonus
