import React, { useState } from 'react'
import { Modal } from "antd";
import ImageUpload from '../../Dashboard/AddEmployeeModal/imageUpload/ImageUpload';
import Pdf from "../../../assets/images/file-pdf.svg"
import { triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import Loader from '../../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import ModalLoader from '../../../components/Loader/ModalLoader';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';

const AddAttenanceModal = (props: any) => {
  const csvData = [
    ["firstname", "lastname", "isActive", "status", "designationName", "departmentName", "reportingManagerFirstName", "reportingManagerLastName", "personalEmail", "dateOfBirth", "dateOfJoin", "employeeType"],
    ["Ahmed", "Tomi", "TRUE", "Probation", "Business Analyst", "Marketing", "Hari", "Sudan", "john.doee@example.com", "1997-01-01", "2023-01-01", "Regular"],
    ["Ramesh", "Labes", "TRUE", "Probation", "UI Developer", "UI", "Rajan", "Prince", "john.doee@example.com", "1997-01-01", "2023-01-01", "Regular"],
    ["Raj", "Kumar", "TRUE", "Probation", "Tester", "Testing", "Rajesh", "Kumar", "john.doee@example.com", "1997-01-01", "2023-01-01", "Regular"]
  ];
  const { open, handleClose, bulk, holidayApi } = props;
  const [mediaData, setMediaData] = useState<any>(new FormData());
  const [loader, setLoader] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const holidayBulkUploadApi = useGetApiRequests('holidayBulkupload', 'POST');
  const EmployeeBulkApi = useGetApiRequests('employeeBulkUpload', 'POST');
  const [uploadFile, setUploadFile] = useState(false);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  const handleImageDelete = (file: File) => setMediaData('');
  const handleImageUpload = (file: File) => {
    setUploadFile(true);
    const formData = new FormData();
    if (bulk === 'employee_bulk_upload') {
      formData.append('employeecsvfile', file);
    } else {
      formData.append('file', file);
    }
    setMediaData(formData);

  };
  const handleCloseModal = () => {
    setUploadFile(false);
    handleClose();
  }
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  const handleAddDocument = () => {

    if (mediaData !== '' && mediaData !== null && mediaData !== undefined && uploadFile) {
      setLoader(true);
      const headers = { 'Content-Type': 'multipart/form-data' };
      const queryParams: any = {
        organisationId: orgId
      }
      if (bulk === 'employee_bulk_upload') {
        EmployeeBulkApi(mediaData, queryParams, {}, headers).then((res: any) => {
          if (res?.data?.data !== null) {
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage('File Uploaded Successfully, Please check after sometime')
            setModalResponse('success')
            setTimeout(() => {
              setLoader(false);
              handleClose();
            }, 1500)
          } else {
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage(res?.data?.status?.message)
            setModalResponse('success')
            setTimeout(() => {
              handleClose();
              setLoader(false);
            }, 1500)
          }
        })
          .catch((err: any) => {
            setPopModal(true)
            setModalErrMessage(err?.message)
            setmodalButtonShow(false)
            setModalResponse('error')
            setTimeout(() => {
              setLoader(false);
              handleClose();
            }, 1500)
          })
      } else {
        holidayBulkUploadApi(mediaData, '', {}, headers).then((res: any) => {
          if (res?.data?.data !== null) {
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage(res?.data?.message)
            setModalResponse('success')
            setTimeout(() => {
              handleClose();
              setLoader(false);
            }, 1500)
            holidayApi()
          } else {
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage(res?.data?.message)
            setModalResponse('success')
            setTimeout(() => {
              setLoader(false);
              handleClose();
            }, 1500)
          }
        })
          .catch((err: any) => {
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage(err?.message)
            setModalResponse('error')
            setTimeout(() => {
              setLoader(false);
              handleClose();
            }, 1500)
          })
      }
    } else {
      setPopModal(true)
      setModalErrMessage('Please upload a file')
      setModalResponse('warning')
    }

  }

  const generateAndDownloadSampleXLSX = () => {
    const rows = [
      { holidayName: 'Pongal', holidayOn: '15/01/2024', holidayReoccuring: 'YES' },
      { holidayName: 'NewYear', holidayOn: '01/01/2024', holidayReoccuring: 'YES' },
      { holidayName: 'Republic Day', holidayOn: '26/01/2024', holidayReoccuring: 'YES' },
    ];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Holidays");

    XLSX.utils.sheet_add_aoa(worksheet, [
      ["Holiday Name", "Holiday On", "Holiday Reoccuring"],
    ]);
    XLSX.writeFile(workbook, "HolidayReportSample.xlsx", { compression: true });

  };
  return (
    <Modal data-testid="modal" title={bulk ? "Bulk Upload" : 'Import Holiday'} centered open={open} onCancel={() => handleCloseModal()} footer={null} className={bulk + " " + "modal-uploadDocument"} maskClosable={false}>
      <div className="py-6 documentUpload">
        <div className="attenance-upload">
          <div className='form-item'>
            <div className=' chapter-document'>
              {bulk === 'employee_bulk_upload' ? (
                <div>
                  <ImageUpload onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} maxFileSize={1024 * 1024 * 10}
                    allowedTypes={['text/csv']} />
                  <CSVLink className='flex text-center justify-center mt-2 text-[#D55382] text-[16px]' data={csvData}><img src={Pdf} alt="" className='mr-2' /> Click to download sample fileDownload</CSVLink>;
                </div>
              ) : (
                <div>
                  <ImageUpload onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} maxFileSize={1024 * 1024 * 10}
                    allowedTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ]} />
                  <p className='flex text-center justify-center mt-2 text-[#D55382] text-[16px] cursor-pointer' onClick={() => generateAndDownloadSampleXLSX()}> <img src={Pdf} alt="" className='mr-2' /> Click to download sample file</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <button className="addButton secondary w-28 mr-5" onClick={() => handleCloseModal()}>Cancel</button>
          <button className="addButton px-6 w-28" onClick={() => handleAddDocument()}>Add</button>
        </div>
      </div>
      {loader && <ModalLoader />}
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

    </Modal>
  )
}

export default AddAttenanceModal
