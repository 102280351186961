import React from 'react';
import { Attachment } from './ComposeMailUtiles';

interface FileItem {
  name?: string;
  fileName?: string;
}

interface FilesUploadedListProps {
  attachedFile: FileItem[];
  getAttachedFileNameList: Attachment[];
  iconModal: boolean;
  handleRemoveAttachment: (index: number) => void;
  removeAttachedFile: (index: number) => void;
}

const FilesUploadedList: React.FC<FilesUploadedListProps> = ({
  attachedFile,
  getAttachedFileNameList,
  iconModal,
  handleRemoveAttachment,
  removeAttachedFile,
}) => {
  return (
    <div className={`files-uploaded-list ${iconModal ? 'top-[64%]' : 'top-[73%]'}`}>
      {attachedFile.length > 0 &&
        attachedFile.map((file, index) => (
          <ul key={index} className="p-2 flex items-center bg-[#f5f5f5] mb-1">
            <li className="file-name w-[95%] pr-[2px]">{file.name}</li>
            <li
              className="text-[15px] text-[#d34a7c] font-bold cursor-pointer"
              onClick={() => handleRemoveAttachment(index)}
            >
              X
            </li>
          </ul>
        ))}

      {getAttachedFileNameList &&
        getAttachedFileNameList.map((file, index) => (
          <ul key={index} className="p-2 flex space-x-3 items-center bg-[#f5f5f5] mb-1">
            <li className="file-name">{file?.fileName}</li>
            <li
              className="text-[15px] text-[#d34a7c] font-bold cursor-pointer"
              onClick={() => removeAttachedFile(index)}
            >
              X
            </li>
          </ul>
        ))}
    </div>
  );
};

export default FilesUploadedList;
