import React, { useEffect, useState } from 'react'
import FloatLabel from '../../../../../../components/FloatLabel'
import { ErrorMessages, retrieveData, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport'
import { useParams } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Loader from '../../../../../../components/Loader/Loader';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';

const validationSchema = Yup.object().shape({
  newCtc: Yup.number().required('New Ctc is required').typeError('The new CTC should not contain alphabets'),
  appraiserComments: Yup.string().required('Appraiser Comment is required'),
});

const AppraisalForm = (props: any) => {
  const { setChangeCtcModal, questionObject, changeCtcModal, incrementEligible, getReviewsApi, handleCloseModal, isView, ctcDetails, isPending, employeeReviewId, userId } = props;
  const getCtcApi = useGetApiRequests('getEmployeeCtc', 'GET');
  const createReviewApi = useGetApiRequests('createReviewAppraisal', 'POST')
  const updateReviewApi = useGetApiRequests('updateReviewAppraisal', 'PUT')
  const [newCtc, setNewCtc] = useState(ctcDetails.newCtc);
  const [remarks, setRemarks] = useState(isView && !isPending ? ctcDetails.comments : '')
  const { id }: any = useParams();
  const [initialValues, setInitialValues]: any = useState({
    currentCtc: 0,
    newCtc: '',
    appraiserComments: '',
  });
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    getEmployeeCtc();
  }, []);
  const getEmployeeCtc = () => {
    const params: any = {
      id: id, end: 'employeecurrentctc'
    };
    setLoader(true);
    getCtcApi('', '', params)
      .then((response: any) => {
        const apiResponse = response?.data?.data;
        setInitialValues({ currentCtc: parseInt(apiResponse) })
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.response?.data?.message, 'topRight');
      });
  }
  const handleNewCtc = (event: any, setFieldValue: any, name: any) => {
    const input: any = event.target.value;
    if (name === 'newCtc') {
      if (input.trim() === '0') {
        triggerNotification('warning', '', ErrorMessages.newCtc, 'topRight');
      } else {
        setFieldValue('newCtc', input)
        setNewCtc(input)
      }

    } else {
      setFieldValue('appraiserComments', input)
      setRemarks(input)
    }
  };
  const handelSaveRevise = (values: any) => {


    let finalSendingPayload: any = {};
    let finalPayload: any = {}
    let questionOptionPayload: any = [];
    let queryParams: any
    setLoader(true);
    let questionKeys: any = Object.keys(questionObject);
    questionKeys.forEach((item: any) => {
      let editedKey = item.split("_").join("").toLowerCase();
      if (editedKey === "appraiserfeedback") {
        questionObject &&
          Object.keys(questionObject[item]).forEach((data: any) => {
            questionOptionPayload.push({
              otherText: questionObject[item][data].answer,
              employeeReviewDetailId: questionObject[item][data]?.employeeReviewDetailId ? questionObject[item][data]?.employeeReviewDetailId : undefined,
              questionaire: {
                questionaireId: questionObject[item][data].questionaireId,
              },
            });
          });
      } else if (editedKey !== 'appraiserfeedback') {
        questionObject?.[item]?.forEach((data: any) => {
          questionOptionPayload.push({
            performanceRatingScale: {
              performanceRatingScaleId: data?.range,
            },
            otherText: data?.answer,

            employeeReviewDetailId: data?.employeeReviewDetailId ? data?.employeeReviewDetailId : undefined,
            questionaire: {
              questionaireId: data?.questionaireId,
            },
          });
        });

      }
    });
    finalSendingPayload.isEligibleForIncrement = incrementEligible;
    if (isView) {
      finalSendingPayload.employeeReviewId = employeeReviewId;
    }
    finalSendingPayload.remarks = values.appraiserComments;
    finalSendingPayload.newCtc = parseInt(values.newCtc)
    finalSendingPayload.currentCtc = parseInt(initialValues.currentCtc)
    finalSendingPayload.newCtcEffectiveDate = retrieveData('salaryRevised', true)
    finalSendingPayload.employeeReviewDetails = questionOptionPayload;
    finalSendingPayload.employee = {
      employeeId: parseInt(id)
    }

    if (retrieveData('reviewtype', true) === 'Annual' || retrieveData('reviewtype', true) === 'Half Yearly') {
      finalSendingPayload.type = retrieveData('reviewtype', true)
    } else {
      finalSendingPayload.status = {
        statusId: retrieveData('reviewtype', true)
      }
    }
    finalPayload.employeeReview = finalSendingPayload;
    finalPayload.userId = userId !== null ? parseInt(userId) : retrieveData('userId', true);

    queryParams = {
      employeeReviewAndDetailsDtoJson: finalPayload
    }
    const submitStatus = retrieveData("reviewStatus", true);
    if (submitStatus === "Draft") {
      queryParams.employeeReviewAndDetailsDtoJson.isDraft = false;
      queryParams.employeeReviewAndDetailsDtoJson.employeeReview.employeeReviewId = employeeReviewId
    }

    if (!employeeReviewId) {
      createReviewApi(queryParams).then((res: any) => {
        if (res?.data?.data !== null) {
          setLoader(false)
          triggerNotification('success', '', res?.data?.status?.message, 'topRight');
          setChangeCtcModal(false);
          getReviewsApi();
          handelClose();
          handleCloseModal();
        } else {
          setLoader(false);
          triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        }
      })
        .catch((err) => {
          setLoader(false)
          triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        });
    } else {
      updateReviewApi(queryParams).then((res: any) => {
        if (res?.data?.data !== null) {
          setLoader(false)
          triggerNotification('success', '', res?.data?.status?.message, 'topRight');
          setChangeCtcModal(false);
          getReviewsApi();
          handelClose();
          handleCloseModal();
        } else {
          setLoader(false);
          triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        }
      })
        .catch((err) => {
          setLoader(false)
          triggerNotification('error', '', err?.response?.data?.message, 'topRight');
        });
    }

  };

  const handelClose = () => {
    setChangeCtcModal(!changeCtcModal)
  };

  return (
    <div className='appraisal-increment'>
      <p>Salary Increment</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handelSaveRevise(values);
          // resetForm();
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <div className='grid grid-cols-2 gap-x-12 gap-y-4 mt-4 w-full form-item'>
                <div className='form-item basicForm-fields mb-6' style={{ pointerEvents: 'none' }}>
                  <FloatLabel label='Current Ctc' name='currentCtc' value={String(initialValues.currentCtc)}>
                    <Field
                      type='text'
                      className='float-control'
                      name='currentCtc'
                      value={initialValues.currentCtc}
                      disabled={!!isView}
                    />
                    <ErrorMessage name='currentCtc' component='div' className='error-message' />
                    {initialValues.currentCtc !== 0 ? "" :
                      <p className='error-message'>Still salary is not updated </p>
                    }
                  </FloatLabel>
                </div>
                <div className='form-item basicForm-fields mb-6 w-full'>

                  <FloatLabel label='New Ctc' name='newCtc' value={!isView ? values.newCtc : newCtc}>
                    <Field
                      disabled={!!(isView === true && isPending === false)}
                      type='text'
                      className='float-control w-full'
                      name='newCtc'
                      onChange={(e: any) => { handleNewCtc(e, setFieldValue, 'newCtc') }}
                      value={newCtc}
                    />

                    <ErrorMessage name='newCtc' component='div' className='error-message' />
                  </FloatLabel>
                </div>
              </div>
              <div className='grid relative grid-cols-1 gap-x-12 mb-12 w-full form-item'>
                <Field
                  disabled={!!(isView === true && isPending === false)}
                  className='outline-none w-full px-[10px]'
                  as='textarea'
                  rows={3}
                  style={{ resize: 'none' }}
                  placeholder="Appraiser Comment"
                  name='appraiserComments'
                  onChange={(e: any) => { handleNewCtc(e, setFieldValue, 'appraiserComments') }}
                  value={remarks}
                />

                <ErrorMessage name='appraiserComments' component='div' className='error-message absolute bottom-[-20px]' />
              </div>
              <div className='flex justify-start mr-5 salary_add-btn'>
                <button className='addButton secondary mr-4' onClick={handelClose}>
                  Back
                </button>
                {!isView || (isView && isPending) ? (
                  <button type='submit'
                    className={initialValues.currentCtc != 0 ? 'addButton' : " addButton cursor-not-allowed"}
                    disabled={initialValues.currentCtc == 0 ? true : false}>
                    Submit
                  </button>) : null
                }
              </div>
            </Form>
          )
        }}
      </Formik>
      {loader && <ModalLoader />}
    </div>
  );
}

export default AppraisalForm
