import React, { useEffect, useRef, useState } from 'react';
import AnnouncementHOC from './layouts/Hoc/AnnouncementHOC';
import { useNavigate } from 'react-router-dom'
import { useSelector, useGetApiRequests, triggerNotification, retrieveData } from '../../common/CommonExport';
import { Tooltip } from 'antd';
import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  ResponseAnnouncement, Announcement, AnnouncementID,
  AnnouncementState, ErrorResponse
}
  from './SingleAnnouncementInterface';
import { useDispatch } from "react-redux";
import { fetchApiCall, announcementSliceRootState } from "../../redux/slices/announcementIdSlice";
import ComposeMailModal from './modal/ComposeMailModal';
import DOMPurify from "dompurify";
import {
  OrganisationIdState
} from './layouts/mailList/mailListsInterface';
import EmailRecipientRow from './EmailRecipientRow';
import { formatDate, formatTimeUTC, getBodyData, renderFilePreview, AnnouncementHeader, AnnouncementActions } from './SingleAnnouncementHelper';
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import PopupModal from '../employee/employee-details/employee-profile/modal/PopupModal';

const SingleAnnouncement = () => {
  const dispatch = useDispatch();
  const orgId = useSelector((state: OrganisationIdState) => state?.organisationId?.id);
  const announcementSlice = useSelector((state: announcementSliceRootState) => state.announcementIdSlice);
  const { announcementId } = announcementSlice;
  const userId = retrieveData('userId', true);

  const [showModal, setShowModal] = useState(false);
  const announcement = useSelector(
    (state: AnnouncementState) => state?.announcement
  );
  const [announcementData, setAnnouncementData] = useState<Announcement | null>(null);
  const handleShowModal = () => { setShowModal((prev) => !prev); };
  const getAnnouncement = useGetApiRequests('getAnnouncement', 'GET');
  const [sendMail, setSendMail] = useState<boolean>(false)
  const [actionType, setActionType] = useState<string>('create');
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  const navigate = useNavigate();
  const setAnnouncement = useGetApiRequests("setAnnouncement", "PUT");
  const createdOn = new Date().toISOString();
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_PATH;

  const handleNavigate = () => navigate(-1);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleCloseModal = (event: MouseEvent) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target as Node) &&
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleCloseModal);
    } else {
      document.removeEventListener('mousedown', handleCloseModal);
    }

    return () => {
      document.removeEventListener('mousedown', handleCloseModal);
    };
  }, [showModal]);

  useEffect(() => {
    if (announcement?.announcementList) {
      getSingleAnnouncementDetails(announcement.announcementList);
    }
  }, [announcement]);

  const getSingleAnnouncementDetails = (data: Announcement) => {

    const pathParams = {
      id: data.emailAnnouncementId
    }
    getAnnouncement('', '', pathParams)
      .then((response: AxiosResponse<ResponseAnnouncement> | AxiosError) => {
        if (response && !(response instanceof AxiosError)) {
          const { status, message, data: announcementData } = response.data;

          setAnnouncementData(data);
          if (status === 200) {
            console.log('Announcement Details:', announcementData);

          } else {
            // triggerNotification('error', '', message || 'Unexpected error', 'topRight');
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage(message || 'Unexpected error')
            setModalResponse('error')
            setTimeout(() => {
              handleClosePopUpModal()
            }, 1500)
          }
        } else {
          // triggerNotification('error', '', 'Invalid response format', 'topRight');
          setPopModal(true)
          setmodalButtonShow(false)
          setModalErrMessage('Invalid response format')
          setModalResponse('error')
          setTimeout(() => {
            handleClosePopUpModal()
          }, 1500)
        }
      })
      .catch((err: AxiosError) => {
        // Use type assertion to access the `message` property safely
        const errorResponse = err.response?.data as ErrorResponse;
        const errorMessage = errorResponse?.message || 'Something went wrong';

        console.error('API Error:', err);
        // triggerNotification('error', '', errorMessage, 'topRight');
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(errorMessage)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal()
        }, 1500)
      });
  };
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  const handleEditComposeMail = () => {
    setActionType('edit');
    setSendMail(true);
  }
  const handleSendNow = () => {
    // Prepare FormData for file attachments
    const formData = new FormData();
    formData.append('files', "");

    const pathParams = {
      emailAnnouncementDto: announcementData?.emailAnnouncementId,
    };

    // Construct payload for submission // SCHEDULED
    const payload = {
      "emailAnnouncementDto": {
        "emailAnnouncementId": announcementData?.emailAnnouncementId,
        "senderCntUserId": userId,
        "status": "SENT",
        "isStarred": announcementData?.isStarred == null || announcementData?.isStarred == false ? false : true,
        "isActive": true,
        "createdOn": createdOn,
        "organisationId": orgId,
        "subject": announcementData?.subject,
        "body": announcementData?.body,
        "emailRecipient": {
          "toEmail": announcementData?.emailRecipient?.toEmail,
          "ccEmail": announcementData?.emailRecipient?.ccEmail,
          "bccEmail": announcementData?.emailRecipient?.bccEmail
        },
        "attachments": announcementData?.attachments || []
      }
    };

    setAnnouncement(formData, payload, pathParams)
      .then((response) => {
        if (axios.isAxiosError(response)) {
          throw response;
        }
        const responseStatus = response?.status;
        if (responseStatus === 200 || responseStatus === 201) {
          // triggerNotification('success', '', response?.data?.message, 'topRight');
          setPopModal(true)
          setmodalButtonShow(false)
          setModalErrMessage(response?.data?.message)
          setModalResponse('success')
          setTimeout(() => {
            handleClosePopUpModal()
            navigate(`/home/announcements/sent`);
          }, 1500)

          // setTimeout(() => {
          //   dispatch(fetchApiCall(!isReload));
          // }, 1000)
        }
      })
      .catch((err) => {
        // triggerNotification("error", "", err?.response?.data?.message, "topRight");
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.response?.data?.message)
        setModalResponse('success')
        setTimeout(() => {
          handleClosePopUpModal()
        }, 1500)
      });
  }
  const Comments = (value: any) => {
    let itemText: EditorState;

    try {
      if (typeof value === 'string' && value.trim().startsWith('<')) {
        // Handle HTML input
        const contentState = stateFromHTML(value);
        itemText = EditorState.createWithContent(contentState);
      } else {
        // Assume it's raw draft.js JSON
        const rawContent = JSON.parse(value);
        itemText = EditorState.createWithContent(convertFromRaw(rawContent));
      }
    } catch (error) {
      console.error('Invalid input for draft-js:', error);
      itemText = EditorState.createEmpty();
    }

    return (
      <Editor
        editorState={itemText}
        readOnly={true}
        onChange={() => { }} // No-op function for the required `onChange` prop
      />
    );
  };
  return (
    <div className='singleAnnouncement'>
      <AnnouncementHeader announcementData={announcementData} handleNavigate={handleNavigate} />
      <div className='singleAnnouncement--body'>
        <div className='receive-mail flex items-center space-x-2'>
          <div className='profile'>
            <h5>HR</h5>
          </div>
          <div className='email-lists '>

            <h6 className=' font-medium'>{announcementData?.subject}</h6>
            <div className=' flex items-center'>
              <p>
                To:{' '}
                <span>
                  {announcementData?.emailRecipient?.toEmail || 'No recipient email'}
                </span>
              </p>
              <div>
                <Tooltip title='Show details'>
                  <span ref={tooltipRef} className="material-symbols-outlined cursor-pointer hover:bg-white ml-[2px]" onClick={() => handleShowModal()} >
                    arrow_drop_down
                  </span>
                </Tooltip>
                {showModal && (
                  <div ref={modalRef} className="absolute  p-[20px] ml-[10px]  w-[430px] max-w-[35%] shadow-lg bg-white show-modal-container max-h-[300px] overflow-auto z-[2]">
                    <table className="show-modal-type">
                      <tbody>
                        <tr>
                          <td className="w-[23%] text-right pr-4 align-baseline text-[#707070] font-rubik  text-[14px]">from:</td>
                          <td className="w-[77%]">
                            <p className="font-semibold text-black">
                              <span className="font-normal text-black">&lt;noreply@cloudnowtech.com&gt;</span>
                            </p>
                          </td>
                        </tr>
                        {
                          announcementData?.emailRecipient?.toEmail && (
                            <EmailRecipientRow label="to" emailList={announcementData.emailRecipient.toEmail} />
                          )
                        }
                        {
                          announcementData?.emailRecipient?.ccEmail && (
                            <EmailRecipientRow label="cc" emailList={announcementData.emailRecipient.ccEmail} />
                          )
                        }
                        {
                          announcementData?.emailRecipient?.bccEmail && (
                            <EmailRecipientRow label="bcc" emailList={announcementData.emailRecipient.bccEmail} />
                          )
                        }
                        <tr>
                          <td className="w-[23%] text-right pr-4 align-baseline text-[#707070] text-[14px]">date:</td>
                          <td className="w-[77%]">
                            <p className='text-black '>{announcementData && announcementData.updatedOn ? new Date(announcementData.updatedOn).toLocaleString() : 'Loading...'}</p>
                          </td>
                        </tr>

                        <tr>
                          <td className="w-[23%] text-right pr-4 align-baseline text-[#707070] text-[14px]">subject:</td>
                          <td className="w-[77%]">
                            <p className='text-black'>{announcementData?.subject}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mail-body h-[285px] overflow-auto">

          {announcementData ? (
            <div className=' announcement-body-desc'>
              <p className="pb-4">
                {Comments(announcementData.body)}
              </p>
            </div>
          ) : (
            <p>Loading...</p>
          )}
          {
            announcementData && announcementData?.attachments.length > 0 ? (
              <div className="flex flex-wrap gap-4">
                {
                  announcementData?.attachments?.length > 0 ? (
                    <div className="flex flex-wrap gap-4">
                      {announcementData?.attachments.map((file: any, index: number) => {
                        const cleanFilePath = (filePath: string): string => {
                          if (filePath.includes('/download/storage/v1/b/')) {
                            const pathMatch = filePath.match(/\/o\/(.*?)(\?|$)/);
                            if (pathMatch && pathMatch[1]) {
                              let decodedPath = `${process.env.REACT_APP_IMAGE_BASE_PATH}${decodeURIComponent(pathMatch[1])}`;
                              decodedPath = decodedPath.replace(/\/qa\/qa\/+/g, '/qa/');
                              return decodedPath;
                            }
                          }
                          return filePath;
                        };
                        const fileExtension = file?.fileName?.split('.').pop()?.toLowerCase();
                        let filePath = `${imageBaseUrl}${file?.filePath}`;
                        filePath = cleanFilePath(filePath);

                        return (
                          <div key={index} className="flex flex-col items-center bg-[#f5f5f5] p-3 rounded-lg shadow-sm">
                            <a
                              href={filePath}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm font-medium text-blue-600 hover:underline text-center truncate w-[100px]"
                              title={file?.fileName}
                            >
                              <div className="mb-2 border border-gray-300 rounded-md announcement-file" title={file?.fileName}>
                                {renderFilePreview(fileExtension, filePath, file?.fileName)}
                              </div>
                              {file?.fileName}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ) : null
                }
              </div>
            ) : null
          }
        </div>
      </div>
      <AnnouncementActions
        announcementData={announcementData}
        handleEditComposeMail={handleEditComposeMail}
        handleSendNow={handleSendNow}
      />
      {
        sendMail && <ComposeMailModal
          sendMail={sendMail}
          setSendMail={setSendMail}
          title={announcementData?.status == 'DRAFT' ? 'Draft' : 'Schedule'}
          actionType={actionType}
          announcementData={announcementData} />
      }
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

    </div>
  )
}

export default AnnouncementHOC(SingleAnnouncement);
