import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { retrieveData, storeData } from '../../../../storage/Storage';
import IncrementEligibleSwitch from '../employee-profile/layout/Appraisal/components/IncrementEligibleSwitch';
import { useEmployeeAppraisalContext } from '../employee-profile/layout/Appraisal/context/EmployeeAppraisalContext';
import FloatLabel from '../../../../components/FloatLabel';

import { DatePicker, Table } from 'antd';
import dayjs from 'dayjs';
import { ErrorMessage, Field } from 'formik';
import { formatWithCommas } from '../../../../hooks/commonHooks';
import { salaryUpdate } from '../../../../constants/salaryValues';
import useSalaryDetails from './useSalaryDetails';
import { handleSalaryInput } from '../../../../hooks/salaryHooks';
import { useDispatch } from 'react-redux';
import { ctcSalaryYearlyValue } from '../../../../redux/slices/reviewFormSlice';
import SalaryTable from './SalaryTable';
import { debounce } from 'lodash';
interface RevisedSalaryProps {
  values: any;
  setFieldValue: any;
  salaryDate: any;
}
export default function RevisedSalary({ values, setFieldValue, salaryDate }: RevisedSalaryProps) {
  const dispatch = useDispatch()
  const dateFormat = 'YYYY-MM-DD';
  const { changeGross } = useSalaryDetails()

  const reviewFromDetails = useSelector((state: any) => state.reviewDetailsSlice?.reviewFormSlice);
  const oldGrossAmount = useSelector((state: any) => state.reviewDetailsSlice?.salaryParticularsForGrossAmount)

  const { ctc_AmountYearly, net_AmountYearly, gross_SalaryYearly } = useSelector((state: any) => state.reviewDetailsSlice);
  const { itsIntern } = reviewFromDetails
  const { isPending, isView, employeeCtc, salaryIncrement, SetSalaryIncrement } = useEmployeeAppraisalContext();
  // const currentCtc: number = salaryIncrement.currentCtc ? parseFloat(salaryIncrement.currentCtc as string) : 0;
  const currentCtc: number = oldGrossAmount?.grossAmountAnnual
  const [ctcAmount, setCtcAmount] = useState<number>(currentCtc)
  const [newGrossAmount, setNewGrossAmount] = useState<number>(oldGrossAmount?.grossAmountAnnual)
  const [isEligible, setIsEligible] = useState(values?.incrementEligible);
  const concatSalary = useSelector((state: any) => state.reviewDetailsSlice?.concatenatedSalaryParticulars);
  console.log("RevisedSalary ~ concatSalary:", concatSalary)

  const [salaryBreakdown, setSalaryBreakdown] = useState<any>([{
    key: '1',
    name: 'Gross Salary',
    yearlySalary: 0,
    monthlySalary: 0
  },
  {
    key: '2',
    name: 'Cost To Company',
    yearlySalary: `₹ ${formatWithCommas((ctc_AmountYearly)?.toFixed(2))}`,
    monthlySalary: `₹ ${formatWithCommas((ctc_AmountYearly / 12)?.toFixed(2))}`,
  },
  {
    key: '3',
    name: 'Net Salary',
    yearlySalary: `₹ ${formatWithCommas(net_AmountYearly?.toFixed(2))}`,
    monthlySalary: `₹ ${formatWithCommas((net_AmountYearly / 12)?.toFixed(2))}`,
  }]);
  const { gross_SalaryMonthly, salaryUpdatePayload, ctc_SalaryYearly, salaryPayloadDetails, concatEmployerContribution, employeeContribution } = useSelector((state: any) => state.reviewDetailsSlice);
  console.log(" concatEmployerContribution:", concatEmployerContribution)


  useEffect(() => {
    calculateSalaries(newGrossAmount)
  }, [])


  const handleEligible = (setFieldValue: any, values: any) => {
    setFieldValue('incrementEligible', !values.incrementEligible);
    setIsEligible(!isEligible)
  }
  const onChangeDate = (date: any, dateString: any, setFieldValue: any): any => {
    setFieldValue('salaryRevised', dateString);
    storeData("salaryRevised", dateString, true);
    SetSalaryIncrement((prev: any) => ({
      ...prev,
      salaryRevisedDate: dateString,
    }));
  };

  const handleChangeSalary = (e: any, type = "") => {
    const value: any = handleSalaryInput(e)
    let newCtcInRupees: number = 0
    let newCtcInPercentage: string | number = 0
    if (type === "rupees") {
      if (value < currentCtc) {
        console.error("New CTC cannot be less than current salary.");

      }
      const incrementInRupees = value - currentCtc;
      const percentage = (incrementInRupees / currentCtc) * 100;
      newCtcInRupees = value
      newCtcInPercentage = percentage.toFixed(2)
    } else {

      const rupees = (value / 100) * currentCtc;
      newCtcInRupees = rupees + currentCtc
      newCtcInPercentage = e.target.value

    }
    const handleCheckNumber = (value: string | number): number => {
      const numericValue = typeof value === 'string' ? parseFloat(value) : value;
      return isNaN(numericValue) || numericValue < 0 ? 0 : numericValue;
    };

    SetSalaryIncrement((prev: any) => ({
      ...prev,
      newCtcInRupees: handleCheckNumber(newCtcInRupees),
      newCtcInPercentage: handleCheckNumber(newCtcInPercentage)
    }));
    dispatch(ctcSalaryYearlyValue(newCtcInRupees))
    setCtcAmount(newCtcInRupees)
    if (newCtcInRupees > currentCtc) {
      calculateSalaries(newCtcInRupees);
    } else {
      calculateSalaries(newCtcInRupees);
    }

  };

  // const calculateSalaries = (el: number) => {
  //   const grossSalary =
  //     el - (salaryUpdate?.employerProvidentFund + salaryUpdate?.groupMedicalInsurancePremium);
  //   setNewGrossAmount(grossSalary)
  //   changeGross(grossSalary, "yearly")
  // };


  const calculateSalaries = useCallback(
    debounce((el: number) => {
      // console.log(" debounce ~ el:", el)
      // const basicSalaryDetails = concatSalary.find(
      //   (item: any) => item?.name === "Basic Salary"
      // );
      // const bonus = concatEmployerContribution?.find(
      //   (item: any) => item?.name === "Standard Bonus"
      // );
      // const standardBonusYearly = bonus?.yearly || "Not Found";
      // console.log(" debounce ~ standardBonusYearly:", standardBonusYearly)
      // const basicSalary = basicSalaryDetails?.monthly ?? 0;
      // console.log(" debounce ~ basicSalary:", basicSalary)

      // const gratuityAmount = (basicSalary * 15) / 26
      // console.log("~ debounce ~ gratuityAmount:", gratuityAmount)
      // const grossSalary = el - salaryUpdate?.employerProvidentFund - salaryUpdate?.groupMedicalInsurancePremium - gratuityAmount - 1000;
      // console.log("debounce  grossSalary:", grossSalary)

      // setNewGrossAmount(el);
      changeGross(el, "yearly");
    }, 600), // 300ms debounce delay
    [salaryUpdate, changeGross]
  );

  return (
    <div>
      {/* <pre>concatSalary {JSON.stringify(concatSalary, null, 1)}</pre> */}
      {/* <pre>concatEmployerContribution {JSON.stringify(concatEmployerContribution, null, 1)}</pre>
      <pre>net_AmountYearly {JSON.stringify(net_AmountYearly, null, 1)}</pre>
      <pre>gross_SalaryMonthly {JSON.stringify(gross_SalaryYearly, null, 1)}</pre> */}
      {/* <pre>newCtcInRupees {JSON.stringify(newCtcInRupees.ctc_SalaryYearly, null, 1)}</pre>
     
      <pre> {values && JSON.stringify(values, null, 1)}</pre> */}
      {/* <p className='_header mb-5'>Salary Particulars</p> */}
      {/* <div className="salary_prticulars-container px-4 ">
        <div className="salary_prticulars-list overflow-y-auto">
          <div className="salary-structure grid grid-cols-3 gap-4 mb-8">
            <div className="particulars">
              <p className='_header mb-5'>Salary Particulars</p>
              <ul>
                {concatSalaryParticulars
                  .filter((item: any) => !(item.salaryComponentId === 132 && item.name === 'LTA' && grossSalaryMonthly <= 36803))
                  .map((item: any) => (
                    <li key={item?.salaryComponentId}>{item?.name}</li>
                  ))}
              </ul>
              <p className='salary-gross salary-gross-add '>Gross Salary</p>
            </div>
            <div className="monthly_details">
              <p className='_monthly'>Monthly </p>
              <ul>
                {concatSalaryParticulars
                  .filter((item: any) => !(item.salaryComponentId === 132 && item.name === 'LTA' && grossSalaryMonthly <= 36803))
                  .map((item: any) => (
                    <li key={item?.salaryComponentId}>
                      &#8377;{item?.monthly !== '' ? IndianCurrencyFormat(item?.monthly) : 0}
                    </li>
                  ))}
              </ul>

            </div>

          </div>

        </div>
      </div> */}
      <div className="flex flex-wrap items-center mb-4">
        {/* {JSON.stringify(values?.incrementEligible, null, 2)} */}
        {!itsIntern && itsIntern !== null && (
          <div className='w-[100%]'>
            {retrieveData("reviewStatus", true) !== "Completed" &&
              <IncrementEligibleSwitch
                isEligible={isEligible}
                isView={isView}
                isPending={isPending}
                values={values}
                setFieldValue={setFieldValue}
                handleEligible={handleEligible}
              />
            }</div>)}

        {isEligible === true && (
          <>
            <h4 className='general-assessment-header py-4 w-full' >Salary Details</h4>
            <div className="grid grid-cols-2 grid-rows-1 gap-4 w-full">
              <div><div className="revised-date relative control-float-label">
                <FloatLabel label='Revision Effective From*' name='salaryRevised' value={String("text")}>
                  <DatePicker
                    className='float-control'
                    name="salaryRevised"
                    onChange={(date, dateString) => onChangeDate(date, dateString, setFieldValue)}
                    defaultValue={!isView ? dayjs(salaryIncrement?.salaryRevisedDate, dateFormat) : (isView && isPending) ? dayjs(salaryIncrement?.salaryRevisedDate, dateFormat) : dayjs(salaryDate, dateFormat)} format="YYYY-MM-DD"
                    disabled={!!(isView === true && isPending === false)}
                  />
                </FloatLabel>
              </div></div>
              <div>

                {/* <div className='form-item basicForm-fields' style={{ pointerEvents: 'none' }}>
                  <FloatLabel label='Current Ctc' name='currentCtc' value={String(salaryIncrement?.currentCtc)}>
                    <Field
                      type='text'
                      className='float-control'
                      name='currentCtc'
                      disabled={!!isView}
                      value={formatWithCommas(salaryIncrement?.currentCtc)}
                    />
                    <ErrorMessage name='currentCtc' component='div' className='error-message' />
                    {employeeCtc !== 0 ? "" :
                      <p className='error-message'>Still salary is not updated </p>
                    }
                  </FloatLabel>
                </div> */}
              </div>
              <div>
                <div className='form-item basicForm-fields relative '>
                  <FloatLabel label='New gross salary (₹)' name='newCtcRupees' value={String("text")} >
                    <Field
                      disabled={!!(isView === true && isPending === false)}
                      type='text'
                      className='float-control w-full'
                      name='newCtc'
                      maxLength={10}
                      onChange={(e: any) => handleChangeSalary(e, "rupees")}
                      value={formatWithCommas(salaryIncrement?.newCtcInRupees)}

                    />
                    <ErrorMessage name='newCtc' component='div' className='error-message' />
                  </FloatLabel>
                </div>
              </div>
              <div>
                <div className='form-item basicForm-fields relative '>
                  <FloatLabel label='New gross salary	(%)' name='newCtcPercentage' value={String("text")} >
                    <Field
                      disabled={!!(isView === true && isPending === false)}
                      type='text'
                      className='float-control w-full'
                      name='newCtc'
                      maxLength={4}
                      onChange={(e: any) => handleChangeSalary(e, "percentage")}
                      value={salaryIncrement?.newCtcInPercentage}

                    />
                    <ErrorMessage name='newCtc' component='div' className='error-message' />
                  </FloatLabel>
                </div>
              </div>
            </div>

            <div className='w-[100%] mt-12 mb-4'>
              <SalaryTable newGrossAmount={newGrossAmount} />
              {/* <Table dataSource={salaryBreakdown} columns={columns} pagination={false} /> */}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

