import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { ErrorMessages, triggerNotification, useGetApiRequests, useSelector } from '../../../../../../common/CommonExport';
import Loader from '../../../../../../components/Loader/Loader';
import { calculateEmployeeContribution, calculateEmployerContribution, calculateFixedEmployerContribution, calculateFixedSalaryParticulars, calculateGrossMonthlyYearly, calculateSalaryParticulars, calculationForCtc, calculationForNetAmount, calculationForTotalAmount, gratuityCalculation } from './SalaryClaculationFunctions';
import { checkValueGreaterThanZero } from './AddEditCalculations';
import AddSalaryModal from '../AddSalaryModal';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';

const SalaryHistory = () => {
  const { id }: any = useParams();
  const { search } = useLocation();
  const employeeTypeId: any = new URLSearchParams(search).get("employeeTypeId")
  const salaryRange = useGetApiRequests('getSalaryRange', 'GET');
  const salaryFormView = useGetApiRequests('salaryFormView', 'GET');
  const [loader, setLoader] = useState(true);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const salaryHistoryReloaded = useSelector((state: any) => state?.profileReload?.historyLoad);
  const [employeeHistoryData, setEmployeeHistoryData] = useState<any>([]);
  const [historySalaryParticulars, setHistorySalaryParticulars] = useState<any>([]);
  const [historyEmployeeContribution, setHistoryEmployeeContribution] = useState<any>([]);
  const [historyGrossSalary, setHistoryGrossSalary] = useState({ grossAmountMonthly: NaN, grossAmountAnnual: NaN });
  const [historyCostToCompany, setHistoryCostToCompany] = useState({ ctcMonthly: NaN, ctcYearly: NaN });
  const [historyNetAmount, setHistoryNetAmount] = useState({ monthly: NaN, yearly: NaN });
  const [historyFixedEmployerContribution, setHistoryFixedEmployerContribution] = useState([]);
  const [historyFixedSalaryParticulars, setHistoryFixedSalaryParticulars] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [revisedOnDate, setRevisedOnDate] = useState('')

  const handleRowClick = async (employeeSalaryId: any, date: any) => {
    setRevisedOnDate(date)
    const pathParams: any = {
      id: `${employeeSalaryId}/history`
    }
    await salaryFormView('', {}, pathParams).then((response: any) => {
      if (response?.data?.data) {
        const receivedResponse = response?.data?.data?.employee?.employeeType?.emoyeeTypeSalaryComponentMap.map((item: any) => item?.salaryComponent);
        const historySalaryParticularsData = receivedResponse.filter((item: any) => item.groupName === 'Salary Particulars').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));
        const historyEmployeeContributionData = receivedResponse.filter((item: any) => item.groupName === 'Employee Contribution').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));
        const historyEmployerContributionData = receivedResponse.filter((item: any) => item.groupName === 'Employer Contribution').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));
        const historyTempFixedSalaryParticulars = calculateFixedSalaryParticulars(receivedResponse);

        setHistoryFixedSalaryParticulars(historyTempFixedSalaryParticulars)
        const historyTempFixedEmployerContribution = calculateFixedEmployerContribution(receivedResponse)
        if (employeeTypeId === '2') {
          setHistoryFixedEmployerContribution(historyTempFixedEmployerContribution)
        }
        const finalSalaryDetails = response?.data?.data;
        salaryCalculationDetails(finalSalaryDetails, historySalaryParticularsData, historyEmployeeContributionData, historyEmployerContributionData, historyTempFixedEmployerContribution, employeeTypeId)
      }
      setTimeout(() => {
        setLoader(false)
      }, 1000)
    })
      .catch(() => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      });
  }
  const salaryCalculationDetails = (finalSalaryDetails: any, historySalaryParticularsData: any, historyEmployeeContributionData: any, historyEmployerContributionData: any, historyTempFixedEmployerContribution: any, type: any) => {
    const historyUpdatedSalaryParticulars = calculateSalaryParticulars(finalSalaryDetails, historySalaryParticularsData);
    const historyUpdatedEmployerContribution = calculateEmployerContribution(finalSalaryDetails, historyEmployerContributionData);
    const historyUpdatedEmployeeContribution = calculateEmployeeContribution(finalSalaryDetails, historyEmployeeContributionData)
    const gratuityValueArrayHistory = gratuityCalculation(finalSalaryDetails.employeeSalaryDetails[0].monthly, historyUpdatedEmployerContribution)
    setHistoryFixedEmployerContribution(gratuityValueArrayHistory)
    const { grossAmountMonthly, grossAmountAnnual } = calculateGrossMonthlyYearly(orgId, finalSalaryDetails, historyUpdatedSalaryParticulars)
    setHistoryGrossSalary({ grossAmountMonthly: grossAmountMonthly, grossAmountAnnual: grossAmountAnnual })
    if (type === '2') {
      const monthlyCtc: any = calculationForCtc(finalSalaryDetails, historyUpdatedEmployerContribution);
      const { totalAnnual } = calculationForTotalAmount(finalSalaryDetails, grossAmountAnnual, historyUpdatedEmployeeContribution, historyUpdatedSalaryParticulars);
      setHistoryCostToCompany({ ctcMonthly: monthlyCtc, ctcYearly: totalAnnual })
      setOpenModal(true);
    } else if (type === '1') {
      const { historyMonthlyCTC, historyYearlyCTC } = costToCompanyCalculation(grossAmountMonthly, [], gratuityValueArrayHistory)
      setHistoryCostToCompany({ ctcMonthly: historyMonthlyCTC, ctcYearly: historyYearlyCTC })
    }
    const { netAmountMonthly, netAmountAnnual } = calculationForNetAmount(historyUpdatedEmployeeContribution, grossAmountMonthly, grossAmountAnnual, finalSalaryDetails);
    setHistoryNetAmount({ monthly: netAmountMonthly, yearly: netAmountAnnual })
    setHistorySalaryParticulars(historyUpdatedSalaryParticulars);
    setHistoryEmployeeContribution(historyUpdatedEmployeeContribution);
  };
  const costToCompanyCalculation = (grossMonth: any, historyEmployerContribution: any, historyTempFixedEmployerContribution: any) => {
    const historyTempEmployerContribution: any = [...historyEmployerContribution];
    const historyFixedEmployerContributionTemp = [...historyTempFixedEmployerContribution]
    let historyMonthlyCTC: number = grossMonth;
    historyTempEmployerContribution.forEach((item: any) => {
      historyMonthlyCTC = historyMonthlyCTC + Number(item.monthly);
    });
    historyFixedEmployerContributionTemp.forEach((item: any) => {
      if (item?.name === 'ESI') {
        if (grossMonth <= 21000) {
          item.monthly = ((checkValueGreaterThanZero(grossMonth) * 3.25) / 100);
          item.yearly = (Number(((checkValueGreaterThanZero(grossMonth)) * 3.25) / 100) * 12);
        } else {
          item.monthly = 0;
          item.yearly = 0;
        }
      }
      historyMonthlyCTC = historyMonthlyCTC + Number(item.monthly);
    });
    let historyYearlyCTC: number = (historyMonthlyCTC) * 12;

    historyFixedEmployerContributionTemp.forEach((item: any) => {
      if (item.monthly === 0) {
        historyYearlyCTC += item.yearly;
      }
    });
    // historyYearlyCTC = ((historyYearlyCTC));
    setOpenModal(true);

    return { historyMonthlyCTC, historyYearlyCTC }
  }
  function formatAmountToIndianRupee(amount: any) {
    return amount.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0
    });
  }
  const calculateIncrementPercentage = (currentCTC: number, previousCTC: number) => {
    return ((currentCTC - previousCTC) / previousCTC) * 100;
  };
  const columns = [
    {
      title: 'Designation',
      dataIndex: 'designation',
      render: (text: any, record: any) => (
        <div className='cursor-pointer'>
          <div className='justify-center items-center py-2 ' onClick={() => handleRowClick(record?.key, record?.createdOn)}>{record?.designation}</div>
        </div>)
    },
    {
      title: 'Annual CTC',
      dataIndex: 'ctc',
      render: (text: any, record: any) => (
        <div className='cursor-pointer'>
          <div className='justify-center items-center py-2 ' onClick={() => handleRowClick(record?.key, record?.createdOn)}>{formatAmountToIndianRupee(record?.ctc)}</div>
        </div>)
    },
    {
      title: 'Hike Percentage',
      dataIndex: 'incrementPercentage',
      render: (text: any, record: any, index: number) => {
        if (index === employeeHistoryData.length - 1) {
          return <div className='justify-center items-center py-2'>-</div>;
        } else {
          const currentCTC = employeeHistoryData[index].ctc;
          const previousCTC = employeeHistoryData[index + 1].ctc;
          const incrementPercentage = calculateIncrementPercentage(currentCTC, previousCTC);
          return <div className='justify-center items-center py-2'>{incrementPercentage.toFixed(2)}%</div>;
        }
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text: any, record: any) => (
        <div className='cursor-pointer'>
          <div className='justify-center items-center py-2 ' onClick={() => handleRowClick(record?.key, record?.createdOn)}>{record?.date}</div>
        </div>)
    },
  ];
  const dateConvertion = (date: any) => {
    let spliteValue = date.split('T')[0];
    let value = spliteValue;
    let dateValue = value.split('-')
    return dateValue[2] + '-' + dateValue[1] + '-' + dateValue[0];
  }
  useEffect(() => {
    checkIsRevised()
  }, [salaryHistoryReloaded])
  const checkIsRevised = () => {
    const param = {
      id: id,
      end: 'salary/history/range',
    };
    setLoader(true);
    salaryRange('', '', param)
      .then((response: any) => {
        if (response?.data?.data && response?.data?.data.length > 0) {
          const historyData: any = response?.data?.data.map((item: any) => ({
            designation: item?.designation?.name,
            ctc: item?.ctc,
            date: dateConvertion(item?.createdOn),
            key: item?.employeeSalaryId,
            createdOn: item?.createdOn
          }));
          setEmployeeHistoryData(historyData);
        }
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      });
  };
  const handleClose = () => {
    setTimeout(() => {
      setLoader(false)
    }, 1000)
  };
  return (
    <>
      <div className="salary-history">
        <Table data-testid="tableEl" dataSource={employeeHistoryData} columns={columns} pagination={false} />
        {openModal && <AddSalaryModal historyView={true} isEdit={true} open={openModal} handleClose={handleClose} empTypeId={employeeTypeId} salaryParticulars={historySalaryParticulars} employeeContribution={historyEmployeeContribution} employerContribution={[]} grossSalary={historyGrossSalary} costToCompany={historyCostToCompany} netAmount={historyNetAmount} FixedSalaryParticulars={historyFixedSalaryParticulars} FixedEmployerContribution={historyFixedEmployerContribution} isRevised={true} revisedOnDate={revisedOnDate} />}
      </div>
      {loader && <ModalLoader />}
    </>
  )
}
export default SalaryHistory;
